<template>
    <b-nav-item-dropdown class="profile-dropdown" toggle-class="py-0" right>
        <template #button-content>
            <b-avatar size="35px" variant="light"></b-avatar>
        </template>
        <b-nav-text class="d-flex align-items-center px-4">
            <img
                v-if="enablingPartyIcon"
                :src="enablingPartyIcon"
                alt="login provider logo"
                width="18"
                class="mr-2"
            />
            Logged in as:
        </b-nav-text>
        <b-dropdown-item :href="profileLink">
            {{ userEmail }}
        </b-dropdown-item>
        <template v-for="userLink in userProfileMenu">
            <b-dropdown-item
                :href="userLink.path"
                :key="userLink.name"
                @click="handleNavItemAction(userLink)"
            >
                <template v-if="userLink.name == 'Logout'">
                    <b-icon font-scale="1" icon="lock-fill" aria-label="Logout"></b-icon>
                </template>
                {{ userLink.name }}
            </b-dropdown-item>
        </template>
    </b-nav-item-dropdown>
</template>

<script lang="ts">
import { storageService } from "@/services/storage.service";
import Vue from "vue";
export default Vue.extend({
    props: {
        userEmail: {
            type: String,
            default: null,
        },
        enablingPartyIcon: {
            type: String,
            default: null,
        },
        stripeEnabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            userProfileMenu: [
                {
                    name: "My Profile",
                    path: "/user-profile",
                },
                {
                    name: "Notification Settings",
                    path: "/app/user/settings/notifications",
                },
                {
                    name: "Logout",
                    path: "/logout",
                },
            ],
        };
    },
    computed: {
        profileLink(): string {
            return this.stripeEnabled ? "/app/user/settings/profile" : "/user-profile";
        },
    },
    methods: {
        logout(): void {
            const lastLogin = storageService.getLastLoginProvider();
            storageService.clear();
            lastLogin && storageService.setLastLoginProvider(lastLogin);
        },
        setupStripeMenu(): void {
            this.userProfileMenu[0].path = "/app/user/settings/profile";
            this.userProfileMenu[1].path = "/app/user/settings/notifications";

            this.userProfileMenu.splice(
                2,
                0,
                {
                    name: "Billing",
                    path: "/app/user/settings/billing",
                },
                {
                    name: "Subscriptions",
                    path: "/app/user/settings/subscriptions",
                }
            );
        },
        handleNavItemAction(userLink: Record<string, string>): void {
            if (userLink.name === "Logout") {
                this.logout();
            }
        },
    },
    created() {
        if (this.stripeEnabled) {
            this.setupStripeMenu();
        }
    },
});
</script>

<style></style>
