import { catchErrorHandler } from "@/helpers";
import { FEATURE_FLAGS } from "@/models";
import { a2xApi } from "@/services/api.service";
import { AxiosResponse } from "axios";

export const featureFlagApi = {
    async getAsync(accountKey: string): Promise<FEATURE_FLAGS[]> {
        const url = `feature-flag?accountKey=${accountKey}`;

        const response: AxiosResponse = await a2xApi.get(url);
        return response.data;
    },

    async postFeatureFlagsAsync(
        accountKey: string,
        featureFlagsToAdd: string[]
    ): Promise<string[]> {
        const url = "feature-flag";
        const obj = { accountKey, featureFlagsToAdd };
        try {
            const response: AxiosResponse = await a2xApi.post(url, obj);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },

    async deleteFeatureFlagsAsync(
        accountKey: string,
        featureFlagsToRemove: string[]
    ): Promise<string[]> {
        const url = "feature-flag";
        const obj = { accountKey, featureFlagsToRemove };
        try {
            const response: AxiosResponse = await a2xApi.delete(url, {
                data: obj,
            });
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
};
