<template>
    <b-card body-class="p-3">
        <content-block-skeleton />
    </b-card>
</template>

<script lang="ts">
import Vue from "vue";
import ContentBlockSkeleton from "./ContentBlockSkeleton.vue";
export default Vue.extend({
    components: {
        ContentBlockSkeleton,
    },
});
</script>

<style scoped></style>
