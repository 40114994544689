<template>
    <span class="input-error-message">{{ errorMessage }}</span>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        errorMessage: {
            required: false,
            type: String,
            default: "",
        },
    },
});
</script>

<style scoped>
.input-error-message {
    color: red;
}
</style>
