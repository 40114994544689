
<b-input-group class="atx-date-picker-wrapper w-100" v-if="datepickerIcon">
    <template #prepend>
        <b-input-group-text class="bg-white">
            <atx-icon icon="calendar4" />
        </b-input-group-text>
    </template>
    <datepicker
        v-bind="$attrs"
        v-on="$listeners"
        ref="atx-date-picker"
        class="atx-date-picker clear-button-regular"
        :input-class="{
            'border-bottom-left-radius-0 border-top-left-radius-0 bg-white': true,
            ...inputClass,
        }"
        :wrapper-class="{
            ...wrapperClass,
        }"
        :calendar-class="{
            'atx-date-picker-calendar p-2 border-radius-12': true,
            ...calendarClass,
        }"
        :disabled-dates="disabledDates"
        :day-cell-content="dayCellContent"
        :format="format"
        :minimumView="minimumView"
        :maximumView="maximumView"
        :inline="inline"
        :clear-button="clearButton && !inline"
        bootstrap-styling
        @changedMonth="attachEventListeners"
        :open-date="selectedFromDate"
    >
    </datepicker>
    <b-tooltip
        v-if="dayTooltip && dayTooltipMessage"
        show
        :target="dayTooltip.target"
        triggers="hover"
        variant="danger"
        placement="top"
    >
        <div v-html="dayTooltipMessage"></div>
    </b-tooltip>
</b-input-group>
<div class="atx-date-picker-wrapper" v-else>
    <datepicker
        v-bind="$attrs"
        v-on="$listeners"
        ref="atx-date-picker"
        :inline="inline"
        class="atx-date-picker"
        :input-class="{
            ...inputClass,
        }"
        :wrapper-class="{
            ...wrapperClass,
        }"
        :calendar-class="calendarClassMerged"
        :disabled-dates="disabledDates"
        :day-cell-content="dayCellContent"
        :format="format"
        :minimumView="minimumView"
        :maximumView="maximumView"
        :clear-button="clearButton && !inline"
        bootstrap-styling
        @changedMonth="attachEventListeners"
    >
    </datepicker>
    <b-tooltip
        v-if="dayTooltip && dayTooltipMessage"
        show
        :target="dayTooltip.target"
        triggers="hover"
        variant="danger"
        placement="top"
    >
        <div v-html="dayTooltipMessage"></div>
    </b-tooltip>
</div>
