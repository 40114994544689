import Vue from "vue";
/**
 * The solution here is a bit hacky but it works.
 * There is virtually no guarantee the event listeners will be bound before the events are emitted.
 * In our case its even worse because we are using 2 completely separate components (TheHeader and TheUserSettingsView) and trying to communicate.
 * We know their order in the DOM elements but we are not 100% certain that the header will load before the billing component.
 * Here we have a mechanism that keeps the early-emitted values in memory and apply them upon binding the listeners.
 * @type {Vue}
 */
Vue.prototype.$bus = new Vue();
const lastValues = {} as Record<string, unknown>;
const originalEmit = Vue.prototype.$bus.$emit.bind(Vue.prototype.$bus);
const originalOn = Vue.prototype.$bus.$on.bind(Vue.prototype.$bus);

Vue.prototype.$bus.$emit = function (name: string, value: string) {
    lastValues[name] = value;
    originalEmit(name, value);
};

Vue.prototype.$bus.$on = function (name: string, callback: CallableFunction) {
    if (lastValues[name] !== undefined) {
        callback(lastValues[name]);
    }

    originalOn(name, callback);
};
