<template>
    <b-table-simple v-bind="$attrs">
        <b-tbody>
            <b-tr v-for="n in rowNumber" :key="n">
                <b-td
                    v-for="(field, index) in fields"
                    :key="field.key + 'a' + index"
                    :class="field.thClass"
                    ><b-skeleton class="m-2"
                /></b-td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        rowNumber: {
            type: Number,
            default: 5,
        },
        fields: {
            type: Array as () => Record<string, string>[],
            default: () => [],
        },
    },
});
</script>

<style scoped></style>
