import { WhitelistPageList } from "./models";
import {
    ACCOUNT_SETTINGS_PAGE_ROUTES,
    ADMIN_PAGE_ROUTES,
    AMAZON_PAGE_ROUTES,
    BILLING_PAGE_ROUTES,
    MAPPING_PAGE_ROUTES,
    OMNI_ANALYTICS_PAGES,
    OVERVIEW_PAGE_ROUTES,
    PARTNER_PAGE_ROUTES,
    REGEX_ROUTES,
    REPORTS_PAGE_ROUTES,
    SETTLEMENT_PAGE_ROUTES,
    SHOPIFY_PAGE_ROUTES,
    USER_SETTINGS_PAGE_ROUTES,
} from "./router/routeConstants";

/**
 * A2X URL. Should start with /rest
 */
let baseUrlA2X: string;
/**
 * A2X2 URL. Should start with /api
 */
let baseUrlA2X2: string;

const productionUrl = "https://www.a2xaccounting.com";

if (window.location.origin.includes("shire-staging")) {
    baseUrlA2X2 = "https://shire-a2x2-staging.a2xaccounting.com";
} else if (window.location.origin.includes("shire2-staging")) {
    baseUrlA2X2 = "https://shire2-a2x2-staging.a2xaccounting.com";
} else if (window.location.origin.includes("avengers-staging")) {
    baseUrlA2X2 = "https://avengers-a2x2-staging.a2xaccounting.com";
} else if (window.location.origin.includes("club-penguin-staging")) {
    baseUrlA2X2 = "https://club-penguin-a2x2-staging.a2xaccounting.com";
} else if (window.location.origin.includes("clarity-staging")) {
    baseUrlA2X2 = "https://clarity-a2x2-staging.a2xaccounting.com";
} else if (window.location.origin.includes("dev") || window.location.origin.includes("staging")) {
    baseUrlA2X2 = "https://a2x2-staging-cloudrun.a2xaccounting.com";
} else if (window.location.origin.includes("a2xaccounting")) {
    baseUrlA2X2 = "https://a2x2-cloudrun.a2xaccounting.com";
} else if (window.location.origin.includes("a2x.app")) {
    baseUrlA2X2 = "https://a2x.app";
} else {
    baseUrlA2X2 = "http://localhost:8070";
}

if (window.location.origin.includes("shire-staging")) {
    baseUrlA2X = "https://shire-staging.a2xaccounting.com";
} else if (window.location.origin.includes("shire2-staging")) {
    baseUrlA2X = "https://shire2-staging.a2xaccounting.com";
} else if (window.location.origin.includes("avengers-staging")) {
    baseUrlA2X = "https://avengers-staging.a2xaccounting.com";
} else if (window.location.origin.includes("club-penguin-staging")) {
    baseUrlA2X = "https://club-penguin-staging.a2xaccounting.com";
} else if (window.location.origin.includes("clarity-staging")) {
    baseUrlA2X = "https://clarity-staging.a2xaccounting.com";
} else if (window.location.origin.includes("dev") || window.location.origin.includes("staging")) {
    baseUrlA2X = "https://staging.a2xaccounting.com";
} else if (window.location.origin.includes("a2xaccounting")) {
    baseUrlA2X = "https://www.a2xaccounting.com";
} else if (window.location.origin.includes("a2x.app")) {
    baseUrlA2X = "https://a2x.app";
} else {
    baseUrlA2X = "http://localhost:8070";
}

const stagingBackendAccount = "https://staging.a2xaccounting.com/backend/account?account=";

const PAGE_LAYOUTS = {
    ADMIN: "ADMIN",
    FULL_WIDTH: "FULL_WIDTH",
    FULL_WIDTH_SIDEBAR_LEFT: "FULL_WIDTH_SIDEBAR_LEFT",
    FULL_WIDTH_SIDEBAR_RIGHT: "FULL_WIDTH_SIDEBAR_RIGHT",
    TABBED: "TABBED_CONTENT",
};

// This is used to whitelist vue routes that come after /app/
const WHITELIST_PAGES = {
    /**
     * root page with many sub pages
     * e.g. /admin (immediately whitelisting everything under /admin/*)
     */
    [ADMIN_PAGE_ROUTES.ADMIN.fullPath]: {
        layout: PAGE_LAYOUTS.ADMIN,
    },
    [ADMIN_PAGE_ROUTES.CONNECT_QUICKBOOKS.fullPath]: {
        layout: PAGE_LAYOUTS.ADMIN,
    },
    [ACCOUNT_SETTINGS_PAGE_ROUTES.ACCOUNT_SETTINGS.fullPath]: {
        layout: PAGE_LAYOUTS.TABBED,
    },
    [USER_SETTINGS_PAGE_ROUTES.NOTIFICATIONS.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH_SIDEBAR_LEFT,
        layoutOptions: {
            pageHeader: false,
        },
    },
    [USER_SETTINGS_PAGE_ROUTES.SETTINGS_NOTIFICATIONS.fullPath]: {
        layout: PAGE_LAYOUTS.TABBED,
    },
    [USER_SETTINGS_PAGE_ROUTES.USER_SETTINGS.fullPath]: {
        layout: PAGE_LAYOUTS.TABBED,
        layoutOptions: {
            pageHeader: false,
        },
    },
    [BILLING_PAGE_ROUTES.BILLING.fullPath]: {
        layout: PAGE_LAYOUTS.TABBED,
        layoutOptions: {
            pageHeader: false,
        },
    },
    /**
     * Sales channel specific pages
     */
    [SHOPIFY_PAGE_ROUTES.RECONCILIATION_REPORTS.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [SHOPIFY_PAGE_ROUTES.RECONCILIATION_REPORT.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
        layoutOptions: {
            table: true,
        },
    },
    [SHOPIFY_PAGE_ROUTES.SINGLE_ORDER_INVOICE_REPORT.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [SHOPIFY_PAGE_ROUTES.ORDER.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [AMAZON_PAGE_ROUTES.DATE_RANGE_SUMMARY_REPORT.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    /**
     * Generic specific pages that are part of each sales channel OR accounting system
     */
    [MAPPING_PAGE_ROUTES.MAPPING.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
        layoutOptions: {
            table: true,
        },
    },
    [SETTLEMENT_PAGE_ROUTES.SETTLEMENTS.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
        layoutOptions: {
            table: true,
        },
    },
    [SETTLEMENT_PAGE_ROUTES.B2B_SETTLEMENTS.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
        layoutOptions: {
            table: true,
        },
    },
    [SETTLEMENT_PAGE_ROUTES.APP_SETTLEMENT.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [PARTNER_PAGE_ROUTES.PARTNER_DASHBOARD.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [SHOPIFY_PAGE_ROUTES.RECONNECT.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [OVERVIEW_PAGE_ROUTES.OVERVIEW_SETTLEMENTS.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [REPORTS_PAGE_ROUTES.APP_EXPORT_DATA.path]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [OMNI_ANALYTICS_PAGES.DASHBOARD.path]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [OMNI_ANALYTICS_PAGES.CUSTOM_ANALYSIS.path]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    [OMNI_ANALYTICS_PAGES.DIRECTORY.path]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
} as WhitelistPageList;

const WHITELIST_PUBLIC_PAGES = {
    "/oauth/microsoft": {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    "/oauth/google": {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    "/oauth/firebase": {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
    "/oauth/user-management": {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
    },
};

const PAGE_SKELETON_LIST = {
    ...WHITELIST_PAGES,
    [REGEX_ROUTES.CONNECT_SALES_CHANNEL.regex]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
        layoutOptions: {
            backButton: true,
            pageHeader: false,
            mediumBlock: true,
        },
    },
    [REGEX_ROUTES.CONNECT_ACCOUNTING.regex]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
        layoutOptions: {
            backButton: true,
            pageHeader: false,
            mediumBlock: true,
        },
    },
    [REGEX_ROUTES.SALES_CHANNEL_OR_ACCOUNTING_CALLBACK.regex]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
        layoutOptions: {
            backButton: true,
            pageHeader: false,
            mediumBlock: true,
        },
    },
    [ADMIN_PAGE_ROUTES.REQUEST_ACCESS.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH,
        layoutOptions: {
            backButton: true,
            pageHeader: false,
            mediumBlock: true,
        },
    },
    [BILLING_PAGE_ROUTES.CHECKOUT.fullPath]: {
        layout: PAGE_LAYOUTS.FULL_WIDTH_SIDEBAR_RIGHT,
        layoutOptions: {
            pageHeader: false,
        },
    },
};

// TODO: Have separate file for the const messages
const ERROR_MESSAGE_GENERAL = "Something went wrong. Please try again later.";
const ERROR_MESSAGE_GENERAL_CONTACT_SUPPORT = "Something went wrong. Please contact support.";
const ERROR_MESSAGE_NETWORK_ERROR = "Network error. Please try again later.";
const ERROR_MESSAGE_AUTH_CANCELLED =
    "We were unable to connect to your account because the authorization was cancelled.";
const ERROR_ESSAGE_MANY_ATTEMPTS =
    "We noticed a lot of sign-in attempts recently. Please wait a few minutes and try again.";
const ERROR_MESSAGE_POPUP_BLOCKED =
    "Popup blocked by browser. Please enable popups and try again by refreshing the page.";
const ERROR_MESSAGE_POPUP_CLOSED = "Authentication popup closed. Please login again.";
const ERROR_MESSAGE_EMAIL_NOT_VERIFIED =
    "To complete your registration, please verify your email. We've just sent you an email with instructions.";
const ERROR_MESSAGE_COULD_NOT_VERIFY_EMAIL = "Could not verify email. Please try again later.";
const ERROR_COULD_NOT_SEND_INVITE = "Could not send an invite. Please try again later.";
const ERROR_MESSAGE_SALES_CHANNEL_CONNECT_FAILURE = "Could not connect to sales source.";
const ERROR_MESSAGE_PARTNER_DASHBOARD_FAILURE =
    "There was an issue loading the dashboard, please contact customer support.";
const ERROR_COULD_NOT_CONNECT_TO_ACCOUNTING_SYSTEM = "Could not connect to accounting system.";
const MESSAGE_NO_DATA_FOUND = "we found no information to display";
const REFERRER_MISSING_COOKIE = "69788cac80f08ae14ee4ed5d1162da0f";
const REFERRER_UNBLOCK_COOKIE = "9fd4935f814de846edd7705c883c45b4";
const MESSAGE_REFRESH_TOKEN_EXPIRED = "Refresh Token expired.";

const RANDOM_COLOR_PALLETTE = [
    "#FFDBDB",
    "#E4CCFF",
    "#DF91E5",
    "#FFEBAE",
    "#FFB040",
    "#D8A154",
    "#FCD19C",
    "#CCBA82",
    "#97B6CC",
    "#85B0FF",
    "#95D9E2",
    "#C0F3C5",
];

export {
    baseUrlA2X2,
    baseUrlA2X,
    productionUrl,
    stagingBackendAccount,
    WHITELIST_PAGES,
    PAGE_SKELETON_LIST,
    ERROR_MESSAGE_GENERAL,
    ERROR_MESSAGE_SALES_CHANNEL_CONNECT_FAILURE,
    ERROR_MESSAGE_PARTNER_DASHBOARD_FAILURE,
    ERROR_COULD_NOT_SEND_INVITE,
    ERROR_COULD_NOT_CONNECT_TO_ACCOUNTING_SYSTEM,
    MESSAGE_NO_DATA_FOUND,
    REFERRER_MISSING_COOKIE,
    REFERRER_UNBLOCK_COOKIE,
    RANDOM_COLOR_PALLETTE,
    PAGE_LAYOUTS,
    WHITELIST_PUBLIC_PAGES,
    ERROR_MESSAGE_NETWORK_ERROR,
    ERROR_MESSAGE_POPUP_BLOCKED,
    ERROR_MESSAGE_POPUP_CLOSED,
    ERROR_MESSAGE_EMAIL_NOT_VERIFIED,
    ERROR_MESSAGE_COULD_NOT_VERIFY_EMAIL,
    ERROR_MESSAGE_AUTH_CANCELLED,
    ERROR_ESSAGE_MANY_ATTEMPTS,
    MESSAGE_REFRESH_TOKEN_EXPIRED,
    ERROR_MESSAGE_GENERAL_CONTACT_SUPPORT,
};

declare global {
    // to be used for sending events to GTM where vue context is not available
    interface Window {
        gtag: (...args: unknown[]) => void;
        // fbq is the Facebook Pixel function which is loaded in the index.html
        fbq: (...args: unknown[]) => void;
        // lintrk is the LinkedIn Pixel function which is loaded in the index.html
        lintrk: (...args: unknown[]) => void;
    }
}

/**
 * This was tied to the googleAuth file which we don't use for now. We will revisit this one at later stage
 */
// declare global {
//     interface Window {
//         gapi: any;
//         onGoogleApiLoad: any;
//     }
// }
