<template>
    <b-card class="mb-4">
        <div class="d-flex align-items-center justify-content-between mb-3">
            <h5 class="mb-0"><b-skeleton width="200px" height="18px" /></h5>
            <b-link id="btn-actions-toggle"><b-skeleton width="100px" height="18px" /></b-link>
        </div>
        <!-- Wrapper for  settlements-table-trial-overlay -->
        <div class="settlements-table-trial-overlay-wrapper position-relative">
            <settlements-empty-table-skeleton :small="true" class="table-a2x-slim" />
        </div>
    </b-card>
</template>

<script lang="ts">
import Vue from "vue";
import SettlementsEmptyTableSkeleton from "../../../settlements/__components__/skeletons/SettlementsEmptyTable.vue";

export default Vue.extend({
    components: {
        SettlementsEmptyTableSkeleton,
    },
});
</script>

<style scoped></style>
