import { AtxVariants, BaseVariants } from "@/models/generic.model";

export function setToastSettings(
    variant: BaseVariants | AtxVariants = "success",
    title = "Notification",
    message = "n/a"
): {
    toastTitle: string;
    toastMessage: string;
    toastVariant: string;
    bodyClass: string;
    noAutoHide: boolean;
    noCloseButton: boolean;
} {
    const toastTitle = title;
    const toastMessage = message;
    const toastVariant = variant;
    const bodyClass = toastMessage === "n/a" ? "d-none" : "";
    let noAutoHide = true;
    let noCloseButton = !noAutoHide;

    // Only show close button and prevent auto hide if message is too long for success toast
    if (variant === "success") {
        noAutoHide = message.length >= 50 || title.length >= 50 ? true : false;
        noCloseButton = !noAutoHide;
    }

    return { toastTitle, toastMessage, toastVariant, bodyClass, noAutoHide, noCloseButton };
}

/**
 * Low priority notification component
 * Optional: Toast disappear when dismissed by the user or when page is refreshed
 * https://bootstrap-vue.org/docs/components/toast#toast
 * @param vm pass 'this', if you are not sure what to use
 * @param variant variants set styling for the toast, check for '$theme-colors'
 * @param title use this to show your message
 * @param message use this to append an extra block if needed
 */
export function toast(
    vm: Vue,
    variant?: BaseVariants | AtxVariants,
    title?: string,
    message?: string
): void {
    // Set toast settings
    const { toastTitle, toastMessage, toastVariant, bodyClass, noAutoHide, noCloseButton } =
        setToastSettings(variant, title, message);

    vm.$root.$bvToast.toast(toastMessage, {
        title: toastTitle,
        appendToast: false,
        solid: true,
        variant: toastVariant as string,
        noCloseButton: noCloseButton,
        noAutoHide: noAutoHide,
        toastClass: "atx-toaster",
        bodyClass: bodyClass,
        autoHideDelay: 3000,
    });
}
