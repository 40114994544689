
<b-form-group
    :id="`${id}-form-group`"
    :label="label"
    label-class="font-weight-bold"
    :label-for="id"
>
    <atx-select
        :id="id"
        class="bg-white"
        :value="value"
        :options="options"
        :searchable="searchable"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="changeHandler"
    ></atx-select>
</b-form-group>
