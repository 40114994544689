<template>
    <b-card
        :class="[
            'd-inline-block',
            'payment-card-details',
            'mb-4',
            'bg-atx-grey-1',
            'border-atx-grey-2',
            { 'with-coupon': couponToBeApplied },
        ]"
    >
        <div>
            <img v-if="cardImagePath" class="bg-white" :src="cardImagePath" />
            <b-icon v-else class="h1" icon="credit-card2-front" />

            <span class="float-right h6">
                <b-link class="text-atx-blue-2" @click="(event) => changeBillingInformation(event)"
                    >Update <b-icon class="ml-1" icon="pencil" />
                </b-link>
            </span>
        </div>
        <h4 class="font-weight-bold mb-1 d-flex align-items-center">
            <template v-for="n in 12">
                <b-icon :key="n" icon="asterisk" class="font-weight-bold mr-1 card-asterisk" />
                {{ n % 4 == 0 ? "&nbsp;&nbsp;" : "" }}
            </template>
            <span style="letter-spacing: 2px">{{
                paymentMethod ? paymentMethod.lastFourDigits : ""
            }}</span>
        </h4>
        <small class="text-atx-grey-4 d-inline-block mb-3">Expires {{ cardExpireDate }}</small>
        <div class="d-flex justify-content-between">
            <div class="font-weight-bold">
                {{ cardHolderName }}
            </div>
            <atx-badge
                v-if="couponToBeApplied"
                :text="couponToBeApplied"
                icon="tag-fill"
                :extraText="`${discount && discount.percentOff ? discount.percentOff + '%' : ''}`"
                variant="atx-green-1"
                extraTextVariant="atx-green-2"
            />
        </div>
    </b-card>
</template>
<script lang="ts">
import Vue from "vue";
import { baseUrlA2X } from "@/globals";
import { getIconPath } from "@/helpers";
import { IPaymentMethod } from "@/models";
import AtxBadge from "@/components/feedback/AtxBadge/AtxBadge.vue";
export default Vue.extend({
    components: { AtxBadge },
    props: {
        paymentMethod: {
            type: Object as () => IPaymentMethod,
            required: true,
        },
        discount: {
            type: Object as () => Record<string, string> | null,
            required: false,
        },
        couponToBeApplied: {
            type: String as () => string | null,
            required: false,
        },
        cardHolderName: {
            type: String,
            required: true,
        },
    },
    computed: {
        domainPath(): string {
            return baseUrlA2X;
        },
        cardExpireDate(): string | undefined {
            let d, dReadable;
            if (this.paymentMethod.expirationMonth && this.paymentMethod.expirationYear) {
                const month = this.paymentMethod.expirationMonth;
                const year = this.paymentMethod.expirationYear;
                d = new Date(year, month - 1, 15);
                dReadable = d.toLocaleString("default", {
                    month: "short",
                    year: "numeric",
                });
            }
            return dReadable;
        },
        cardImagePath(): string {
            return this.paymentMethod.type && getIconPath(this.paymentMethod.type.toLowerCase())
                ? `${this.domainPath}/${getIconPath(this.paymentMethod.type.toLowerCase())}`
                : "";
        },
    },
    methods: {
        getIconPath: getIconPath,
        changeBillingInformation(event: PointerEvent) {
            this.$emit("change-billing-information", event);
        },
    },
});
</script>
<style lang="scss">
.payment-card-details {
    border-radius: 16px;
    max-width: 400px;
    width: 100%;

    &.with-coupon {
        max-width: 450px;
    }

    .card-asterisk {
        font-size: 14px;
    }

    img {
        max-height: 70px;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid #dadada;
    }
}
</style>
