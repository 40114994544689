
<b-button-group class="position-static" :data-test-id="testId">
    <b-dropdown
        ref="atx-dropdown-select"
        variant="light"
        class="atx-dropdown mx-1"
        toggle-class="border-radius-4 px-2 py-1"
        menu-class="border-radius-12"
        :disabled="dropdownDisabled"
        boundary="window"
        no-caret
        @show="openFilterSelect"
        @hide="closeFilterSelect"
    >
        <template #button-content>
            <span class="dropdown-toggle-button-text">{{ dropdownButtonText }}</span>
            <atx-filter-counter-badge v-if="badgeCounter > 0" :counter="badgeCounter" />
            <atx-icon :icon="filterSelectShouldOpen ? 'chevron-up' : 'chevron-down'" />
        </template>

        <div class="text-center my-2" v-if="isLoading">
            <b-spinner class="align-middle"></b-spinner>
        </div>
        <atx-select
            v-else
            class="px-2 atx-select"
            v-on="$listeners"
            v-bind="$attrs"
            transition=""
            :dropdown-should-open="() => filterSelectShouldOpen"
            :close-on-select="false"
            deselectFromDropdown
            searchable
            :reduce="enableTooltips ? (options) => options.label : undefined"
        >
            <template v-slot:option="option">
                <atx-icon class="mr-1" :icon="getSelectedIcon(option)" />
                {{ getOptionLabel(option) }}
                <atx-icon
                    v-if="enableTooltips && getOptionsTooltip(option)"
                    icon="info-circle"
                    class="ml-2"
                    v-b-popover.hover="{
                        content: getOptionsTooltip(option),
                        boundary: 'viewport', // this allows proper positioning of the popover, due to constrained space
                    }"
                />
            </template>
            <template #footer>
                <atx-button-link class="ml-3" @click="clearInputs">Clear</atx-button-link>
            </template>
        </atx-select>
    </b-dropdown>
</b-button-group>
