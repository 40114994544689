import { getPageTitle } from "@/helpers";
import { AMAZON_PAGE_ROUTES } from "@/router/routeConstants";

export const DateRangeSummaryReportRoutes = [
    {
        path: AMAZON_PAGE_ROUTES.DATE_RANGE_SUMMARY_REPORT.path,
        name: AMAZON_PAGE_ROUTES.DATE_RANGE_SUMMARY_REPORT.name,
        meta: {
            title: getPageTitle("Summary Report"),
            pageHeading: "Summary Report",
            pageHeaderOverride: true,
        },
        component: (): Promise<typeof import("*.vue")> =>
            import(/* webpackChunkName: "DateRangeSummaryReport" */ "./DateRangeSummaryReport.vue"),
    },
];
