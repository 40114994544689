<template>
    <div>
        <h4 class="mt-5 mb-3 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-baseline">
                <b-skeleton width="150px" height="24px" />
            </div>
        </h4>
        <b-card-group deck class="row no-gutters">
            <div class="col-md-4">
                <b-card class="mb-2">
                    <div class="mb-0">
                        <span class="d-flex justify-content-between align-items-center"
                            ><b-skeleton width="100px" height="18px" /><b-skeleton
                                variant="atx-blue-1"
                                class="float-right"
                                width="48px"
                        /></span>
                    </div>
                    <atx-separator />
                    <b-card-text>
                        <b-skeleton width="50px" height="18px" />
                    </b-card-text>
                </b-card>
                <b-card>
                    <div class="mb-0">
                        <span class="d-flex justify-content-between align-items-center"
                            ><b-skeleton width="100px" height="18px" /><b-skeleton
                                variant="atx-blue-1"
                                class="float-right"
                                width="48px"
                        /></span>
                    </div>
                    <atx-separator />
                    <b-card-text class="my-6">
                        <b-skeleton width="50px" height="18px" />
                    </b-card-text>
                </b-card>
            </div>
            <b-card class="chart-card">
                <monthly-net-payments-chart-skeleton />
            </b-card>
            <b-card class="chart-card">
                <monthly-orders-chart-skeleton />
            </b-card>
        </b-card-group>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import MonthlyNetPaymentsChartSkeleton from "./MonthlyNetPaymentsChart.vue";
import MonthlyOrdersChartSkeleton from "./MonthlyOrdersChart.vue";

export default Vue.extend({
    components: {
        MonthlyNetPaymentsChartSkeleton,
        MonthlyOrdersChartSkeleton,
    },
});
</script>

<style scoped></style>
