import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

const defaultOptions = {};

function axiosProvider(
    baseUrl: string | undefined,
    options: AxiosRequestConfig | undefined
): AxiosInstance {
    return axios.create({
        baseURL: baseUrl,
        ...defaultOptions,
        ...options,
    });
}

export default axiosProvider;
