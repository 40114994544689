
<b-dropdown
    class="atx-dropdown-ellipsis"
    variant="link"
    toggle-tag="a"
    toggle-class="p-0 text-atx-blue-4"
    menu-class="p-0 border-atx-grey-2 border-radius-12 shadow-sm"
    no-caret
    right
>
    <template #button-content>
        <atx-icon icon="three-dots" class="fa-fw" />
    </template>
    <slot></slot>
</b-dropdown>
