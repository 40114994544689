export const ReconciliationReportsRoutes = [
    {
        path: "/reconciliation-reports",
        name: "ReconciliationReports",
        meta: {
            pageHeading: "Reconciliation Reports",
            pageHeaderOverride: true,
        },
        component: (): Promise<typeof import("*.vue")> =>
            import(/* webpackChunkName: "ReconciliationReports" */ "./ReconciliationReports.vue"),
        children: [
            {
                path: "report/:reconciliationReportId",
                component: (): Promise<typeof import("*.vue")> => import("./report/Report.vue"),
                name: "Report",
                meta: {
                    pageHeading: "Report",
                    pageHeaderOverride: true,
                },
                props: true,
            },
        ],
    },
];
