import { ISettlement } from "@/models/settlement.models";

export const settlementsEmptyTableData = {
    limit: 10,
    cursor: "",
    total: 10,
    data: [
        {
            accountKey: "",
            created: "",
            updated: "",
            start: "",
            end: "",
            total: 1,
            channel: "",
            gateway: "",
            currency: "",
            salesSource: "",
            reportId: "",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: null,
            cogsPostingStatus: null,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "",
            created: "",
            updated: "",
            start: "",
            end: "",
            total: 1,
            channel: "",
            gateway: "",
            currency: "",
            salesSource: "",
            reportId: "",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: null,
            cogsPostingStatus: null,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "",
            created: "",
            updated: "",
            start: "",
            end: "",
            total: 1,
            channel: "",
            gateway: "",
            currency: "",
            salesSource: "",
            reportId: "",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: null,
            cogsPostingStatus: null,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "",
            created: "",
            updated: "",
            start: "",
            end: "",
            total: 1,
            channel: "",
            gateway: "",
            currency: "",
            salesSource: "",
            reportId: "",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: null,
            cogsPostingStatus: null,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "",
            created: "",
            updated: "",
            start: "",
            end: "",
            total: 1,
            channel: "",
            gateway: "",
            currency: "",
            salesSource: "",
            reportId: "",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: null,
            cogsPostingStatus: null,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "",
            created: "",
            updated: "",
            start: "",
            end: "",
            total: 1,
            channel: "",
            gateway: "",
            currency: "",
            salesSource: "",
            reportId: "",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: null,
            cogsPostingStatus: null,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "",
            taxesMapped: null,
            accountsMapped: null,
        },
    ] as ISettlement[],
};
