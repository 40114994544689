<template>
    <div class="bg-atx-blue-3">
        <b-container id="navigation" fluid="fluid" class="py-1">
            <b-row>
                <b-col>
                    <b-navbar
                        class="pl-0 pr-0"
                        toggleable="lg"
                        style="padding-top: 0.3rem !important; padding-bottom: 0.3rem !important"
                    >
                        <b-navbar-brand>
                            <b-skeleton-img
                                animation
                                width="80px"
                                height="40px"
                                class="d-inline-block"
                            />
                        </b-navbar-brand>

                        <b-navbar-toggle target="nav-collapse" class="order-3"></b-navbar-toggle>

                        <b-navbar
                            class="p-0 ml-auto justify-content-end align-self-end order-lg-1 nav-secondary"
                        >
                            <b-navbar-nav align="right" class="align-items-center">
                                <b-nav-item class="d-none d-md-block"
                                    ><b-skeleton
                                        animation
                                        type="avatar"
                                        width="24px"
                                        height="24px"
                                    ></b-skeleton
                                ></b-nav-item>
                                <b-nav-item class="d-none d-md-block">
                                    <b-skeleton
                                        animation
                                        type="avatar"
                                        width="24px"
                                        height="24px"
                                    ></b-skeleton>
                                </b-nav-item>
                                <b-nav-item class="d-none d-md-block">
                                    <b-skeleton
                                        animation
                                        type="avatar"
                                        width="24px"
                                        height="24px"
                                    ></b-skeleton>
                                </b-nav-item>

                                <b-nav-item class="d-none d-md-block">
                                    <b-skeleton
                                        animation
                                        width="80px"
                                        height="24px"
                                        class="d-inline-block m-0 align-middle"
                                    />
                                </b-nav-item>
                                <b-nav-item class="d-none d-md-block">
                                    <b-skeleton
                                        animation
                                        width="80px"
                                        height="24px"
                                        class="d-inline-block m-0 align-middle"
                                    />
                                </b-nav-item>

                                <b-nav-item-dropdown no-caret>
                                    <template #button-content>
                                        <b-skeleton
                                            animation
                                            type="avatar"
                                            width="32px"
                                            height="32px"
                                            class="d-inline-block m-0 align-middle"
                                        />
                                    </template>
                                </b-nav-item-dropdown>
                            </b-navbar-nav>
                        </b-navbar>
                        <b-collapse id="nav-collapse" class="order-lg-0 order-3" is-nav>
                            <b-navbar-nav>
                                <b-nav-item>
                                    <b-skeleton animation width="80px" height="16px"></b-skeleton>
                                </b-nav-item>
                                <b-nav-item>
                                    <b-skeleton animation width="80px" height="16px"></b-skeleton>
                                </b-nav-item>
                                <b-nav-item>
                                    <b-skeleton animation width="80px" height="16px"></b-skeleton>
                                </b-nav-item>
                                <b-nav-item>
                                    <b-skeleton animation width="80px" height="16px"></b-skeleton>
                                </b-nav-item>
                            </b-navbar-nav>
                        </b-collapse>
                    </b-navbar>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({});
</script>

<style scoped></style>
