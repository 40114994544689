export function getUrlParams(): URLSearchParams {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    return params;
}

export function getAccountKeyParam(): string {
    const urlParams = getUrlParams();
    let accountKey = urlParams.get("account");
    if (accountKey) {
        return accountKey;
    }
    accountKey = urlParams.get("accountKey");
    if (accountKey) {
        return accountKey;
    }
    return "";
}

export function getUserIdParam(): string {
    const urlParams = getUrlParams();
    const userId = urlParams.get("userId");
    if (userId) {
        return userId;
    }
    return "";
}
