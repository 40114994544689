
<b-card
    :class="componentName"
    :data-test-id="componentName"
    :aria-expanded="visible ? 'true' : 'false'"
>
    <b-row align-v="center" role="button" @click="toggleCollapse">
        <b-col cols="10"
            ><h5 class="mb-0">{{ title }}</h5></b-col
        >
        <b-col cols="2" class="text-right">
            <atx-icon :icon="icon" />
        </b-col>
    </b-row>
    <template v-if="persistent">
        <b-collapse v-model="visible" class="mt-4">
            <div v-if="busy" class="m-5 p-5 text-center">
                <b-spinner />
            </div>
            <slot v-else />
        </b-collapse>
    </template>
    <template v-else>
        <div v-if="visible">
            <div v-if="busy" class="m-5 p-5 text-center">
                <b-spinner />
            </div>
            <slot v-else />
        </div>
    </template>
</b-card>
