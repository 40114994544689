import { AxiosResponse } from "axios";
import { catchErrorHandler, isProductionEnv } from "@/helpers";
import { a2xBaseApi } from "@/services/api.service";
import { getWhatsNewDataAsync } from "@/api/whats-new.api.mock";

export type WhatsNewDataResponse = {
    date: string;
    permalink: string;
    title: string;
};

export const whatsNewApi = {
    async getWhatsNewDataAsync(): Promise<WhatsNewDataResponse[]> {
        const url = `/res/whats-new.json`;

        if (isProductionEnv()) {
            try {
                const response: AxiosResponse = await a2xBaseApi.get(url);
                return response.data;
            } catch (e) {
                return catchErrorHandler(e);
            }
        } else {
            return getWhatsNewDataAsync;
        }
    },
};
