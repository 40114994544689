import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import "./main.scss";
import App from "./App.vue";
import PublicApp from "./PublicApp.vue";
import VueGtag from "vue-gtag";
import router from "./router/index";
import pinia from "@/setup/setupPinia";
import { AuthenticateUserResponse, useAuthStore } from "@/stores/modules/auth";
import VueCookies from "vue-cookies";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
// Import services and extension/package setups
import "@/services/bus.service";
import { setupVeeValidate } from "./setup/setupVeeValidate";
import { setupAtxLibrary } from "./setup/setupAtxLibrary";
import { setupPageSkeleton } from "./setup/setupPageSkeleton";
import { setupAppDataAsync } from "./setup/setupAppData";
import { redirectToLogin } from "./helpers";
import InternalErrorSkeleton from "@/skeletons/pages/InternalErrorSkeleton.vue";
import publicRouter from "./router/public";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(
    VueGtag,
    {
        bootstrap: false, // Delay loading until we have the needed data
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pageTrackerTemplate(to: Record<string, any>) {
            const metaInfo = to.meta;
            let pageTitle = to.name;
            if (metaInfo !== undefined) {
                pageTitle = metaInfo.title;
            }
            return {
                page_title: pageTitle,
                page_path: to.path,
            };
        },
    },
    router
);
Vue.use(VueCookies);
Vue.use(PerfectScrollbar);

// Setup package options and our library
setupVeeValidate();
setupAtxLibrary();

// Instantiate the App
const atxApp = new Vue({
    pinia,
    router,
    render: (h) => h(App),
});

// Instantiate the store
const authStore = useAuthStore(pinia);

// Setup page skeleton
const atxAppSkeleton = setupPageSkeleton();

// Have InternalErrorSkeleton in case something fails with data fetch
const internalErrorSkeleton = new Vue({
    render: (h) => h(InternalErrorSkeleton),
});

// For now its a simple vue template imported as we don't need anymore pages. Once we expand we can turn this into a separate App with vue-router
const publicApp = new Vue({
    router: publicRouter,
    render: (h) => h(PublicApp),
});

// Try to auth user
authStore.authenticateUser().then(async (resp: AuthenticateUserResponse) => {
    if (!resp.public && resp.authenticated) {
        atxAppSkeleton.$mount("#app-skeleton");

        try {
            await setupAppDataAsync();
        } catch (e) {
            internalErrorSkeleton.$mount("#app");
            return;
        }

        atxApp.$mount("#app");
    } else if (resp.public) {
        publicApp.$mount("#app");
    } else {
        redirectToLogin();
    }
});
