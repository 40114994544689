
<b-alert
    :show="show"
    :variant="variant"
    :dismissible="dismissible"
    :class="[
        'py-1',
        {
            'mt-3': topSpace,
            'mt-0': !topSpace,
            'my-3': topSpace && bottomSpace,
            'mb-3': bottomSpace,
            'mb-0': !bottomSpace,
        },
    ]"
>
    <div
        :class="[
            'd-flex',
            {
                'justify-content-center': center,
            },
        ]"
    >
        <div v-if="icon" class="align-self-center">
            <b-icon :icon="icon" :variant="iconVariant" />
        </div>
        <div :class="icon ? 'ml-3' : ''">
            <p class="m-0 py-2">
                <slot></slot>
            </p>
        </div>
    </div>
</b-alert>
