export enum FEATURE_FLAGS {
    SAVE_AMAZON_SETTLEMENT_REPORTS_INTO_SPANNER = "SAVE_AMAZON_SETTLEMENT_REPORTS_INTO_SPANNER",
    AMAZON_SUMMARY_REPORT = "AMAZON_SUMMARY_REPORT",
    NETSUITE_INTEGRATION_BETA = "NETSUITE_INTEGRATION_BETA",
    NEW_ADMIN_PAGE = "NEW_ADMIN_PAGE",
    SHOW_COGS_ONLY_REFRESH_BULK_ACTION = "SHOW_COGS_ONLY_REFRESH_BULK_ACTION",
    SHOPIFY_RECONCILIATION_TOOL_ENABLED = "SHOPIFY_RECONCILIATION_TOOL_ENABLED",
    ACCESS_AMAZON_PAY_REPORTS = "ACCESS_AMAZON_PAY_REPORTS",
    SHOPIFY_SCOPE_MAY_2024 = "SHOPIFY_SCOPE_MAY_2024",
    PAYPAL_SEND_INVOICE = "PAYPAL_SEND_INVOICE",
    AMAZON_TRANSACTIONS_API = "AMAZON_TRANSACTIONS_API",
    SHOPIFY_SINGLE_ORDER_ENABLED = "SHOPIFY_SINGLE_ORDER_ENABLED",
    A2X_CLARITY_ENABLED = "A2X_CLARITY_ENABLED",
}

export type Feature = {
    featureFlag: FEATURE_FLAGS;
    toggling: boolean;
    shouldExplode: boolean;
    shouldReloadPage: boolean;
    active: boolean;
    name: string;
    title: string;
    viewLink: string;
};
