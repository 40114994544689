<template>
    <span>{{ formatCurrency }}</span>
</template>

<script lang="ts">
import Vue from "vue";
import { currencyLocale } from "@/helpers/currencyUtils";

// NOTES:
// See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#parameters

export default Vue.extend({
    props: {
        amount: {
            type: Number,
            required: true,
        },
        currencyCode: {
            type: String,
            required: false,
        },
        locale: {
            type: String,
            required: false,
        },
    },
    computed: {
        formatCurrency(): string {
            let amountValue = this.amount;
            if (!this.amount) {
                amountValue = 0;
            }

            if (this.currencyCode) {
                // check if we have the proper currency key and look up the locale
                if (this.currencyCode as keyof typeof currencyLocale) {
                    const index = this.currencyCode.toUpperCase() as keyof typeof currencyLocale;
                    if (currencyLocale[index]) {
                        return new Intl.NumberFormat(currencyLocale[index].locale, {
                            style: "currency",
                            currency: this.currencyCode,
                        }).format(amountValue);
                    } else {
                        new Intl.NumberFormat(currencyLocale.USD.locale, {
                            style: "currency",
                            currency: "USD",
                        }).format(amountValue);
                    }
                }
            }

            return new Intl.NumberFormat(currencyLocale.USD.locale, {
                style: "currency",
                currency: "USD",
            }).format(amountValue);
        },
    },
});
</script>
