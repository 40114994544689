
<b-row class="h-100">
    <b-col md="4" class="d-flex align-items-end justify-content-around">
        <b-skeleton variant="atx-orange-1" width="96px" height="30%" />
    </b-col>
    <b-col md="4" class="d-flex align-items-end justify-content-around">
        <b-skeleton variant="atx-orange-1" width="96px" height="70%" />
    </b-col>
    <b-col md="4" class="d-flex align-items-end justify-content-around">
        <b-skeleton variant="atx-orange-1" width="96px" height="40%" />
    </b-col>
</b-row>
