import { ISettlement, PostingStatus } from "@/models/settlement.models";

export const settlementsTableData = {
    limit: 10,
    cursor: "Cj4KEgoFc3RhcnQSCQiAsJi6psL5AhIkaglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDA1gkMGAAgAQ",
    total: 10,
    data: [
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:06:47.383+0100",
            updated: "2023-01-26T15:06:47.383+0100",
            start: "2022-12-17T00:00:00.000+0100",
            end: "2022-12-31T00:00:00.000+0100",
            total: 1127.67,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "01032023",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: 33.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDA-gsM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:06:51.616+0100",
            updated: "2023-01-26T15:06:51.616+0100",
            start: "2022-12-03T00:00:00.000+0100",
            end: "2022-12-17T00:00:00.000+0100",
            total: 829.36,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "12202022",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: 28.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: false,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDAhgkM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:06:55.512+0100",
            updated: "2023-01-26T15:06:55.512+0100",
            start: "2022-11-19T00:00:00.000+0100",
            end: "2022-12-03T00:00:00.000+0100",
            total: 489.15,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "12062022",
            postedToXero: "2023-01-26T21:11:59.765+0100",
            cogsPosted: "2023-01-26T15:32:46.101+0100",
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.POSTED,
            cogsInvoicesTotal: 15.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDAxggM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:06:59.432+0100",
            updated: "2023-01-26T15:06:59.432+0100",
            start: "2022-11-05T00:00:00.000+0100",
            end: "2022-11-19T00:00:00.000+0100",
            total: 1733.9,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "11222022",
            postedToXero: "2023-01-26T21:11:58.767+0100",
            cogsPosted: "2023-01-26T15:33:03.228+0100",
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.POSTED,
            cogsInvoicesTotal: 50.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDAxgsM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:07:03.403+0100",
            updated: "2023-01-26T15:07:03.403+0100",
            start: "2022-10-22T00:00:00.000+0200",
            end: "2022-11-05T00:00:00.000+0100",
            total: 883.86,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "11082022",
            postedToXero: "2023-01-27T10:24:42.584+0100",
            cogsPosted: "2023-01-27T10:24:40.201+0100",
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.POSTED,
            cogsInvoicesTotal: 30.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDApgoM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:07:07.412+0100",
            updated: "2023-01-26T15:07:07.412+0100",
            start: "2022-10-08T00:00:00.000+0200",
            end: "2022-10-22T00:00:00.000+0200",
            total: 585.03,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "10252022",
            postedToXero: null,
            cogsPosted: "2023-01-27T10:33:43.334+0100",
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.POSTED,
            cogsInvoicesTotal: 17.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDA5ggM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:07:11.397+0100",
            updated: "2023-01-26T15:07:11.397+0100",
            start: "2022-09-24T00:00:00.000+0200",
            end: "2022-10-08T00:00:00.000+0200",
            total: 17.01,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "10112022",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: 7.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDA5gkM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:07:15.756+0100",
            updated: "2023-01-26T15:07:15.756+0100",
            start: "2022-09-10T00:00:00.000+0200",
            end: "2022-09-24T00:00:00.000+0200",
            total: 918.87,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "09272022",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: 34.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDAlgoM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:07:19.416+0100",
            updated: "2023-01-26T15:07:19.416+0100",
            start: "2022-08-27T00:00:00.000+0200",
            end: "2022-09-10T00:00:00.000+0200",
            total: -198.94,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "09132022",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: -57.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: true,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDAlgsM",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgIDAugkM",
            created: "2023-01-26T15:07:23.467+0100",
            updated: "2023-01-26T15:07:23.467+0100",
            start: "2022-08-13T00:00:00.000+0200",
            end: "2022-08-27T00:00:00.000+0200",
            total: -12.04,
            channel: "Walmart.com",
            gateway: null,
            currency: "USD",
            salesSource: "WALMART",
            reportId: "08302022",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: 58.0,
            cogsCurrency: "USD",
            allProductCostsAvailable: true,
            sellerId: "07da76df-a252-4469-b1e6-11c831ac60a2",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "aglhMngtbG9jYWxyFwsSClNldHRsZW1lbnQYgICAgIDA1gkM",
            taxesMapped: true,
            accountsMapped: true,
        },
    ] as ISettlement[],
};

export const settlementsTableDataShopify = {
    limit: 10,
    cursor: "CkMKEgoFc3RhcnQSCQiA8cHtzJX9AhIpag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjbsajdCgwYACAB",
    total: 10,
    data: [
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-23T02:51:36.847+0000",
            updated: "2023-02-23T02:51:49.369+0000",
            start: "2023-02-21T00:04:56.000+0000",
            end: "2023-02-23T06:00:00.000+0000",
            total: 373.9,
            channel: "ilovemyfreedom",
            gateway: "Shopify Payments",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "95258247337",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjPzprnCQw",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-23T05:19:39.637+0000",
            updated: "2023-02-23T05:19:54.292+0000",
            start: "2023-02-19T06:00:00.000+0000",
            end: "2023-02-20T05:59:59.000+0000",
            total: 14.57,
            channel: "ilovemyfreedom",
            gateway: "paypal",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "2023-02-19-2023-02-20",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOiflL7uCgw",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-23T05:19:55.686+0000",
            updated: "2023-02-23T05:20:06.903+0000",
            start: "2023-02-19T06:00:00.000+0000",
            end: "2023-02-20T05:59:59.000+0000",
            total: 42.72,
            channel: "ilovemyfreedom",
            gateway: "Order Edits",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "2023-02-19-2023-02-20",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjPyOn_Cgw",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-23T05:19:24.979+0000",
            updated: "2023-02-23T05:19:37.986+0000",
            start: "2023-02-19T06:00:00.000+0000",
            end: "2023-02-20T05:59:59.000+0000",
            total: 4073.52,
            channel: "ilovemyfreedom",
            gateway: "No_Gateway",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "2023-02-19-2023-02-20",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOifmJiiCww",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-23T04:13:17.572+0000",
            updated: "2023-02-23T04:13:19.344+0000",
            start: "2023-02-17T00:04:30.000+0000",
            end: "2023-02-22T06:00:00.000+0000",
            total: 1378.48,
            channel: "ilovemyfreedom",
            gateway: "Shopify Payments",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "95180292265",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOifmsSTCAw",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-23T00:51:53.514+0000",
            updated: "2023-02-23T00:52:04.352+0000",
            start: "2023-02-16T04:27:07.000+0000",
            end: "2023-02-21T06:00:00.000+0000",
            total: 109.2,
            channel: "ilovemyfreedom",
            gateway: "Shopify Payments",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "95145361577",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOj3gvbXCAw",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-22T18:33:04.732+0000",
            updated: "2023-02-22T18:33:26.939+0000",
            start: "2023-02-15T06:00:00.000+0000",
            end: "2023-02-16T05:59:59.000+0000",
            total: 55.86,
            channel: "ilovemyfreedom",
            gateway: "paypal",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "2023-02-15-2023-02-16",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOin1d2XCQw",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-22T18:33:03.410+0000",
            updated: "2023-02-22T18:33:38.021+0000",
            start: "2023-02-15T06:00:00.000+0000",
            end: "2023-02-16T05:59:59.000+0000",
            total: 12226.53,
            channel: "ilovemyfreedom",
            gateway: "No_Gateway",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "2023-02-15-2023-02-16",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjHgvLOCgw",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-23T02:44:28.865+0000",
            updated: "2023-02-23T02:44:33.606+0000",
            start: "2023-02-15T00:04:00.000+0000",
            end: "2023-02-17T06:00:00.000+0000",
            total: 562.05,
            channel: "ilovemyfreedom",
            gateway: "Shopify Payments",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "95081529513",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOj3gM3FCww",
            taxesMapped: true,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOj7nfykCAw",
            created: "2023-02-22T18:33:14.729+0000",
            updated: "2023-02-22T18:33:34.196+0000",
            start: "2023-02-14T18:05:06.000+0000",
            end: "2023-02-15T05:59:59.000+0000",
            total: 114.19,
            channel: "ilovemyfreedom",
            gateway: "paypal",
            currency: "USD",
            salesSource: "SHOPIFY",
            reportId: "2023-02-14-2023-02-15",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjbsajdCgw",
            taxesMapped: true,
            accountsMapped: true,
        },
    ] as ISettlement[],
};

export const settlementsDifferentCurrenies = {
    limit: 50,
    cursor: "CkMKEgoFc3RhcnQSCQjA9OCT39T4AhIpag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOitl8yvCwwYACAB",
    total: 14,
    data: [
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T15:06:25.303+0000",
            updated: "2023-02-03T12:51:24.566+0000",
            start: "2022-11-17T14:49:04.000+0000",
            end: "2022-12-01T14:49:04.000+0000",
            total: 82.64,
            channel: "Amazon.com",
            gateway: null,
            currency: "USD",
            salesSource: "AMAZON",
            reportId: "771801019327",
            postedToXero: "2023-02-03T13:35:10.023+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjN14LmCQw",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T15:07:26.279+0000",
            updated: "2023-02-03T12:51:30.951+0000",
            start: "2022-11-17T08:20:49.000+0000",
            end: "2022-12-01T08:20:49.000+0000",
            total: 8405.83,
            channel: "Amazon.ca",
            gateway: null,
            currency: "CAD",
            salesSource: "AMAZON",
            reportId: "771578019327",
            postedToXero: "2023-02-03T13:35:11.382+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjtuIPrCww",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T15:08:25.133+0000",
            updated: "2023-02-03T12:51:33.472+0000",
            start: "2022-11-16T16:24:25.000+0000",
            end: "2022-11-30T16:24:25.000+0000",
            total: 809.83,
            channel: "Amazon.com.mx",
            gateway: null,
            currency: "MXN",
            salesSource: "AMAZON",
            reportId: "771065019326",
            postedToXero: "2023-02-03T13:35:12.156+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjd3o_BCww",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T15:09:31.380+0000",
            updated: "2023-02-03T12:51:58.582+0000",
            start: "2022-11-08T19:29:00.000+0000",
            end: "2022-11-22T19:29:00.000+0000",
            total: 50598.4,
            channel: "Amazon.com",
            gateway: null,
            currency: "BBD",
            salesSource: "AMAZON",
            reportId: "765161019318",
            postedToXero: "2023-02-03T13:35:12.969+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOj9mYWfCAw",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T15:10:25.833+0000",
            updated: "2023-02-03T12:51:52.162+0000",
            start: "2022-11-03T14:49:04.000+0000",
            end: "2022-11-17T14:49:04.000+0000",
            total: 85.28,
            channel: "Amazon.com.br",
            gateway: null,
            currency: "BRL",
            salesSource: "AMAZON",
            reportId: "761886019313",
            postedToXero: "2023-02-03T13:35:13.769+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOit1NnQCAw",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T15:11:26.798+0000",
            updated: "2023-02-03T12:51:52.639+0000",
            start: "2022-11-03T08:20:49.000+0000",
            end: "2022-11-17T08:20:49.000+0000",
            total: 8445.88,
            channel: "Amazon.com",
            gateway: null,
            currency: "DKK",
            salesSource: "AMAZON",
            reportId: "761727019313",
            postedToXero: "2023-02-03T13:35:14.659+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOj9g4rBCQw",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T15:12:25.491+0000",
            updated: "2023-02-03T12:51:53.679+0000",
            start: "2022-11-02T16:24:25.000+0000",
            end: "2022-11-16T16:24:25.000+0000",
            total: 780.25,
            channel: "Amazon.com.hk",
            gateway: null,
            currency: "HKD",
            salesSource: "AMAZON",
            reportId: "761308019312",
            postedToXero: "2023-02-03T13:35:15.526+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjN96akCgw",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T15:15:29.586+0000",
            updated: "2023-02-03T12:52:12.670+0000",
            start: "2022-10-25T19:29:00.000+0000",
            end: "2022-11-08T19:29:00.000+0000",
            total: 40126.52,
            channel: "Amazon.com",
            gateway: null,
            currency: "IDR",
            salesSource: "AMAZON",
            reportId: "756731019304",
            postedToXero: "2023-02-03T13:35:16.734+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjNjqHPCAw",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T14:57:25.348+0000",
            updated: "2023-02-02T14:58:02.861+0000",
            start: "2022-10-06T08:20:49.000+0000",
            end: "2022-10-20T08:20:49.000+0000",
            total: 12608.47,
            channel: "Amazon.in",
            gateway: null,
            currency: "INR",
            salesSource: "AMAZON",
            reportId: "804993019375",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOid-ePpCgw",
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjdxaD9CAw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T14:45:24.485+0000",
            updated: "2023-02-02T14:45:58.761+0000",
            start: "2022-10-06T08:20:49.000+0000",
            end: "2022-10-20T08:20:49.000+0000",
            total: 12608.47,
            channel: "Amazon.com.ke",
            gateway: null,
            currency: "KES",
            salesSource: "AMAZON",
            reportId: "805022019375",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOid-ePpCgw",
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOit9srRCgw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T14:24:22.451+0000",
            updated: "2023-02-03T12:52:06.325+0000",
            start: "2022-10-06T08:20:49.000+0000",
            end: "2022-10-20T08:20:49.000+0000",
            total: 12608.47,
            channel: "Amazon.com.ko",
            gateway: null,
            currency: "KRW",
            salesSource: "AMAZON",
            reportId: "805077019375",
            postedToXero: "2023-02-03T13:35:17.449+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOid-ePpCgw",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T14:58:25.475+0000",
            updated: "2023-02-02T14:59:03.531+0000",
            start: "2022-10-06T08:20:49.000+0000",
            end: "2022-10-20T08:20:49.000+0000",
            total: 12608.47,
            channel: "Amazon.com",
            gateway: null,
            currency: "MYR",
            salesSource: "AMAZON",
            reportId: "804991019375",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOid-ePpCgw",
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOi90dKxCww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T14:16:20.513+0000",
            updated: "2023-02-03T12:52:08.793+0000",
            start: "2022-10-05T16:24:24.000+0000",
            end: "2022-10-19T16:24:25.000+0000",
            total: 381.21,
            channel: "Amazon.com.ng",
            gateway: null,
            currency: "NGN",
            salesSource: "AMAZON",
            reportId: "805103019375",
            postedToXero: "2023-02-03T13:35:18.246+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjtnd3pCgw",
            taxesMapped: false,
            accountsMapped: true,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgOjO4OaQCww",
            created: "2023-02-02T13:59:22.430+0000",
            updated: "2023-02-03T12:52:19.155+0000",
            start: "2022-09-27T19:29:00.000+0000",
            end: "2022-10-11T19:29:00.000+0000",
            total: 42953.48,
            channel: "Amazon.com.se",
            gateway: null,
            currency: "SEK",
            salesSource: "AMAZON",
            reportId: "805173019375",
            postedToXero: "2023-02-03T13:35:19.664+0000",
            cogsPosted: null,
            postingStatus: PostingStatus.POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: "ATSCEYZW6ISOL",
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: false,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjtuLLKCww",
            taxesMapped: false,
            accountsMapped: true,
        },
    ] as ISettlement[],
};
