import { baseUrlA2X } from "@/globals";
import { AccountingSystem, SalesSource } from "@/models";

/**
 * This is used only to generate an image path based on the provided accounting system or sales source.
 * Return value of this method should be passed to the getImportImage at the end to get the image.
 * @param system
 * @returns
 */
export function getStepperImageLogoPath(system: AccountingSystem | SalesSource): string {
    const prefix = "/images/logo/";
    const suffix = "-card";
    const type = ".svg";
    let path = "";
    if (
        (Object.values(AccountingSystem) as string[]).includes(system) &&
        system !== AccountingSystem.NONE
    ) {
        path = system.toLowerCase() + suffix;
    } else if ((Object.values(SalesSource) as string[]).includes(system.toUpperCase())) {
        path = system.toLowerCase();
    } else {
        path = "custom" + suffix;
    }

    return prefix + path + type;
}

/**
 * Main method for getting the image path inside any .vue related file.
 * @param imagePath
 * @returns
 */
export function getImportImage(imagePath: string): string {
    const path = `../assets${imagePath}`;

    const modules = import.meta.glob("../assets/images/**/*", { eager: true });

    const mod = modules[path] as { default: string };
    return mod ? mod.default : "";
}

/**
 * This is used only to generate an image path based on the provided accounting system or sales source.
 * Return value of this method should be passed to the getImportImage at the end to get the image.
 * @param system
 * @returns
 */
export function getSystemIconPath(system: AccountingSystem | SalesSource | string): string {
    const prefix = "/images/integration-icons/";

    let path = "";

    if ((system in AccountingSystem && system !== AccountingSystem.NONE) || system in SalesSource) {
        path = `${prefix}${system.toLowerCase()}.svg`;
    }

    if (typeof system === "string") {
        return `${prefix}${system.toLowerCase()}.svg`;
    }

    return path;
}

/**
 * This method uses getImportImage internally to get the image.
 * @param system
 * @returns
 */
export function getSalesChannelBadgeIcon(imageName: string, isCurrent = false): string {
    let path = "";

    path += "badge-";

    path += `${imageName.toLowerCase()}`;
    if (!isCurrent) {
        path += "-white";
    }

    return getImportImage(getSystemIconPath(path));
}

export function getA2XLogo(): string {
    return `${baseUrlA2X}/images/a2x-logo-white.png`;
}

export function getIconPath(icon: string): string {
    const staticIconPaths = {
        visa: "images/graphic-visa.png",
        mastercard: "images/graphic-mastercard.png",
        americanExpress: "images/graphic-american-express.png",
        ccardMastercard: "/images/icon-ccard-mastercard.svg", // used only for billing migration currently
        ccardVisa: "/images/icon-ccard-visa.svg", // used only for billing migration currently
        paypal: "/images/icon-paypal.svg", // used only for billing migration currently
    };

    switch (icon) {
        case "visa":
            return staticIconPaths.visa;
        case "matercard":
            return staticIconPaths.mastercard;
        case "americanExpress":
            return staticIconPaths.americanExpress;
        case "ccardVisa":
            return staticIconPaths.ccardVisa;
        case "ccardMastercard":
            return staticIconPaths.ccardMastercard;
        case "paypal":
            return staticIconPaths.paypal;
        default:
            break;
    }

    return "";
}
