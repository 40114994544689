<template>
    <b-nav class="atx-secondary-nav mb-4">
        <b-nav-item
            v-for="(navItem, index) in navItems"
            :key="index"
            @click="() => clickHandler(navItem)"
            class="border-bottom mb-4 border-5 pr-5"
            :class="{
                'border-atx-grey-2': !isActive(navItem) && !navItem.active,
                'border-atx-blue-2': isActive(navItem) || navItem.active,
            }"
            :to="navItem.to ? navItem.to : null"
            :href="navItem.href ? navItem.href : null"
        >
            <span
                :class="{
                    'text-atx-blue-2': isActive(navItem) || navItem.active,
                }"
                >{{ navItem.label }}</span
            >
        </b-nav-item>
    </b-nav>
</template>
<script lang="ts">
import Vue from "vue";

type NavItem = {
    label: string;
    to?: string; // Vue Router path
    href?: string; // Non-Vue Router path
    active?: boolean; // Optional to mark a item as active
    onClick?: (eventProperties?: Record<string, unknown> | undefined) => void;
};

export default Vue.extend({
    props: {
        navItems: {
            type: Array as () => NavItem[],
            required: true,
        },
    },
    methods: {
        clickHandler: function (navItem: NavItem) {
            if (navItem.onClick && this.isActive(navItem)) {
                navItem.onClick();
            }
        },
        // checks both props: to and href
        isActive(navItem: NavItem) {
            if (this.$route) {
                if (navItem.to) {
                    return this.$route.fullPath === navItem.to;
                } else if (navItem.href) {
                    return this.$route.path === navItem.href;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
    },
});
</script>
<style lang="scss" scoped>
.atx-secondary-nav {
    .nav-item {
        transition: all 0.5s ease-in-out;
        &:last-child {
            padding-right: 0 !important;
        }
        &:hover {
            border-bottom: 5px solid var(--atx-blue-2) !important;
            background-color: transparent !important;
        }
        .nav-link {
            padding-left: 0;
            padding-right: 0;
            color: var(--atx-grey-3);
            &:hover {
                color: var(--atx-blue-2) !important;
            }
        }
    }
}
</style>
