<template>
    <b-dropdown
        class="atx-dropdown-ellipsis"
        variant="link"
        toggle-tag="a"
        toggle-class="p-0 text-atx-blue-4"
        menu-class="p-0 border-atx-grey-2 border-radius-12 shadow-sm"
        no-caret
        right
    >
        <template #button-content>
            <atx-icon icon="three-dots" class="fa-fw" />
        </template>
        <slot></slot>
    </b-dropdown>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({});
</script>

<style lang="scss">
.atx-dropdown-ellipsis {
    .dropdown-menu {
        min-width: initial !important;
        border-color: rgba(red($atx-grey-2), green($atx-grey-2), blue($atx-grey-2), 0.5) !important;
    }

    .dropdown-item.active,
    .dropdown-item:active {
        background-color: var(--atx-grey-2) !important;
    }

    .dropdown-item {
        border-radius: 12px;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
}
</style>
