<template>
    <b-pagination
        @change="changeCurrentPage"
        pills
        :value="currentPageLocal"
        :total-rows="totalRows"
        :per-page="perPage"
        :align="align"
        size="sm"
        class="mb-3 atx-pagination"
    />
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        currentPage: {
            type: Number,
            default: 1,
        },
        totalRows: {
            type: Number,
            default: 0,
        },
        perPage: {
            type: Number,
            default: 10,
        },
        align: {
            type: String,
            default: "right",
        },
    },
    data() {
        return {
            currentPageLocal: this.currentPage,
        };
    },
    methods: {
        changeCurrentPage(eventValue: number) {
            this.currentPageLocal = eventValue;
            this.$emit("change", eventValue);
        },
    },
    watch: {
        // Reset current page when perPage changes
        perPage: {
            handler(newPerPage, oldPerPage) {
                if (oldPerPage && this.currentPageLocal) {
                    this.currentPageLocal = 1;
                    this.$emit("change", this.currentPageLocal);
                }
            },
            immediate: true, // Run the handler immediately when the component is created
        },
    },
});
</script>
<style>
/* TODO: move pagination scss here */
</style>
