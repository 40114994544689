import { IMenu } from "@/components/models/IMenu";
import { ACCOUNT_SETTINGS_PAGE_ROUTES } from "@/router/routeConstants";

export const appNavigation = {
    mainMenu: [
        {
            name: "Home",
            path: "/admin",
        },
        {
            name: "Settlements",
            path: "/settlements",
        },
        {
            name: "Accounts and Taxes",
            path: "/mapping",
        },
        // Amazon FBA menu
        {
            name: "Inventory",
            path: "#",
            subMenu: [
                {
                    name: "Monthly Summary",
                    path: "/inventory",
                },
                {
                    name: "Locations",
                    path: "/inventory-locations",
                },
            ],
        },
        {
            name: "Costs",
            path: "/cost-admin",
        },
        {
            name: "Need Help",
            path: "https://support.a2xaccounting.com/en/",
            classes: "d-md-none",
        },
    ],
    settingsMenu: [
        {
            name: "Account",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.ACCOUNT_SETTINGS.fullPath,
            icon: "gear",
        },
        {
            name: "Automation",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.AUTOMATION.fullPath}`,
            icon: "arrow-repeat",
        },
        {
            name: "Product Types",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.PRODUCT_TYPES.fullPath,
            icon: "boxes",
        },
        {
            name: "Sales Grouping and Tracking",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.INVOICES.fullPath,
            icon: "file-earmark-text",
        },
        {
            name: "NetSuite Configuration",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.NETSUITE_CONFIGURATION.fullPath}`,
            icon: "netsuite",
        },
        {
            name: "History",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.HISTORY.fullPath,
            icon: "clock-history",
        },
        {
            name: "Connections",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.CONNECTIONS.fullPath,
            icon: "plug",
        },
        {
            name: "Cost of Goods Sold",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.COGS.fullPath,
            icon: "currency-dollar",
        },
        {
            name: "Inventory",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.INVENTORY.fullPath,
            icon: "box-seam",
        },
        {
            name: "Users",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.USERS.fullPath,
            icon: "people",
        },
        {
            name: "Billing",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.BILLING.fullPath,
            icon: "credit-card-2-back",
        },
        {
            name: "Registered Country",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.REGISTERED_COUNTRY.fullPath,
            icon: "globe-americas",
        },
        {
            name: "Amazon Pay",
            path: ACCOUNT_SETTINGS_PAGE_ROUTES.AMAZON_PAY.fullPath,
            icon: "amazon-pay",
        },
        {
            name: "Beta Features",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.BETA_FEATURES.fullPath}`,
            icon: "rocket-takeoff",
            iconClasses: "text-atx-blue-3",
        },
        {
            name: "Backend App",
            path: `/app${ACCOUNT_SETTINGS_PAGE_ROUTES.BACKEND.fullPath}`,
            icon: "cpu",
        },
    ],
} as IMenu;

export const appNavigationForNetSuite = {
    mainMenu: [
        {
            name: "Home",
            path: "/admin",
        },
        {
            name: "Settlements",
            path: "/settlements",
        },
        {
            name: "Accounts and Taxes",
            path: "/mapping",
        },
        // Amazon FBA menu
        {
            name: "Inventory",
            path: "#",
            subMenu: [
                {
                    name: "Monthly Summary",
                    path: "/inventory",
                },
                {
                    name: "Locations",
                    path: "/inventory-locations",
                },
            ],
        },
        {
            name: "Costs",
            path: "/cost-admin",
        },
        {
            name: "Need Help",
            path: "https://support.a2xaccounting.com/en/",
            classes: "d-md-none",
        },
    ],
    settingsMenu: [
        {
            name: "Account",
            path: "/account-settings",
        },
        {
            name: "Automation",
            path: "/app/account-settings/automation",
        },
        {
            name: "Product Types",
            path: "/account-settings/product-types",
        },
        {
            name: "Sales Grouping and Tracking",
            path: "/account-settings/invoices",
        },
        {
            name: "History",
            path: "/account-settings/history",
        },
        {
            name: "Connections",
            path: "/account-settings/connections",
        },
        {
            name: "Cost of Goods Sold",
            path: "/account-settings/cogs",
        },
        {
            name: "Inventory",
            path: "/account-settings/inventory",
        },
        {
            name: "Users",
            path: "/account-settings/users",
        },
        {
            name: "Billing",
            path: "/account-settings/billing",
        },
    ],
} as IMenu;
