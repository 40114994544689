import { accountApi, featureFlagApi, oauthApi } from "@/api";
import { pinia } from "@/setup/setupPinia";
import { useAccountStore, useAuthStore, useLoaderStore } from "@/stores";

export async function setupAppDataAsync(): Promise<void> {
    // Instantiate the store and try to auth the user. If all good mount the app otherwise redirect to 404
    const authStore = useAuthStore(pinia);
    const accountStore = useAccountStore(pinia);
    const loaderStore = useLoaderStore(pinia);

    window.onblur = function () {
        /**
         * Download links will trigger in certain situation browser download bars to open and show the file which would focus out of the tab
         * However this is not a tab change, so we need to ignore this
         */
        const isDownloadLink =
            (document.activeElement?.tagName === "A" ||
                document.activeElement?.tagName === "BUTTON") &&
            document.activeElement?.classList.contains("download-link");
        /**
         * If the user has clicked iframe inside the app, don't trigger the tab change
         * This can happen for stripe input fields because they load iframed content
         */
        const isIframe = document.activeElement?.tagName === "IFRAME";
        if (isIframe || isDownloadLink) return;
        loaderStore.$patch((store) => {
            store.tabChanged = true;
        });
        console.log("tab changed");
    };

    authStore.$patch((store) => {
        store.prodEnvironment = import.meta.env.VITE_APP_NODE_ENV === "production";
    });

    let userStatus = false;

    if (!authStore.hasAdminPermissions) {
        const response = await oauthApi.getUserStatusAsync();

        if (response) {
            authStore.$patch((store) => {
                store.user = { ...store.user, ...response.user };
                store.expired = response.expired;
                store.hasAdminPermissions =
                    !response.expired &&
                    (response.user.rootAdmin ||
                        response.user.supportLead ||
                        response.user.supportAdmin);
            });
            authStore.setStorageAccountKey(response.user.currentAccount);
            userStatus = true;
        }
    }

    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    const urlParams = params;

    const account = urlParams.get("account");
    const accountKey = urlParams.get("accountKey");

    let accountParamValue = account;
    if (!account) {
        accountParamValue = accountKey;
    }
    const userIdParamValue = urlParams.get("userId");

    if (userStatus) {
        // accountKey is the derived account key. It may be overridden by URL query param
        let accountKey: string | null, userId: string | null;
        if (accountParamValue && accountParamValue !== "") {
            accountKey = accountParamValue;
        } else {
            accountKey = authStore.user.currentAccount;
        }

        if (userIdParamValue && userIdParamValue !== "") {
            userId = userIdParamValue;
        } else {
            userId = authStore.user.userId;
        }

        accountStore.$patch((store) => {
            store.accountKey = accountKey as string;
        });

        authStore.$patch((store) => {
            store.user.userId = userId as string;
        });

        // if we have the proper account key, fetch the needed data on which the navigation relies

        // Fetch account features
        if (accountKey !== null) {
            const accountSettings = accountApi.getSettingsAsync(accountKey);
            const accountFeatureFlags = featureFlagApi.getAsync(accountKey);

            const [settingsResponse, featureFlagResponse] = await Promise.all([
                accountSettings,
                accountFeatureFlags,
            ]);

            const accountDataMerge = {
                ...settingsResponse,
                enabledFeatureFlags: featureFlagResponse,
            };

            await accountStore.updateAccountStoreDataAsync(
                accountDataMerge,
                authStore.user.currentAccount !== accountKey
            );
        }
    }
    loaderStore.setInitialDataLoaded();
}
