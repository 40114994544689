
<b-container id="navigation" :fluid="headerContainerFluid" class="py-1">
    <b-row>
        <b-col>
            <b-navbar class="pl-0 pr-0" toggleable="lg">
                <b-button
                    v-if="sidebarNavigationFeatureActive"
                    v-b-toggle.a2x-sidebar-nav
                    class="d-flex btn-sidebar-nav-toggle d-xxl-none mr-4 border-radius-4 p-0 bg-transparent border-0"
                    ><div class="position-relative">
                        <atx-icon
                            icon="layout-sidebar"
                            style="width: 24px; height: 24px"
                        /><atx-icon
                            icon="arrow-right-short"
                            class="position-absolute arrow-absolute"
                            style="width: 19px; height: 19px"
                        /></div
                ></b-button>
                <b-navbar-brand v-if="showLogo" href="/">
                    <img
                        :src="getA2XLogo()"
                        class="d-inline-block"
                        alt="A2X Logo"
                        width="80"
                        height="22"
                    />
                </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse" class="order-3"></b-navbar-toggle>

                <b-navbar
                    class="p-0 ml-auto justify-content-end align-self-end order-lg-1 nav-secondary"
                >
                    <b-navbar-nav align="right" class="align-items-center">
                        <b-nav-item
                            :href="WEBSITE_LINKS.WHATS_NEW.path"
                            class="d-none d-md-block"
                            ><span class="label-new bg-gradient-orange"
                                ><atx-icon icon="gift" /> NEW</span
                            ></b-nav-item
                        >
                        <b-nav-item
                            :to="USER_SETTINGS_PAGE_ROUTES.NOTIFICATIONS.path"
                            class="d-none d-md-block"
                        >
                            <b-icon icon="bell-fill" class="rounded"></b-icon>
                        </b-nav-item>
                        <b-nav-item
                            :href="SUPPORT_LINKS.SUPPORT.path"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="d-none d-md-block"
                        >
                            <b-icon
                                font-scale="1"
                                icon="question-circle-fill"
                                aria-label="Help"
                                class="mr-1"
                            ></b-icon>
                        </b-nav-item>
                        <b-nav-item
                            :href="PARTNER_PAGE_ROUTES.PARTNER.path"
                            class="d-none d-md-block"
                        >
                            Partner overview
                        </b-nav-item>
                        <b-nav-item
                            :href="PARTNER_PAGE_ROUTES.TEAMS.path"
                            class="d-none d-md-block"
                            >Teams</b-nav-item
                        >

                        <atx-navbar-user-dropdown
                            :user-email="userEmail"
                            :enabling-party-icon="enablingPartyIcon"
                            :stripe-enabled="stripeEnabled"
                        ></atx-navbar-user-dropdown>
                    </b-navbar-nav>
                </b-navbar>
            </b-navbar>
        </b-col>
    </b-row>
</b-container>
