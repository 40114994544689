export function trackingConfig(
    params: Record<string, any>,
    accountCentricParams: Record<string, any>,
    accountAndUserCentricParams: Record<string, any>
): Record<string, any> {
    return {
        config: {
            // id: "G-GHWZ7Q0FX7", // Testing EU - Use this account for DebugView testing
            id: "G-53CRS972NH", // Behavioral Analytics - App
            params: params,
        },
        includes: [
            {
                id: "G-8471RTL2LY", // Combined Website.+ App
                params: params,
            },
            {
                id: "G-NN36GPGXCF", // App Error Tracking
                params: params,
            },
            {
                id: "G-XFB4JDEQ3G", // Behavioral Analytics - App ( Account Key )
                params: accountCentricParams,
            },
            {
                id: "G-LDC3B8S4S3", // Behavioral Analytics - App ( Account Key + User ID)
                params: accountAndUserCentricParams,
            },
        ],
    };
}
