
<b-form-group
    :id="`${id}-form-group`"
    class="mb-0"
    :label="label"
    label-class="font-weight-bold"
    :label-for="`${id}-from`"
>
    <b-input-group>
        <b-form-text class="align-self-center pr-2">From</b-form-text>
        <b-form-input
            :id="`${id}-from`"
            :aria-label="`From ${fromValue ? fromValue : ''}`"
            :value="fromValueComputed"
            type="number"
            @input="fromChanged($event)"
        ></b-form-input>
        <b-form-text class="p-2 align-self-center">up to</b-form-text>
        <b-form-input
            :id="`${id}-to`"
            :aria-label="`To ${toValue ? toValue : ''}`"
            :value="toValueComputed"
            type="number"
            @input="toChanged($event)"
        ></b-form-input>
    </b-input-group>
</b-form-group>
