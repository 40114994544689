<template>
    <b-container v-if="heading !== '' || $slots.default" class="atx-page-header my-6">
        <template v-if="breadcrumbs && breadcrumbs.length > 0">
            <atx-breadcrumb v-if="loaded" :items="breadcrumbs"></atx-breadcrumb>
            <b-skeleton width="350px" height="24px" class="mb-6" v-else />
        </template>
        <atx-heading
            :loaded="loaded"
            :heading="heading"
            :isBetaFeature="isBetaFeature"
            :description="description"
            :sales-channel="salesChannel"
            :route="route"
            ><template v-for="(_, headingSlot) of $slots" v-slot:[headingSlot]="scope">
                <slot :name="headingSlot" v-bind="scope" /></template
        ></atx-heading>
    </b-container>
</template>

<script lang="ts">
import Vue from "vue";

/**
 * This would be a default header component for our pages.
 * Atx Page Header can be used as a stand-alone component. Make sure you provide it heading and description props.
 * Thi component is used in LayoutDefault.vue for all the pages. In case you want different header for specific page
 * you can override it by adding a meta property to the route called pageHeaderOverride and set it to true.
 * Even though AtxHeading and AtxPageHeader are very similar, they are not the same. AtxHeading is used for titles specifically.
 * Header can further expanded to contain breadcrumbs and other things necessary for generic display on the page.
 */
export default Vue.extend({
    props: {
        heading: {
            type: String,
            default: "",
        },
        isBetaFeature: {
            type: Boolean,
            default: false,
        },
        description: {
            type: String,
            default: "",
        },
        salesChannel: {
            type: String,
            default: "",
        },
        loaded: {
            type: Boolean,
            default: false,
        },
        breadcrumbs: {
            type: Array,
            default: () => [],
        },
        route: {
            type: Object,
            default: () => ({}),
        },
    },
});
</script>

<style scoped></style>
