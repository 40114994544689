<template>
    <b-button-group class="position-static" :data-test-id="testId">
        <b-dropdown
            ref="atx-dropdown-select"
            variant="light"
            class="atx-dropdown mx-1"
            toggle-class="border-radius-4 px-2 py-1"
            menu-class="border-radius-12"
            :disabled="dropdownDisabled"
            boundary="window"
            no-caret
            @show="openFilterSelect"
            @hide="closeFilterSelect"
        >
            <template #button-content>
                <span class="dropdown-toggle-button-text">{{ dropdownButtonText }}</span>
                <atx-filter-counter-badge v-if="badgeCounter > 0" :counter="badgeCounter" />
                <atx-icon :icon="filterSelectShouldOpen ? 'chevron-up' : 'chevron-down'" />
            </template>

            <div class="text-center my-2" v-if="isLoading">
                <b-spinner class="align-middle"></b-spinner>
            </div>
            <atx-select
                v-else
                class="px-2 atx-select"
                v-on="$listeners"
                v-bind="$attrs"
                transition=""
                :dropdown-should-open="() => filterSelectShouldOpen"
                :close-on-select="false"
                deselectFromDropdown
                searchable
                :reduce="enableTooltips ? (options) => options.label : undefined"
            >
                <template v-slot:option="option">
                    <atx-icon class="mr-1" :icon="getSelectedIcon(option)" />
                    {{ getOptionLabel(option) }}
                    <atx-icon
                        v-if="enableTooltips && getOptionsTooltip(option)"
                        icon="info-circle"
                        class="ml-2"
                        v-b-popover.hover="{
                            content: getOptionsTooltip(option),
                            boundary: 'viewport', // this allows proper positioning of the popover, due to constrained space
                        }"
                    />
                </template>
                <template #footer>
                    <atx-button-link class="ml-3" @click="clearInputs">Clear</atx-button-link>
                </template>
            </atx-select>
        </b-dropdown>
    </b-button-group>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        isDragged: {
            type: Boolean,
            required: false,
            default: false,
        },
        dropdownButtonText: {
            type: String,
            required: false,
            default: "Button text",
        },
        dropdownDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        alterSelectLabels: {
            type: Function,
            required: false,
            default: null,
        },
        isLoading: {
            type: Boolean,
            default: false,
            required: false,
        },
        badgeCounter: {
            type: Number,
            required: false,
            default: 0,
        },
        testId: {
            type: String,
            required: false,
            default: "",
        },
        enableTooltips: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            filterSelectShouldOpen: false,
            lockFilterSelect: true,
        };
    },
    methods: {
        closeFilterSelect() {
            this.filterSelectShouldOpen = false;
        },
        openFilterSelect(e: Event) {
            if (this.isDragged) {
                e.preventDefault();
                return;
            }
            this.filterSelectShouldOpen = true;
            this.$emit("dropdown-shown");
        },
        clearInputs() {
            // The $emit 'input' is by design so that the values are cleared properly
            this.$emit("input", null);
            this.$emit("clear");
        },
        getSelectedIcon(option: Record<string, string>): string {
            const isMultipleSelections =
                "multiple" in this.$attrs && this.$attrs["multiple"] === "";
            const missingValues = !this.$attrs || !option;
            const isSingleValue = typeof this.$attrs["value"] === "string";
            const isValueSelected = this.$attrs["value"]
                ? isSingleValue
                    ? this.$attrs["value"] === option.label
                    : this.$attrs["value"].includes(option.label)
                : false;

            const notSelectedIcon = isMultipleSelections ? "square" : "circle";
            const selectedIcon = isMultipleSelections ? "check-square" : "check-circle";

            if (missingValues) {
                return notSelectedIcon;
            }
            if (isValueSelected) {
                return selectedIcon;
            } else {
                return notSelectedIcon;
            }
        },
        getOptionLabel(option: Record<string, string>): string {
            let label = option.label;
            if (this.alterSelectLabels) {
                label = this.alterSelectLabels(option.label);
            }

            if ("label" in this.$attrs) {
                label = option[this.$attrs.label];
            }
            return label;
        },
        getOptionsTooltip(option: Record<string, string>): string {
            return option.tooltip;
        },
    },
});
</script>

<style scoped></style>
