import { extend, setInteractionMode, configure, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en.json";

export function setupVeeValidate(): void {
    localize("en", en);
    configure({
        classes: {
            valid: "is-valid",
            invalid: "is-invalid",
        },
    });
    setInteractionMode("custom", () => {
        return {
            on: ["input"],
        };
    });

    // install vee-validate rules
    Object.keys(rules).forEach((rule) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        extend(rule, (rules as any)[rule]);
    });
}
