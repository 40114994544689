<template>
    <router-view></router-view>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    computed: {
        isProductionEnv() {
            return import.meta.env.VITE_APP_NODE_ENV === "production";
        },
    },
    errorCaptured(err: Error, vm, info) {
        if (!this.isProductionEnv) return;
        // handle error
        // `info` is a Vue-specific error info, e.g. which lifecycle hook
        // the error was found in. Only available in 2.2.0+
        vm.$root.$gtag.query("event", "js_error", {
            js_error: err,
            js_stack: err.stack,
            js_info: info,
        });
    },
    created() {
        console.log("PublicApp created");
    },
});
</script>
