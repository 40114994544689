<template>
    <hr :class="'my-' + margin" />
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        margin: {
            type: Number,
            default: 2,
        },
    },
});
</script>
