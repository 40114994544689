import { defineStore } from "pinia";

export const useLoaderStore = defineStore({
    id: "loader",
    state: () => ({
        loading: false,
        initialDataLoaded: false,
        accountDataSwitching: false,
        pageLoaded: false,
        tabChanged: false,
        accountListLoaded: false,
    }),
    actions: {
        toggleLoading() {
            this.loading = !this.loading;
        },
        setLoadingStarted() {
            this.loading = true;
        },
        setLoadingCompleted() {
            this.loading = false;
        },
        setInitialDataLoaded() {
            this.initialDataLoaded = true;
        },
        setInitialDataLoading() {
            this.initialDataLoaded = false;
        },
        setAccountListLoaded() {
            this.accountListLoaded = true;
        },
        resetTabChanged() {
            this.tabChanged = false;
        },
        setAccountDataSwitching() {
            this.accountDataSwitching = true;
        },
        setAccountDataSwitched() {
            this.accountDataSwitching = false;
        },
        // eslint-disable-next-line require-await
        async awaitInitialDataLoadedAsync() {
            return new Promise((resolve) => {
                if (this.initialDataLoaded) {
                    resolve(resolve);
                } else {
                    const interval = setInterval(() => {
                        if (this.initialDataLoaded) {
                            clearInterval(interval);
                            resolve(resolve);
                        }
                    }, 100);
                }
            });
        },
        // eslint-disable-next-line require-await
        async awaitAccountListLoadedAsync() {
            return new Promise((resolve) => {
                if (this.accountListLoaded) {
                    resolve(resolve);
                } else {
                    const interval = setInterval(() => {
                        if (this.accountListLoaded) {
                            clearInterval(interval);
                            resolve(resolve);
                        }
                    }, 100);
                }
            });
        },
    },
});
