
<ul class="pagination">
    <li v-if="limitOptions" class="pagination-limit">
        <span class="pagination-limit-label">Rows per page</span>
        <select class="form-control pagination-limit-select" v-model="theLimit">
            <option
                v-for="option in limitOptions"
                :value="option"
                :key="option"
                :selected="option === theLimit"
            >
                {{ option }}
            </option>
        </select>
    </li>
    <li class="pagination-previous">
        <a v-if="currentPage == 0" class="nav-button-disabled">
            <b-icon icon="chevron-left" class="nav-button-disabled" font-scale="2"></b-icon>
        </a>
        <a v-if="currentPage != 0" class="nav-button" type="button" @click="previousPage">
            <b-icon icon="chevron-left" class="nav-button" font-scale="2"></b-icon>
        </a>
    </li>
    <li class="pagination-page-number">{{ currentPage + 1 }} of many</li>
    <li class="pagination-next">
        <a v-if="isAtLastPage" class="nav-button-disabled">
            <b-icon icon="chevron-right" class="nav-button-disabled" font-scale="2"></b-icon>
        </a>
        <a v-if="!isAtLastPage" class="nav-button" type="button" @click="nextPage">
            <b-icon icon="chevron-right" class="nav-button" font-scale="2"></b-icon>
        </a>
    </li>
</ul>
