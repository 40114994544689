import { AtxError, catchErrorHandler } from "@/helpers";
import { IAccountEntity } from "@/models";
import { a2xApi } from "@/services/api.service";
import { AxiosResponse } from "axios";

export type SwitchAccountRequest = {
    destinationAccountKey: string;
};

export type SwitchAccountResponse = {
    redirectUrl: string;
} & AtxError &
    IAccountEntity;

export const switchApi = {
    async switchAccountAsync(request: SwitchAccountRequest): Promise<SwitchAccountResponse> {
        const url = `/switch/account`;
        try {
            const response: AxiosResponse = await a2xApi.post(url, request);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
};
