<template>
    <b-row
        class="atx-heading align-items-center"
        v-if="heading !== '' || Object.keys($scopedSlots).length > 0"
    >
        <b-col>
            <h1
                v-if="loaded"
                :class="{
                    h4: true,
                    'mb-0': description === '',
                    'mb-3': description !== '',
                    headingClass,
                    'd-flex align-items-center': centered,
                }"
            >
                <template v-if="$scopedSlots.default">
                    <slot />
                </template>
                <template v-else>
                    {{ pageHeading }}
                    <atx-badge class="ml-2" :preset="AtxBadgePresets.BETA" v-if="isBetaFeature" />
                </template>
            </h1>
            <b-skeleton
                v-else
                width="250px"
                height="32px"
                :class="{
                    'mb-0': description === '',
                    'mb-3': description !== '',
                }"
            />
            <template v-if="description !== ''">
                <p v-if="loaded">{{ description }}</p>
                <b-skeleton v-else width="400px" height="16px" class="mb-2" />
            </template>
        </b-col>

        <template v-if="$scopedSlots['heading-right-col']">
            <b-col cols="auto"><slot name="heading-right-col"></slot></b-col
        ></template>
    </b-row>
</template>

<script lang="ts">
import { AtxBadgePresets } from "@/components/feedback/AtxBadge/AtxBadge";
import { capitalize } from "@/helpers";
import { MAPPING_PAGE_ROUTES } from "@/router/routeConstants";
import Vue from "vue";

/**
 * This would be a default heading component for our page header component.
 * Atx heading can be used as a stand-alone component. Make sure you provide it heading and description props.
 * For instance you can use it on the tabbed content for title and not just page title.
 */
export default Vue.extend({
    data() {
        return {
            AtxBadgePresets,
        };
    },
    props: {
        loaded: {
            type: Boolean,
            default: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        heading: {
            type: String,
            default: "",
        },
        isBetaFeature: {
            type: Boolean,
            default: false,
        },
        headingClass: {
            type: String,
            default: "",
        },
        description: {
            type: String,
            default: "",
        },
        salesChannel: {
            type: String,
            default: "",
        },
        route: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        pageHeading(): string {
            let pageHeading = "";
            switch (this.route.name) {
                case MAPPING_PAGE_ROUTES.APP_MAPPINGS.name:
                    pageHeading = capitalize(this.salesChannel) + " " + this.heading;
                    break;
                default:
                    pageHeading = this.heading;
            }

            return pageHeading;
        },
    },
});
</script>

<style scoped>
.atx-heading h1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
