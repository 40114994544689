import { ISettlement, PostingStatus } from "@/models/settlement.models";

export const shopifySettlementsTableData = {
    limit: 20,
    cursor: "CkMKEgoFc3RhcnQSCQjA2LCbtp_4AhIpag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgKjL9p_GCgwYACAB",
    total: 20,
    data: [
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:12:52.434+0000",
            updated: "2023-02-16T09:13:01.702+0000",
            start: "2023-02-04T18:32:49.000+0000",
            end: "2023-02-09T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "83737280563",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOiz3_fwCww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:10:14.639+0000",
            updated: "2023-02-16T09:10:19.527+0000",
            start: "2023-01-31T11:00:00.000+0000",
            end: "2023-02-01T10:59:59.000+0000",
            total: 0.0,
            channel: "mumi-bubi",
            gateway: "No_Gateway",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "2023-01-31-2023-02-01",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: true,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjblMW8Cww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:13:57.500+0000",
            updated: "2023-02-16T09:13:59.278+0000",
            start: "2023-01-25T00:39:17.000+0000",
            end: "2023-01-29T11:00:00.000+0000",
            total: 42.15,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "83565412403",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjr6L3aCAw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:10:15.980+0000",
            updated: "2023-02-16T09:10:26.760+0000",
            start: "2023-01-24T19:58:27.000+0000",
            end: "2023-01-26T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "83543195699",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjbzq2FCQw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:15:49.145+0000",
            updated: "2023-02-16T09:15:50.952+0000",
            start: "2023-01-16T23:08:14.000+0000",
            end: "2023-01-18T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "83428606003",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjb86WtCQw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:16:09.680+0000",
            updated: "2023-02-16T09:16:11.509+0000",
            start: "2023-01-10T11:24:26.000+0000",
            end: "2023-01-12T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "83353010227",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOibl8SGCww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:16:18.993+0000",
            updated: "2023-02-16T09:16:20.825+0000",
            start: "2023-01-03T00:22:20.000+0000",
            end: "2023-01-08T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "83277905971",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOi7wITbCgw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:21:40.270+0000",
            updated: "2023-02-16T09:21:49.875+0000",
            start: "2022-12-31T11:00:00.000+0000",
            end: "2023-01-31T10:59:59.000+0000",
            total: 93.98,
            channel: "mumi-bubi",
            gateway: "paypal",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "2022-12-31-2023-01-31",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOib9PC7Cgw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:17:06.516+0000",
            updated: "2023-02-16T09:17:13.594+0000",
            start: "2022-12-30T00:36:55.000+0000",
            end: "2023-01-05T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "83252641843",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOj7r8SfCww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:19:49.485+0000",
            updated: "2023-02-16T09:19:58.796+0000",
            start: "2022-12-08T03:48:58.000+0000",
            end: "2022-12-12T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "82908119091",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjb3ojKCww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:20:41.931+0000",
            updated: "2023-02-16T09:20:49.774+0000",
            start: "2022-11-24T07:49:21.000+0000",
            end: "2022-11-28T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "82659344435",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOi7nvSLCgw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:10:22.735+0000",
            updated: "2023-02-16T09:10:30.579+0000",
            start: "2022-11-22T19:55:27.000+0000",
            end: "2022-11-24T11:00:00.000+0000",
            total: 61.96,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "82609045555",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOi77ZeXCgw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:22:57.573+0000",
            updated: "2023-02-16T09:23:11.388+0000",
            start: "2022-11-03T02:04:15.000+0000",
            end: "2022-11-07T11:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "82296864819",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjz-9fECww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:03:31.939+0000",
            updated: "2023-02-16T09:03:38.279+0000",
            start: "2022-10-28T01:51:09.000+0000",
            end: "2022-11-01T11:00:00.000+0000",
            total: 61.96,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "82139840563",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOib_cjZCww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2023-02-16T09:04:04.259+0000",
            updated: "2023-02-16T09:04:10.606+0000",
            start: "2022-10-22T09:53:00.000+0000",
            end: "2022-10-27T11:00:00.000+0000",
            total: 45.05,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "82024300595",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjrgu-DCww",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2022-10-21T12:33:22.389+0000",
            updated: "2022-10-21T12:33:34.742+0000",
            start: "2022-09-25T03:49:25.000+0000",
            end: "2022-09-28T11:00:00.000+0000",
            total: 61.96,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "81287249971",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjQ65jqCQw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2022-10-20T11:09:23.259+0000",
            updated: "2022-10-20T11:09:28.122+0000",
            start: "2022-09-12T02:20:33.000+0000",
            end: "2022-09-14T12:00:00.000+0000",
            total: 42.5,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "80937418803",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgOjQ5YTMCAw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2022-10-20T11:09:27.403+0000",
            updated: "2022-10-20T11:09:33.749+0000",
            start: "2022-08-22T19:59:06.000+0000",
            end: "2022-08-24T12:00:00.000+0000",
            total: 61.96,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "80426074163",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgKjzzMKtCQw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2022-10-20T11:09:38.433+0000",
            updated: "2022-10-20T11:09:41.810+0000",
            start: "2022-06-30T12:00:00.000+0000",
            end: "2022-07-31T11:59:59.000+0000",
            total: 180.97,
            channel: "mumi-bubi",
            gateway: "paypal",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "2022-06-30-2022-07-31",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgKjL9fvWCQw",
            taxesMapped: null,
            accountsMapped: null,
        },
        {
            accountKey: "ag5zfmFtYXpvbnRveGVyb3IUCxIHQWNjb3VudBiAgKizoqiLCQw",
            created: "2022-10-20T11:09:36.033+0000",
            updated: "2022-10-20T11:09:39.355+0000",
            start: "2022-06-09T03:26:33.000+0000",
            end: "2022-06-13T12:00:00.000+0000",
            total: 61.96,
            channel: "mumi-bubi",
            gateway: "Shopify Payments",
            currency: "NZD",
            salesSource: "SHOPIFY",
            reportId: "78659289139",
            postedToXero: null,
            cogsPosted: null,
            postingStatus: PostingStatus.NOT_POSTED,
            cogsPostingStatus: PostingStatus.NOT_POSTED,
            cogsInvoicesTotal: null,
            cogsCurrency: null,
            allProductCostsAvailable: false,
            sellerId: null,
            duplicateOf: null,
            inconsistent: null,
            flagsSet: [],
            onHold: null,
            onHoldReason: null,
            onHoldDate: null,
            keyAsString: "ag5zfmFtYXpvbnRveGVyb3IXCxIKU2V0dGxlbWVudBiAgKjL9p_GCgw",
            taxesMapped: null,
            accountsMapped: null,
        },
    ] as ISettlement[],
};
