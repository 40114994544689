<template>
    <b-breadcrumb :items="items"></b-breadcrumb>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        items: {
            type: Array,
            default: () => [] as string[],
            required: true,
        },
    },
});
</script>

<style scoped>
.breadcrumb {
    background-color: transparent;
    padding-left: 0;
}
</style>
