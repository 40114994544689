import { catchErrorHandler } from "@/helpers";
import {
    GetAccountOverviewResponse,
    IAccountEntity,
    IAccountEntityBackend,
    IAccountEntityWrapper,
    IAccountListItems,
    ICopyAccCredsToStagingEntity,
} from "@/models";
import { a2x2Api, a2xApi, a2xBaseApi } from "@/services/api.service";
import axios, { AxiosResponse } from "axios";

export const accountApi = {
    async getAsync(accountKey: string): Promise<IAccountEntityBackend> {
        const url = `account?accountKey=${accountKey}&backend=true`;

        const response: AxiosResponse = await a2xApi.get(url);
        return response.data;
    },
    async getCurrentAccount(userId: string): Promise<{ currentAccount: string }> {
        const url = `current-account?userId=${userId}`;
        try {
            const response: AxiosResponse = await a2xApi.get(url);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
    async getListAsync(userId: string): Promise<IAccountListItems> {
        const url = `account-list?userId=${userId}`;
        const response: AxiosResponse = await a2xApi.get(url);
        return response.data;
    },
    /**
     * This is specifically for backend pages
     * 
        // {
        //     "salesSource": "SHOPIFY",
        //     "salesSourceDisplay": "Shopify: Not Connected",
        //     "premium": true,
        //     "displayName": "jj",
        //     "cogsEnabled": true,
        //     "planName": "A2X Multi 5K",
        //     "xeroMigratedToA2x2": true,
        //     "accountingIntegrationDisplayName": "Xero",
        //     "planCode": "MULTICH_5K_202010_COGS",
        //     "accountKey": "aglhMngtbG9jYWxyFAsSB0FjY291bnQYgICAgICAgAoM",
        //     "status": "PREMIUM",
        //     "timeZoneId": "Pacific/Auckland"
        // }
     */
    async getBackendListAsync(limit: number, cursor?: string): Promise<IAccountEntityWrapper> {
        let url = `backend-account-list?limit=${limit}`;
        if (cursor) {
            url += `&cursor=${cursor}`;
        }
        const response: AxiosResponse = await a2xApi.get(url);
        return response.data;
    },
    async getSettingsAsync(accountKey: string): Promise<IAccountEntity> {
        const url = `account?accountKey=${accountKey}&settings=true`;

        const response: AxiosResponse = await a2xApi.get(url);
        return response.data;
    },
    async getMappingsCopyAccountsAsync(accountKey: string): Promise<IAccountEntity> {
        const url = `account?accountKey=${accountKey}&settings=true&mappings=true`;

        const response: AxiosResponse = await a2xApi.get(url);
        return response.data;
    },
    async getForceUnblockAsync(): Promise<any> {
        const url = `/refererUnblock`;

        const response: AxiosResponse = await axios.get(url);
        return response.data;
    },
    async postCopyCredentialsToStagingAsync(
        accountKey: string
    ): Promise<ICopyAccCredsToStagingEntity> {
        const url = `account/copy-account-creds-to-staging`;

        const requestBody = {
            accountKeyCopyFrom: accountKey,
        };

        const response: AxiosResponse = await a2xApi.post(url, requestBody);
        return response.data;
    },

    async getOverview(accountKey: string): Promise<GetAccountOverviewResponse> {
        const url = `public/account/overview?accountKey=${accountKey}`;

        try {
            const response: AxiosResponse = await a2x2Api.get(url);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },

    async deleteOverviewCache(accountKey: string): Promise<boolean> {
        const url = `public/account/overview/cache?accountKey=${accountKey}`;
        try {
            const response: AxiosResponse = await a2x2Api.delete(url);
            return response.data;
        } catch (e) {
            return <never>{};
        }
    },

    // Switch url from the navigation
    // That is why it is not setup like the other api calls
    async switchAccount(accountKey: string): Promise<unknown> {
        const url = `/switch?account=${accountKey}`;
        try {
            const response = axios.get(url);
            return response;
        } catch (e) {
            return <never>{};
        }
    },

    // Switch url from the navigation
    // That is why it is not setup like the other api calls
    async switchAccount2(accountKey: string): Promise<unknown> {
        const url = `/switch?account=${accountKey}`;
        try {
            const response = axios.get(url);
            return response;
        } catch (e) {
            return <never>{};
        }
    },

    // hit POST ENDPOINT under /account-settings to save accountName
    async postAccountSettingsAsync(accountName: string): Promise<any> {
        const url = `/account-settings?accountName=${accountName}&caller=admin`;

        try {
            const response: AxiosResponse = await a2xBaseApi.post(
                url,
                {},
                {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                }
            );
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },

    async putAccountingSystemReferenceNameAsync(
        payload: Record<string, string>,
        accountKey: string
    ): Promise<any> {
        const url = `/account?action=updateAccountingSystemReferenceName&accountKey=${accountKey}`;

        try {
            const response: AxiosResponse = await a2xApi.put(url, payload);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
};
