
<b-table
    :id="id"
    :small="small"
    :class="tableClass"
    :fields="fields"
    :items="settlementsEmptyTableData.data"
>
    <template #head(date)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    <template #cell(date)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    <template #head(total)><b-skeleton variant="atx-orange-1" class="m-2" /></template>
    <template #cell(total)><b-skeleton variant="atx-orange-1" class="m-2" /></template>
    <template #head(cogsInvoicesTotal)
        ><b-skeleton variant="atx-green-1" class="m-2"
    /></template>
    <template #cell(cogsInvoicesTotal)
        ><b-skeleton variant="atx-green-1" class="m-2"
    /></template>
    <template #head(channel)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    <template #cell(channel)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    <template #head(gateway)><b-skeleton variant="atx-yellow-1" class="m-2" /></template>
    <template #cell(gateway)><b-skeleton variant="atx-yellow-1" class="m-2" /></template>
    <template #head(status)><b-skeleton variant="atx-green-1" class="m-2" /></template>
    <template #cell(status)><b-skeleton variant="atx-green-1" class="m-2" /></template>
    <template #head(actions)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    <template #cell(actions)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
</b-table>
