import { differenceInDays } from "date-fns";
import { utcToZonedTime, format, getTimezoneOffset } from "date-fns-tz";

export function unixToDate(val: number, custom = false): string {
    const milliseconds = val;
    const dateObj = new Date(milliseconds);
    const humanDateForm = dateObj.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
    });
    if (custom) {
        return dateObj.getFullYear().toString() + "/" + (dateObj.getMonth() + 1).toString();
    }
    return humanDateForm;
}

export function unixToISO(val: number): string {
    return new Date(val).toISOString();
}

/**
 * returns date like "2024-05-28"
 */
export function unixToISODateString(val: number): string {
    return new Date(val).toISOString().split("T")[0];
}

export function getNumOfDaysBetweenDatesInclusive(date1: Date, date2: Date): number {
    const diffDays = Math.abs(differenceInDays(date1, date2));
    return diffDays + 1;
}

export function dateFormat(timestamp: number, dateFormat: string, tzString: string): string {
    const d = new Date(timestamp);
    const zonedDate = utcToZonedTime(d, tzString);
    return format(zonedDate, dateFormat);
}
export function dateFormatLocal(timestamp: number, dateFormat: string): string {
    const d = new Date(timestamp);
    return format(d, dateFormat);
}

/** Format a date string to a given date format and timezone
 * Timezone defaults to UTC if not specified
 */
export function dateStringFormat(date: string, dateFormat: string, tzString?: string): string {
    const dateObj = new Date(date);

    if (!tzString) {
        tzString = "UTC";
    }

    const zonedDate = utcToZonedTime(dateObj, tzString);
    return format(zonedDate, dateFormat);
}

/**
 * Transforms date with timezone and converts to UTC string representation
 * @param date
 * for example: 31 December 2023, 07:00 PM EST -> "2024-01-01"
 */
export function formatDateToUtcStringDate(date: Date): string {
    const utcDateInUtcTimezone = utcToZonedTime(date, "UTC");
    return format(utcDateInUtcTimezone, "yyyy-MM-dd");
}

/**
 * Format a date string using local timezone
 */
export function dateStringFormatLocal(date: string, dateFormat: string): string {
    const dateObj = new Date(date);
    return format(dateObj, dateFormat);
}

export function formatDate(
    date: Date,
    monthFormat: "short" | "long" = "short",
    dayFormat: "numeric" | "2-digit" = "numeric",
    yearFormat: "numeric" | "2-digit" | undefined = undefined
): string {
    return date.toLocaleString("en-US", {
        month: monthFormat,
        day: dayFormat,
        year: yearFormat,
    });
}

export function formatFullDate(date: Date): string {
    return formatDate(date, "long", "numeric", "numeric");
}

export function formatShortDate(date: Date): string {
    return formatDate(date, "short", "numeric", "numeric");
}

export const a2xDisplayDateFormat = "MMM dd yyyy";

export function getTimezoneOffsetHoursAndMinutes(IANATimezone: string, date: Date): string {
    const timezoneOffset = getTimezoneOffset(IANATimezone, date);

    const offsetHours = Math.abs(Math.floor(timezoneOffset / 3600000));
    const offsetMinutes = Math.abs(timezoneOffset % 60000);
    const offsetSign = timezoneOffset < 0 ? "-" : "+";

    return `${offsetSign}${offsetHours.toString().padStart(2, "0")}:${offsetMinutes
        .toString()
        .padStart(2, "0")}`;
}

export function dateFormatEpochSecond(
    epochSeconds: number,
    dateFormat: string,
    tzString: string
): string {
    const d = new Date(epochSeconds * 1000);
    const zonedDate = utcToZonedTime(d, tzString);
    return format(zonedDate, dateFormat);
}
