<template>
    <b-badge
        variant="atx-blue-2"
        class="border-radius-100 p-1 align-items-center justify-content-center"
        >{{ counter }}</b-badge
    >
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        counter: {
            type: Number,
            required: true,
            default: 0,
        },
    },
});
</script>
<style lang="scss" scoped>
.badge {
    width: 20px;
    height: 20px;
    display: inline-flex;
    font-size: 0.7rem;
}
</style>
