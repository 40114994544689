/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-restricted-syntax */
import { baseUrlA2X, baseUrlA2X2 } from "@/globals";
import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import { storageService } from "./storage.service";
import { useAuthStore, useLoaderStore, useProgressBarStore } from "@/stores";
import { TimeoutError } from "@/api/timeout-exception";
import { simultaneousAccessDetected } from "@/helpers/store";
import { IA2X2User } from "@/models";
import { ADMIN_PAGE_ROUTES } from "@/router/routeConstants";
import axiosProvider from "./axios.provider";
import { useModalStore } from "@/stores/modules/modal";
import { redirectAfterDelay } from "@/helpers/routerUtils";

class PublicApiService {
    protected readonly http: AxiosInstance;
    constructor(baseURL: string) {
        this.http = axiosProvider(baseURL, {});
    }

    setHeader(options: Record<string, any>): void {
        options.forEach((option: Record<string, any>) => {
            this.http.defaults.headers[option.key] = option.value;
        });
    }

    removeHeader(): void {
        this.http.defaults.headers.common = {};
    }

    get<T = any, R = AxiosResponse<T>>(resource: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(resource, config);
    }

    post<T = any, R = AxiosResponse<T>>(
        resource: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.post<T, R>(resource, data, config);
    }

    put<T = any, R = AxiosResponse<T>>(
        resource: string,
        data?: T,
        config?: AxiosRequestConfig | undefined
    ): Promise<R> {
        return this.http.put<T, R>(resource, data, config);
    }

    delete<T = any, R = AxiosResponse<T>>(
        resource: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.delete<T, R>(resource, config);
    }

    /**
     * Perform a custom Axios request.
     **/
    customRequest(data: AxiosRequestConfig): AxiosPromise<any> {
        return this.http(data);
    }
}

export class ApiService {
    protected readonly http: AxiosInstance;
    constructor(baseURL: string) {
        let expirationResponsePending = false;
        let currentUser: IA2X2User | null = null;

        this.http = axios.create({
            baseURL: baseURL,
            headers: {
                Authorization: ` Bearer ${storageService.getAccessToken()}`,
            },
        });

        this.http.interceptors.request.use(
            async (config: any) => {
                // Before doing a request we check if the refresh token is expired
                if (storageService.isRefreshTokenExpired()) {
                    this.showSessionExpiredModal();
                    await redirectAfterDelay("/login", 3500);
                    return Promise.reject("Refresh Token has expired.");
                }
                const loaderStore = useLoaderStore();
                if (loaderStore.tabChanged) {
                    const authStore = useAuthStore();
                    if (
                        !expirationResponsePending &&
                        !config?.url?.includes("user?userId=") &&
                        !config?.url?.includes("switch")
                    ) {
                        try {
                            const response = await this.getUserAsync(authStore.user.userId);
                            currentUser = response[0];
                        } catch (e) {
                            // console.log(e);
                        }
                    }

                    if (
                        currentUser !== null &&
                        simultaneousAccessDetected(currentUser?.currentAccount ?? "")
                    ) {
                        window.location.href = `${ADMIN_PAGE_ROUTES.ADMIN.path}?simultaneousAccessDetected=true`;
                        return;
                    } else {
                        currentUser = null;
                        loaderStore.resetTabChanged();
                    }
                }
                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        this.http.interceptors.response.use(undefined, (error) => {
            const originalRequest = error.config;
            if (
                error.response?.status === 401 &&
                error.response?.data?.message === "Refresh Token has expired." &&
                !originalRequest._retry
            ) {
                originalRequest._retry = true;
                window.location.href = "/login";
            }

            if (
                error.response?.status === 403 &&
                error.response?.data?.message === "Access Token has expired." &&
                !originalRequest._retry
            ) {
                expirationResponsePending = true;
                originalRequest._retry = true;
                return this.postOAuthRefresh().then((resp: any) => {
                    storageService.setAccessToken(resp.accessToken);
                    storageService.setRefreshToken(resp.refreshToken);
                    storageService.setAccessTokenExpiresAt(resp.accessTokenExpiresAt);
                    storageService.setRefreshTokenExpiresAt(resp.refreshTokenExpiresAt);
                    // set new header
                    this.setHeader();

                    originalRequest.headers.Authorization = ` Bearer ${resp.accessToken}`;
                    expirationResponsePending = false;
                    return this.http(originalRequest);
                });
            }

            if (error?.code === "ECONNABORTED") {
                const urlWithoutQueryParams = `${error.config.baseURL}${
                    error.config.url.split("?", 1)[0]
                }`;
                return Promise.reject(
                    new TimeoutError(`Request to ${urlWithoutQueryParams} timed out`)
                );
            }

            return Promise.reject(error);
        });

        // Add a response interceptor
        this.http.interceptors.response.use(
            async (response) => {
                let url;
                try {
                    url = new URL(response.request.responseURL, window.location.origin); // Use current origin as base
                } catch (e) {
                    console.error("Invalid URL:", response.request.responseURL);
                    return response; // Proceed without breaking execution
                }

                if (url.pathname.startsWith("/login")) {
                    // Only checks the path, not query params
                    this.showSessionExpiredModal();
                    await redirectAfterDelay("/login", 3500);
                    return Promise.reject(response);
                }

                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                const progressBarStore = useProgressBarStore();
                if (progressBarStore.active) {
                    progressBarStore.iterate(this.getUrlWithoutParams(response.config.url));
                }

                return response;
            },
            (error) => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error
                const progressBarStore = useProgressBarStore();
                if (progressBarStore.active) {
                    progressBarStore.iterate(this.getUrlWithoutParams(error.config.url));
                }
                return Promise.reject(error);
            }
        );
    }

    showSessionExpiredModal(): void {
        const modalStore = useModalStore();
        modalStore.$patch({
            showModal: true,
            title: "Session Expired",
            message: "You are about to be redirected to the login page.",
            icon: "clock-history",
            iconVariant: "atx-orange-3",
            hideOkButton: true,
        });
    }

    setHeader(): void {
        this.http.defaults.headers["Authorization"] = ` Bearer ${storageService.getAccessToken()}`;
    }

    removeHeader(): void {
        this.http.defaults.headers.common = {};
    }

    get<T = any, R = AxiosResponse<T>>(resource: string, config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(resource, config);
    }

    post<T = any, R = AxiosResponse<T>>(
        resource: string,
        data?: T,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.post<T, R>(resource, data, config);
    }

    put<T = any, R = AxiosResponse<T>>(
        resource: string,
        data?: T,
        config?: AxiosRequestConfig | undefined
    ): Promise<R> {
        return this.http.put<T, R>(resource, data, config);
    }

    delete<T = any, R = AxiosResponse<T>>(
        resource: string,
        config?: AxiosRequestConfig
    ): Promise<R> {
        return this.http.delete<T, R>(resource, config);
    }

    /**
     * Perform a custom Axios request.
     **/
    customRequest(data: AxiosRequestConfig): AxiosPromise<any> {
        return this.http(data);
    }

    postOAuthRefresh<T = any>(): Promise<T> {
        const url = `${baseUrlA2X2}/api/oauth/refresh`;
        const obj = {
            accessToken: storageService.getAccessToken(),
            refreshToken: storageService.getRefreshToken(),
            accessTokenExpiresAt: storageService.getAccessTokenExpiresAt(),
            refreshTokenExpiresAt: storageService.getRefreshTokenExpiresAt(),
        };
        return new Promise((resolve, reject) => {
            this.http
                .post(url, obj)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    getUserAsync<T = [IA2X2User]>(userId: string): Promise<T> {
        const url = `${baseUrlA2X}/rest/user?userId=${userId}`;
        return new Promise((resolve, reject) => {
            this.http
                .get(url)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    getUrlWithoutParams(url: string | undefined): string {
        if (url === undefined) return "";
        return url.split("?")[0];
    }
}

class A2XApi extends ApiService {
    public constructor() {
        /** We concat /rest/ here and not in the main variable mostly because we need them on some places without /rest/ */
        super(`${baseUrlA2X}/rest/`);
    }
}

class A2X2Api extends ApiService {
    public constructor() {
        /** We concat /api/ here and not in the main variable mostly because we need them on some places without /api/ */
        super(`${baseUrlA2X2}/api/`);
    }
}

class A2XBaseApi extends ApiService {
    /** For endpoint that do not have the /rest/ prefix */
    public constructor() {
        super(`${baseUrlA2X}/`);
    }
}

class A2XPublicApi extends PublicApiService {
    public constructor() {
        super(`${baseUrlA2X}/rest/`);
    }
}

export const a2xApi: A2XApi = new A2XApi(),
    a2x2Api: A2X2Api = new A2X2Api(),
    a2xBaseApi: A2XBaseApi = new A2XBaseApi(),
    a2xPublic: A2XPublicApi = new A2XPublicApi();
