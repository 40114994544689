
<div :class="wrapperClasses">
    <b-row class="p-2">
        <b-col v-if="enablePredefinedRangePicker">
            <div class="d-flex flex-column border-right h-100 pr-3">
                <atx-button
                    @click="selectMonth('thisMonth')"
                    size="sm"
                    :variant="
                        monthSelected === 'thisMonth' ? 'atx-blue-2' : 'outline-atx-grey-2'
                    "
                    :class="buttonClasses(monthSelected === 'thisMonth')"
                >
                    This month
                </atx-button>
                <atx-button
                    @click="selectMonth('lastMonth')"
                    size="sm"
                    :variant="
                        monthSelected === 'lastMonth' ? 'atx-blue-2' : 'outline-atx-grey-2'
                    "
                    :class="buttonClasses(monthSelected === 'lastMonth')"
                >
                    Last month
                </atx-button>
                <atx-button
                    @click="selectYear('thisYear')"
                    size="sm"
                    :variant="yearSelected === 'thisYear' ? 'atx-blue-2' : 'outline-atx-grey-2'"
                    :class="buttonClasses(yearSelected === 'thisYear')"
                >
                    This year
                </atx-button>
                <atx-button
                    @click="selectYear('lastYear')"
                    size="sm"
                    :variant="yearSelected === 'lastYear' ? 'atx-blue-2' : 'outline-atx-grey-2'"
                    :class="buttonClasses(yearSelected === 'lastYear')"
                >
                    Last year
                </atx-button>
            </div>
        </b-col>
        <b-col>
            <b-row class="p-2">
                <b-col md="6">
                    <label for="input-date-from">Start date</label>
                    <b-form-input
                        id="input-date-from"
                        ref="input-date-from"
                        v-model="inputDateFrom"
                        :disabled="disabled"
                        readonly
                        @click="showCalendar"
                        @blur="hideCalendar"
                    ></b-form-input>
                </b-col>
                <b-col md="6">
                    <label for="input-date-to">End date</label>
                    <b-form-input
                        id="input-date-to"
                        ref="input-date-to"
                        v-model="inputDateTo"
                        :disabled="disabled"
                        readonly
                        @click="showCalendar"
                        @blur="hideCalendar"
                    ></b-form-input>
                </b-col>
            </b-row>

            <atx-date-picker
                v-show="datepickerVisible"
                ref="atx-range-date-picker"
                v-bind="$attrs"
                v-on="$listeners"
                :calendar-class-main="calendarClassMain"
                v-model="selected"
                :calendar-class="{ 'border-0': true }"
                :highlighted="dateRange"
                :selected-from-date="dateRange.from"
                :selected-to-date="dateRange.to"
                @selected="setRange"
                :open-date="dateRange.from || new Date()"
            >
            </atx-date-picker>
        </b-col>
    </b-row>
    <b-row class="px-2">
        <b-col>
            <div v-if="showClearButton" class="mt-auto">
                <b-link @click="resetDateRange" class="date-range-clear-button">
                    <strong>Clear</strong>
                </b-link>
            </div>
        </b-col>
    </b-row>
</div>
