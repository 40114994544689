<template>
    <div class="rounded-lg border d-flex align-items-center">
        <div
            v-if="icon || imagePath"
            class="p-3 bg-light border-radius-12 border-right text-center border-top-right-radius-0 border-bottom-right-radius-0 d-flex align-self-stretch align-items-center"
        >
            <b-icon v-if="icon" :icon="icon" font-scale="2" class="m-2" />
            <b-img v-else class="w-100" height="50" style="max-width: 100px" :src="imagePath" />
        </div>
        <div class="p-3">
            <a
                v-if="supportLink !== '' && videoLink === ''"
                @click="linkClicked"
                class="text-atx-blue-3 font-weight-bolder d-flex align-items-center justify-content-between"
                :href="supportLink"
                target="_blank"
            >
                <slot> </slot>
                <atx-icon space-left font-scale="1.5" icon="chevron-right" />
            </a>
            <a
                v-if="videoLink !== '' && supportLink === ''"
                @click="linkClicked"
                class="text-atx-blue-3 font-weight-bolder d-flex align-items-center justify-content-between"
                :href="videoLink"
                target="_blank"
            >
                <slot> </slot>
                <atx-icon space-left font-scale="2" icon="play-circle" />
            </a>
        </div>
    </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        imagePath: {
            type: String,
            default: null,
        },
        supportLink: {
            type: String,
            default: "",
        },
        videoLink: {
            type: String,
            default: "",
        },
        /* Use this when you have a icon instead of a image */
        icon: {
            type: String,
            default: null,
        },
    },
    methods: {
        linkClicked() {
            this.$emit("link-clicked");
        },
    },
});
</script>
<style scoped>
.border-top-right-radius-0 {
    border-top-right-radius: 0 !important;
}

.border-bottom-right-radius-0 {
    border-bottom-right-radius: 0 !important;
}
</style>
