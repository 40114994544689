import Vue from "vue";
import VueRouter from "vue-router";
import { BackOfficeRoutes } from "../pages/backend/routes";
import { ReconciliationReportsRoutes } from "../pages/reconciliation-reports/routes";
import { AdminPageRoutes } from "../pages/admin/routes";
import { oauthApi, userApi } from "@/api";
import { useAuthStore, useLoaderStore } from "@/stores";
import pinia from "@/setup/setupPinia";
import {
    REFERRER_MISSING_COOKIE,
    REFERRER_UNBLOCK_COOKIE,
    WHITELIST_PUBLIC_PAGES,
} from "@/globals";
import { metaInfo } from "./guards/MetaInfo";
import { getPageTitle, requiresPermission, refererMatches, updateReferrer } from "@/helpers";
import { simultaneousAccessDetected } from "@/helpers/store";
import { DateRangeSummaryReportRoutes } from "@/pages/amazon/routes";
import { UserSettingsRoutes } from "@/modules/user-settings/routes";
import {
    ACCOUNT_SETTINGS_PAGE_ROUTES,
    ADMIN_PAGE_ROUTES,
    BILLING_PAGE_ROUTES,
    INVOICE_PAGE_ROUTES,
    MAPPING_PAGE_ROUTES,
    OTHER_LINKS,
    OVERVIEW_PAGE_ROUTES,
    PARTNER_PAGE_ROUTES,
    OMNI_ANALYTICS_PAGES,
    SETTLEMENT_PAGE_ROUTES,
    USER_SETTINGS_PAGE_ROUTES,
} from "./routeConstants";
import { ShopifyRoutes } from "@/pages/shopify/routes";
import { ReportsRoutes } from "@/pages/reports/routes";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: import.meta.env.BASE_URL,
    routes: [
        {
            // THIS HAS TO BE APP due to a2x1 setup, localhost it will be "/"
            path: "/",
            meta: {
                title: getPageTitle("App"),
            },
            component: () => import(/* webpackChunkName: "Homepage" */ "@/pages/Homepage.vue"),
            children: [
                {
                    path: ACCOUNT_SETTINGS_PAGE_ROUTES.ACCOUNT_SETTINGS.path,
                    name: ACCOUNT_SETTINGS_PAGE_ROUTES.ACCOUNT_SETTINGS.name,
                    meta: {
                        title: getPageTitle("Settings"),
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "SettingsModule" */ "@/modules/settings/SettingsModule.vue"
                        ),
                    children: [
                        {
                            path: ACCOUNT_SETTINGS_PAGE_ROUTES.AUTOMATION.path,
                            name: ACCOUNT_SETTINGS_PAGE_ROUTES.AUTOMATION.name,
                            meta: {
                                title: getPageTitle("Settings Automation"),
                                pageHeading: "Settings",
                                strictReferrerCheck: true,
                            },
                            component: () =>
                                import(
                                    /* webpackChunkName: "AccountsAndTaxes" */ "@/modules/settings/components/AccountsAndTaxes.vue"
                                ),
                        },
                        {
                            path: ACCOUNT_SETTINGS_PAGE_ROUTES.STATEMENTS_ACCOUNTS_AND_TAXES.path,
                            name: ACCOUNT_SETTINGS_PAGE_ROUTES.STATEMENTS_ACCOUNTS_AND_TAXES.name,
                            meta: {
                                title: getPageTitle("Statements, Accounts and Taxes"),
                                pageHeading: "Statements, Accounts and Taxes",
                                strictReferrerCheck: true,
                            },
                            component: () =>
                                import(
                                    /* webpackChunkName: "Invoices" */ "@/modules/settings/components/Invoices.vue"
                                ),
                        },
                        {
                            path: ACCOUNT_SETTINGS_PAGE_ROUTES.BETA_FEATURES.path,
                            name: ACCOUNT_SETTINGS_PAGE_ROUTES.BETA_FEATURES.name,
                            meta: {
                                title: getPageTitle("Settings Beta Features"),
                                pageHeading: "Settings",
                                strictReferrerCheck: false,
                            },
                            component: () =>
                                import(
                                    /* webpackChunkName: "AccountsAndTaxes" */ "@/modules/settings/components/NewFeatures.vue"
                                ),
                        },
                        {
                            path: ACCOUNT_SETTINGS_PAGE_ROUTES.HISTORY.path,
                            name: ACCOUNT_SETTINGS_PAGE_ROUTES.HISTORY.name,
                            meta: {
                                title: getPageTitle("Settings History"),
                                pageHeading: "Settings",
                                strictReferrerCheck: true,
                            },
                            component: () =>
                                import(
                                    /* webpackChunkName: "History" */ "@/modules/settings/history/History.vue"
                                ),
                        },
                        {
                            path: ACCOUNT_SETTINGS_PAGE_ROUTES.PAYPAL.path,
                            name: ACCOUNT_SETTINGS_PAGE_ROUTES.PAYPAL.name,
                            meta: {
                                title: getPageTitle("PayPal"),
                                pageHeading: "PayPal",
                                strictReferrerCheck: true,
                            },
                            component: () =>
                                import(
                                    /* webpackChunkName: "PayPal" */ "@/modules/settings/components/PayPal.vue"
                                ),
                        },
                        {
                            path: ACCOUNT_SETTINGS_PAGE_ROUTES.NETSUITE_CONFIGURATION.path,
                            name: ACCOUNT_SETTINGS_PAGE_ROUTES.NETSUITE_CONFIGURATION.name,
                            meta: {
                                title: getPageTitle("Settings Netsuite Configuration"),
                                pageHeading: "Settings",
                                strictReferrerCheck: true,
                            },
                            component: () =>
                                import(
                                    /* webpackChunkName: "NetsuiteConfiguration" */ "@/modules/settings/components/NetsuiteConfiguration.vue"
                                ),
                        },

                        {
                            path: ACCOUNT_SETTINGS_PAGE_ROUTES.SHOPIFY_B2B.path,
                            name: ACCOUNT_SETTINGS_PAGE_ROUTES.SHOPIFY_B2B.name,
                            meta: {
                                title: getPageTitle("Settings B2B Invoice"),
                                pageHeading: "Settings",
                                strictReferrerCheck: true,
                            },
                            component: () =>
                                import(
                                    /* webpackChunkName: "ShopifyB2B" */ "@/modules/settings/components/ShopifyB2B.vue"
                                ),
                        },
                    ],
                },
                {
                    path: "/mapping",
                    name: MAPPING_PAGE_ROUTES.APP_MAPPINGS.name,
                    meta: {
                        title: getPageTitle("Mappings"),
                        pageHeading: "Account and Tax Rate Mappings",
                        pageHeaderOverride: true,
                    },
                    component: () =>
                        import(
                            /* webpackChunkName: "Mappings" */ "@/pages/mappings/MappingsModule.vue"
                        ),
                },
            ],
        },
        {
            path: SETTLEMENT_PAGE_ROUTES.SETTLEMENTS.path,
            name: SETTLEMENT_PAGE_ROUTES.SETTLEMENTS.name,
            meta: {
                title: getPageTitle("Settlements"),
                pageHeading: "Settlements",
                pageHeaderOverride: true,
            },
            component: () =>
                import(/* webpackChunkName: "Settlements" */ "@/pages/settlements/Settlements.vue"),
        },
        {
            path: SETTLEMENT_PAGE_ROUTES.B2B_SETTLEMENTS.path,
            name: SETTLEMENT_PAGE_ROUTES.B2B_SETTLEMENTS.name,
            meta: {
                title: getPageTitle("B2B Invoices"),
                pageHeading: "Settlements",
                pageHeaderOverride: true,
            },
            component: () =>
                import(/* webpackChunkName: "Settlements" */ "@/pages/settlements/Settlements.vue"),
            props: () => ({ isSingleOrderShopify: true }),
        },
        {
            path: SETTLEMENT_PAGE_ROUTES.SETTLEMENT.path,
            name: SETTLEMENT_PAGE_ROUTES.SETTLEMENT.name,
            meta: {
                title: getPageTitle("Settlement"),
                pageHeading: "Settlement",
                pageHeaderOverride: true,
            },
            component: () =>
                import(/* webpackChunkName: "Settlement" */ "@/pages/settlement/Settlement.vue"),
        },
        {
            path: USER_SETTINGS_PAGE_ROUTES.NOTIFICATIONS.path,
            name: USER_SETTINGS_PAGE_ROUTES.NOTIFICATIONS.name,
            meta: {
                title: getPageTitle("Notifications"),
            },
            component: () =>
                import(
                    /* webpackChunkName: "Notifications" */ "@/pages/notifications/Notifications.vue"
                ),
        },
        {
            path: PARTNER_PAGE_ROUTES.PARTNER_DASHBOARD.path,
            name: PARTNER_PAGE_ROUTES.PARTNER_DASHBOARD.name,
            meta: {
                title: getPageTitle("Partner Dashboard"),
                pageHeading: "Partner Dashboard",
                pageHeaderOverride: true,
            },
            component: () => import("@/pages/partner/PartnerDashboard.vue"),
        },
        {
            path: INVOICE_PAGE_ROUTES.INVOICE.path,
            name: INVOICE_PAGE_ROUTES.INVOICE.name,
            component: () =>
                import(/* webpackChunkName: "Invoice" */ "@/pages/invoice/Invoice.vue"),
            meta: {
                title: getPageTitle("Invoice"),
            },
        },
        ...BackOfficeRoutes,
        ...AdminPageRoutes,
        ...ReconciliationReportsRoutes,
        ...DateRangeSummaryReportRoutes,
        ...UserSettingsRoutes,
        ...ShopifyRoutes,
        ...ReportsRoutes,
        {
            path: OTHER_LINKS.UNDEFINED_PATH.path,
            name: OTHER_LINKS.UNDEFINED_PATH.name,
            meta: {
                title: getPageTitle("404 - Not Found"),
                pageHeaderOverride: true,
            },
            component: () => import(/* webpackChunkName: "404" */ "@/pages/errors/404.vue"),
        },
        {
            path: "/afterpay",
            name: "AFTERPAY_DEMO",
            meta: {
                title: getPageTitle("Afterpay demo"),
            },
            component: () =>
                import(/* webpackChunkName: "Afterpay" */ "@/pages/afterpay/Afterpay.vue"),
        },
        {
            path: OVERVIEW_PAGE_ROUTES.OVERVIEW_SETTLEMENTS.path,
            name: OVERVIEW_PAGE_ROUTES.OVERVIEW_SETTLEMENTS.name,
            meta: {
                title: getPageTitle("Account Overview"),
            },
            component: () =>
                import(
                    /* webpackChunkName: "OverviewSettlements" */ "@/pages/overview-settlements/OverviewSettlements.vue"
                ),
        },
        {
            path: OMNI_ANALYTICS_PAGES.DASHBOARD.path,
            name: OMNI_ANALYTICS_PAGES.DASHBOARD.name,
            meta: {
                title: getPageTitle("Overview"),
            },
            component: () =>
                import(
                    /* webpackChunkName: "DashboardExample" */ "@/pages/omni/DashboardExample.vue"
                ),
        },
        {
            path: OMNI_ANALYTICS_PAGES.CUSTOM_ANALYSIS.path,
            name: OMNI_ANALYTICS_PAGES.CUSTOM_ANALYSIS.name,
            meta: {
                title: getPageTitle("Custom Analysis"),
            },
            component: () =>
                import(
                    /* webpackChunkName: "CustomAnalysisExample" */ "@/pages/omni/CustomAnalysisExample.vue"
                ),
        },
        {
            path: OMNI_ANALYTICS_PAGES.DIRECTORY.path,
            name: OMNI_ANALYTICS_PAGES.DIRECTORY.name,
            meta: {
                title: getPageTitle("Directory"),
            },
            component: () =>
                import(
                    /* webpackChunkName: "DirectoryExample" */ "@/pages/omni/DirectoryExample.vue"
                ),
        },
    ],
});

let initialReferrerCheckDone = false;
let referrer = document.referrer;
let currentUser = null;

router.beforeEach(async (to, from, next) => {
    /**
     * Skip this hook if we are on the public page
     * This is for the initial call to be prevented to run any possible actions that are not needed for public pages
     */
    let isPublicPage = false;
    Object.keys(WHITELIST_PUBLIC_PAGES).forEach((page) => {
        if (to.path === page) {
            isPublicPage = true;
        }
    });
    if (isPublicPage) {
        console.log("Public pages");
        return false;
    }

    const authStore = useAuthStore(pinia);
    const loaderStore = useLoaderStore(pinia);

    if (loaderStore.tabChanged) {
        const response = await userApi.getByUserIdAsync(authStore.user.userId);
        currentUser = response[0];

        // If current account key from API doesn't match the one from localStorage trigger simultaneous access and redirect to admin
        if (simultaneousAccessDetected(currentUser.currentAccount)) {
            window.location.href = `${ADMIN_PAGE_ROUTES.ADMIN.path}?simultaneousAccessDetected=true`;
            return;
        } else {
            // if the tab was changed BUT they key is the same, reset the tabChanged flag
            currentUser = null;
            loaderStore.resetTabChanged();
        }
    }
    /**
     * Update referrer. We initial use document.referrer but by default its not reactive option as its native to browser and doesn't work in sync with Vue
     * We use the initial document.referrer value to check the referrer link, after that we use the internal links from the vue-router
     * to set latest referrer
     */
    referrer = updateReferrer(referrer, initialReferrerCheckDone, from.path);
    initialReferrerCheckDone = true;
    /**
     * If route have set meta object with strictReferrerCheck param to true that route will be checked for the referrer matching
     * Otherwise it will be skipped and normal vue-router flow continues
     */
    if (to.meta?.strictReferrerCheck && !refererMatches(from, referrer)) {
        /**
         * If we do the redirect lets set global $redirect variable so that we can stop certain components
         * from doing any work whatsoever (this will stop calling certain endpoints with in turn will be called, canceled and then once redirected called again)
         */
        // Vue.prototype.$redirecting = true;
        window.location.href = "/admin";
        return false;
    } else if (to.query.missingReferer) {
        const referrerForceUnblock = Vue.$cookies.get(REFERRER_UNBLOCK_COOKIE);
        if (!referrerForceUnblock) {
            Vue.$cookies.set(REFERRER_MISSING_COOKIE, "1", 0, "/", undefined, true, "None");
        }
    }

    /**
     * Setup meta information including title and any additional meta (description, og etc.)
     */
    metaInfo(to, from);

    //if checkout is missing any of the needed params redirect back to billing
    // Only enter here if we are on the checkout page or billing details page
    if (
        to.name === BILLING_PAGE_ROUTES.CHECKOUT.name ||
        to.name === USER_SETTINGS_PAGE_ROUTES.BILLING.name
    ) {
        if (to.name === BILLING_PAGE_ROUTES.CHECKOUT.name && to.query.accountKey === undefined) {
            next({ name: USER_SETTINGS_PAGE_ROUTES.BILLING.name });
        } else if (
            to.name === USER_SETTINGS_PAGE_ROUTES.BILLING.name &&
            to.query.planCode !== undefined &&
            to.query.accountKey !== undefined
        ) {
            next({
                name: BILLING_PAGE_ROUTES.CHECKOUT.name,
                query: {
                    planCode: to.query.planCode,
                    accountKey: to.query.accountKey,
                },
            });
        } else {
            next();
        }
    }

    const checkPermissions = requiresPermission(to);

    // if the route is not white listed, check if the user has admin permissions to access the route
    if (checkPermissions) {
        // solution for usage of pinia inside router (Vue 2 only) - https://github.com/vuejs/pinia/discussions/723#discussioncomment-3432229
        if (authStore.hasAdminPermissions === null) {
            oauthApi
                .getUserStatusAsync()
                .then((response) => {
                    if (response && response.error === undefined) {
                        authStore.hasAdminPermissions =
                            !response.expired &&
                            (response.user.rootAdmin ||
                                response.user.supportLead ||
                                response.user.supportAdmin);

                        if (!authStore.hasAdminPermissions) {
                            authStore.hasAdminPermissions = false;
                            window.location.href = "/404";
                            return;
                        }

                        next();
                    } else {
                        authStore.hasAdminPermissions = false;
                        window.location.href = "/404";
                    }
                })
                .catch(() => {
                    authStore.hasAdminPermissions = false;
                    window.location.href = "/404";
                });
        } else {
            if (!authStore.hasAdminPermissions) {
                window.location.href = "/404";
            }
            next();
        }
    } else {
        next();
    }
});
export default router;
