
<b-row>
    <b-col>
        <atx-pagination-controls
            v-if="totalRows"
            @updatePerPage="changePerPageHandler"
            :totalRows="totalRows"
            :currentPage="currentPageLocal"
            :perPage="perPageLocal"
            :pageOptions="pageOptions"
        />
    </b-col>
    <b-col>
        <atx-pagination-slim
            @change="changeCurrentPageHandler"
            :totalRows="totalRows"
            :currentPage="currentPageLocal"
            :perPage="perPageLocal"
        />
    </b-col>
</b-row>
