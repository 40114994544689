
<b-modal
    v-on="$listeners"
    v-bind="$attrs"
    :id="id"
    class="atx-modal"
    :title="title"
    :size="size"
    :centered="centered"
    hide-footer
    lazy
    static
    :no-close-on-esc="noCloseOnEscComputed"
    :no-close-on-backdrop="noCloseOnBackdropComputed"
>
    <template #modal-header>
        <h4
            :class="{
                'my-0': true,
                'align-items-center d-flex justify-content-between':
                    $scopedSlots['modal-header-title-right-side'],
            }"
        >
            {{ title }} <slot name="modal-header-title-right-side" />
        </h4>
        <button v-if="showCloseButton" size="sm" class="close" @click="cancel()">×</button>
    </template>
    <b-overlay :show="modalBusy" variant="white">
        <div class="d-block">
            <div class="atx-modal-content">
                <slot />
            </div>
            <div class="d-flex mt-2" v-if="!hideActionButtons">
                <atx-button
                    class="mt-2 mr-2"
                    @click="ok"
                    :variant="okVariant"
                    :disabled="okDisabled"
                    >{{ okTitle }}</atx-button
                >
                <atx-button
                    v-if="!okOnly"
                    @click="cancel"
                    class="mt-2"
                    variant="outline-primary"
                    :disabled="cancelDisabled"
                    >{{ cancelTitle }}</atx-button
                >
            </div>
        </div>
    </b-overlay>
</b-modal>
