import { useAuthStore } from "@/stores";
import { getAccountKeyParam, getUserIdParam } from "@/helpers";
import { sendEventForAsyncRouterPushInitiateForSimultaneousAccessPage } from "./behavioralAnalyticsUtils";

/**
 * Only when true, redirects to admin page.
 */
export function simultaneousAccessDetected(currentAccountKey: string): boolean {
    // Piggybacking
    if (getAccountKeyParam() || getUserIdParam()) {
        return false;
    }

    const authStore = useAuthStore();
    if (currentAccountKey === authStore.getCurrentAccountKey) {
        return false;
    }
    sendEventForAsyncRouterPushInitiateForSimultaneousAccessPage();
    return true;
}
