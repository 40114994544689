
<b-nav-item-dropdown class="profile-dropdown" toggle-class="py-0" right>
    <template #button-content>
        <b-avatar size="35px" variant="light"></b-avatar>
    </template>
    <b-nav-text class="d-flex align-items-center px-4">
        <img
            v-if="enablingPartyIcon"
            :src="enablingPartyIcon"
            alt="login provider logo"
            width="18"
            class="mr-2"
        />
        Logged in as:
    </b-nav-text>
    <b-dropdown-item :href="profileLink">
        {{ userEmail }}
    </b-dropdown-item>
    <template v-for="userLink in userProfileMenu">
        <b-dropdown-item
            :href="userLink.path"
            :key="userLink.name"
            @click="handleNavItemAction(userLink)"
        >
            <template v-if="userLink.name == 'Logout'">
                <b-icon font-scale="1" icon="lock-fill" aria-label="Logout"></b-icon>
            </template>
            {{ userLink.name }}
        </b-dropdown-item>
    </template>
</b-nav-item-dropdown>
