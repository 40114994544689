import { SHOPIFY_PAGE_ROUTES } from "@/router/routeConstants";

export const ShopifyRoutes = [
    {
        path: "/shopify/order/:orderId",
        name: "Order",
        component: (): Promise<typeof import("*.vue")> =>
            import(/* webpackChunkName: "Order" */ "@/pages/shopify/order/Order.vue"),
    },
    {
        path: SHOPIFY_PAGE_ROUTES.RECONNECT.path,
        name: SHOPIFY_PAGE_ROUTES.RECONNECT.name,
        component: (): Promise<typeof import("*.vue")> =>
            import(
                /* webpackChunkName: "ShopifyReconnect" */ "@/pages/shopify/reconnect/Reconnect.vue"
            ),
    },
    {
        path: SHOPIFY_PAGE_ROUTES.SINGLE_ORDER_INVOICE_REPORT.path,
        name: SHOPIFY_PAGE_ROUTES.SINGLE_ORDER_INVOICE_REPORT.name,
        component: (): Promise<typeof import("*.vue")> =>
            import(
                /* webpackChunkName: "SingleOrderInvoiceReport" */ "@/pages/shopify/single-order/InvoiceReport.vue"
            ),
    },
];
