
<b-button-group class="position-static">
    <b-dropdown
        ref="atx-dropdown-datepicker"
        variant="light"
        class="atx-dropdown mx-1"
        toggle-class="border-radius-4 px-2 py-1"
        menu-class="border-radius-12"
        boundary="window"
        :disabled="dropdownDisabled"
        no-caret
        @show="openFilterSelect"
        @hide="closeFilterSelect"
    >
        <template #button-content>
            <span class="dropdown-toggle-button-text">
                {{ formatDateSelected() }}
            </span>
            <atx-filter-counter-badge v-if="badgeCounter > 0" :counter="badgeCounter" />
            <atx-icon icon="chevron-down" />
        </template>

        <atx-date-picker
            :calendar-class-main="calendarClassMain"
            class="mx-3 my-2"
            v-bind="$attrs"
            v-on="$listeners"
            :calendar-class="{ 'border-0': true }"
            :clear-button="false"
            @input="dateSelectedHandler"
        ></atx-date-picker>
    </b-dropdown>
</b-button-group>
