import { ADMIN_PAGE_ROUTES } from "@/router/routeConstants";

export const AdminPageRoutes = [
    {
        path: "/admin/",
        component: (): Promise<typeof import("*.vue")> =>
            import(/* webpackChunkName: "AdminStepperEssentials" */ "./AdminIndex.vue"),
        children: [
            {
                path: "/",
                component: (): Promise<typeof import("*.vue")> =>
                    import(/* webpackChunkName: "AdminStepperEssentials" */ "./Admin.vue"),
                children: [
                    {
                        path: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_WALMART.path,
                        name: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_WALMART.name,
                        redirect: "/admin",
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_EBAY.path,
                        name: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_EBAY.name,
                        redirect: "/admin",
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_ETSY.path,
                        name: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_ETSY.name,
                        redirect: "/admin",
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_SHOPIFY.path,
                        name: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_SHOPIFY.name,
                        redirect: "/admin",
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_AMAZON.path,
                        name: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_AMAZON.name,
                        redirect: "/admin",
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_BIGCOMMERCE.path,
                        name: ADMIN_PAGE_ROUTES.STEPPER_CONNECT_TO_BIGCOMMERCE.name,
                        redirect: "/admin",
                    },
                ],
            },
            {
                path: ADMIN_PAGE_ROUTES.AUTH_TOKEN.path,
                name: ADMIN_PAGE_ROUTES.AUTH_TOKEN.name,
                component: (): Promise<typeof import("*.vue")> =>
                    import("./__components__/connections/AuthToken.vue"),
            },
            {
                path: ADMIN_PAGE_ROUTES.REQUEST_ACCESS.path,
                name: ADMIN_PAGE_ROUTES.REQUEST_ACCESS.name,
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "AdminSalesChannels" */ "./__components__/connections/RequestAccess.vue"
                    ),
            },
            /**
             * This is accounting route from admin parent (we use this one to setup a skeleton for all accounting sub-routes)
             * vue-router will pick up /app/admin/accounting previously in the stepper parent so this one will be ignored for
             * the same path. However it will fire for all accounting/* routes (e.g. app/admin/accounting/xero)
             */
            {
                path: ADMIN_PAGE_ROUTES.ACCOUNTING.path,
                name: ADMIN_PAGE_ROUTES.ACCOUNTING.name,
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/AccountingLoadingSkeleton.vue"
                    ),
                meta: {
                    strictReferrerCheck: true,
                },
                children: [
                    {
                        path: ADMIN_PAGE_ROUTES.CONNECT_XERO.path,
                        name: ADMIN_PAGE_ROUTES.CONNECT_XERO.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/xero/Xero.vue"
                            ),
                        meta: {
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.XERO_CALLBACK.path,
                        name: ADMIN_PAGE_ROUTES.XERO_CALLBACK.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/xero/cb/Cb.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.CONNECT_QUICKBOOKS.path,
                        name: ADMIN_PAGE_ROUTES.CONNECT_QUICKBOOKS.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/qbo/Qbo.vue"
                            ),
                        meta: {
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.QUICKBOOKS_CALLBACK.path,
                        name: ADMIN_PAGE_ROUTES.QUICKBOOKS_CALLBACK.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/qbo/cb/Cb.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.QUICKBOOKS_BANK_ACCOUNT_SETTINGS.path,
                        name: ADMIN_PAGE_ROUTES.QUICKBOOKS_BANK_ACCOUNT_SETTINGS.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/qbo/bank-account-settings/BankAccountSettings.vue"
                            ),
                        meta: {
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.CONNECT_SAGE.path,
                        name: ADMIN_PAGE_ROUTES.CONNECT_SAGE.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/sage/Sage.vue"
                            ),
                        meta: {
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.SAGE_CALLBACK.path,
                        name: ADMIN_PAGE_ROUTES.SAGE_CALLBACK.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/sage/cb/Cb.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.SAGE_FINANCIAL_SETTINGS.path,
                        name: ADMIN_PAGE_ROUTES.SAGE_FINANCIAL_SETTINGS.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/sage/financial-settings/FinancialSettings.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.CONNECT_NETSUITE.path,
                        name: ADMIN_PAGE_ROUTES.CONNECT_NETSUITE.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/netsuite/NetSuite.vue"
                            ),
                        meta: {
                            strictReferrerCheck: true,
                        },
                    },
                ],
            },
            {
                path: ADMIN_PAGE_ROUTES.SALES_SOURCE.path,
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/AccountingLoadingSkeleton.vue"
                    ),
                children: [
                    {
                        path: ADMIN_PAGE_ROUTES.EBAY_CALLBACK.path,
                        name: ADMIN_PAGE_ROUTES.EBAY_CALLBACK.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminConnectCallBacks" */ "./sales-channel/ebay/cb/Cb.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.ETSY_CALLBACK.path,
                        name: ADMIN_PAGE_ROUTES.ETSY_CALLBACK.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminConnectCallBacks" */ "./sales-channel/etsy/cb/Cb.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.SHOPIFY_CALLBACK.path,
                        name: ADMIN_PAGE_ROUTES.SHOPIFY_CALLBACK.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminConnectCallBacks" */ "./sales-channel/shopify/cb/Cb.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.AMAZON_CALLBACK.path,
                        name: ADMIN_PAGE_ROUTES.AMAZON_CALLBACK.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminConnectCallBacks" */ "./sales-channel/amazon/cb/Cb.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.BIGCOMMERCE_CALLBACK.path,
                        name: ADMIN_PAGE_ROUTES.BIGCOMMERCE_CALLBACK.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminConnectCallBacks" */ "./sales-channel/bigcommerce/cb/Cb.vue"
                            ),
                    },
                ],
            },
            {
                path: ADMIN_PAGE_ROUTES.SALES_CHANNEL.path,
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "AdminAccountingSystems" */ "./accounting/AccountingLoadingSkeleton.vue"
                    ),
                children: [
                    {
                        path: ADMIN_PAGE_ROUTES.CONNECT_SHOPIFY.path,
                        name: ADMIN_PAGE_ROUTES.CONNECT_SHOPIFY.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminSalesChannels" */ "./sales-channel/shopify/connect/Connect.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.CONNECT_AMAZON.path,
                        name: ADMIN_PAGE_ROUTES.CONNECT_AMAZON.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminSalesChannels" */ "./sales-channel/amazon/connect/Connect.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.BIGCOMMERCE_ACCESS_TOKEN.path,
                        name: ADMIN_PAGE_ROUTES.BIGCOMMERCE_ACCESS_TOKEN.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminSalesChannels" */ "./sales-channel/bigcommerce/access-token/AccessToken.vue"
                            ),
                    },
                    {
                        path: ADMIN_PAGE_ROUTES.CONNECT_BIGCOMMERCE.path,
                        name: ADMIN_PAGE_ROUTES.CONNECT_BIGCOMMERCE.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "AdminSalesChannels" */ "./sales-channel/bigcommerce/connect/Connect.vue"
                            ),
                    },
                ],
            },
        ],
    },
];
