
<b-badge :v-if="getText()" class="atx-badge" :class="getClass()" :variant="variant">
    <atx-icon
        width="12"
        height="12"
        v-if="getIcon()"
        :icon="getIcon()"
        :class="`${getIconClass()}`"
    />
    <span :class="getTextClass()">{{ getText() }}</span>
    <span v-if="extraText" class="bg-white ml-1 p-1" :class="`text-${extraTextVariant}`">
        {{ extraText }}
    </span>
</b-badge>
