import Vue from "vue";
import { markRaw } from "@vue/composition-api";
import { createPinia, PiniaVuePlugin } from "pinia";
import router from "@/router/index";

Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
pinia.use(({ store }) => {
    store.router = markRaw(router);
});

export default pinia;
