import { defineStore } from "pinia";

type ModalState = {
    showModal: boolean;
    title: string;
    message: string;
    icon: string;
    iconVariant: string;
    hideOkButton: boolean;
};

/**
 * Modal store - handles the modal state in the layout component
 */
export const useModalStore = defineStore({
    id: "modal",
    state: (): ModalState =>
        ({
            showModal: false,
            title: "",
            message: "",
            icon: "",
            iconVariant: "",
            hideOkButton: false,
        } as ModalState),
});
