import { defineStore } from "pinia";
/**
 * This store is used to show a progress bar when a loop is running. Below you will find an example on how to use it:
 *
//  * let data = [...some data here that we will iterate through and for each iteration we will call the handleRequest() function];
//  *
//  * this.progressBarStore.start(data.length,"Posting to " + this.accountStore.accountingSystem, action);
//  * for (let item of data) {
//  *      await this.handleRequest(item);
//  * }
//  * this.progressBarStore.stop(action);
 *
 * The iterate() action is being called inside the this.http.interceptors.response which can be found in the api.service.ts file. It is called on both the fullfilled and rejected promises.
 *
 */
export const useProgressBarStore = defineStore({
    id: "progressBar",
    state: () => ({
        active: false,
        iterations: 0,
        totalIterations: 0,
        content: "",
        action: "",
    }),
    actions: {
        /**
         * Start action is used to start the showing of the progress bar and set the total iterations and content which is passed from the calling component
         * @param totalIterations
         * @param content
         * @param action
         */
        start(totalIterations: number, content: string, action = "") {
            this.active = true;
            this.totalIterations = totalIterations;
            this.content = content;
            this.action = action.trim();
        },
        /**
         * This action should not be used as it is used only internally to increment the iterations
         */
        iterate(url: string | undefined) {
            if (url === undefined) return;
            // Get url without params and leading /
            const urlAction = url.split("?")[0].split("/").pop()?.trim();
            if (urlAction === this.action) {
                this.iterations++;
            }
        },
        /**
         * Once the loop is finalized the stop(action) action should be called to stop the progress bar and reset everything
         */
        stop(action = "") {
            this.active = false;
            this.iterations = 0;
            this.totalIterations = 0;
            if (action.trim() === this.action) {
                this.action = "";
            }
        },
    },
});
