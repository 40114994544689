
<b-nav-item-dropdown class="whats-new-dropdown d-none d-md-block" no-caret>
    <template #button-content>
        <span class="label-new bg-gradient-orange position-relative" @click="dropdownHandler">
            <atx-icon
                style="width: 14px; height: 14px"
                icon="gift-fill"
                class="align-middle mr-1"
            />

            <span class="d-inline-block align-middle">NEW</span>

            <span
                v-if="hasNewItems && showNotificationIndicator"
                class="notification-wrapper position-absolute"
                style="top: -5px; right: 11px"
            >
                <span class="position-relative">
                    <b-icon
                        icon="circle-fill"
                        variant="atx-red-2"
                        class="animated-background-dot background-icon"
                        width="18"
                        height="18"
                    ></b-icon>
                    <b-icon
                        icon="circle-fill"
                        variant="atx-red-2"
                        class="animated-dot foreground-icon"
                        width="12"
                        height="12"
                    ></b-icon>
                </span>
            </span>
        </span>
    </template>
    <b-dropdown-text variant="atx-blue-4">
        <span class="font-weight-bold">Latest updates</span>
    </b-dropdown-text>
    <!-- Loop over the first 5 items -->
    <b-dropdown-item
        v-for="(item, index) in limitedWhatsNewData"
        :key="index"
        class="p-0"
        :href="item.permalink"
        target="_blank"
        link-class="d-flex align-items-center justify-content-between"
        @click="$emit('post-clicked')"
    >
        {{ item.title }}
        <atx-badge
            v-if="isNew(item)"
            text="NEW"
            class="bg-gradient-orange ml-1 text-atx-blue-3"
        />
    </b-dropdown-item>
    <b-dropdown-item
        class="text-decoration-underline"
        variant="atx-blue-2"
        :href="WEBSITE_LINKS.WHATS_NEW.path"
        target="_blank"
        @click="$emit('view-more-clicked')"
    >
        View more
    </b-dropdown-item>
</b-nav-item-dropdown>
