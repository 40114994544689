<template>
    <b-nav-item-dropdown
        text="Settings"
        :toggle-class="{
            active: Object.values(settingsMenu).some((subMenuItem) =>
                subMenuItem.path.includes(currentRoute)
            ),
        }"
    >
        <b-dropdown-item
            v-for="settingsMenuItem in settingsMenu"
            :href="settingsMenuItem.path"
            :key="settingsMenuItem.name"
        >
            <atx-icon
                v-if="settingsMenuItem.icon && settingsMenuItem.icon !== ''"
                :icon="settingsMenuItem.icon"
                :class="settingsMenuItem.iconClasses"
                space-right
            />
            {{ settingsMenuItem.name }}
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script lang="ts">
import { ISettingsMenuItem } from "@/components/models/IMenu";
import Vue from "vue";
export default Vue.extend({
    props: {
        settingsMenu: {
            type: Array as () => ISettingsMenuItem[],
            default: null,
        },
        currentRoute: {
            type: String,
            default: "",
        },
    },
});
</script>

<style></style>
