<template>
    <b-button class="atx-chevron" v-bind="$attrs" :disabled="isLoading" v-on="$listeners">
        <template v-if="isLoading">
            <b-spinner small label="Loading..."></b-spinner>
        </template>
        <b-icon-chevron-right v-else />
    </b-button>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
});
</script>

<style lang="scss" scoped>
.atx-chevron {
    width: 40px;
    height: 40px;
    background: var(--atx-blue-1);
    border-radius: 50px;
    color: #000;
    padding: 0;
    border: 0;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    &:hover {
        background: var(--atx-orange-2);
        cursor: pointer;
    }
}
</style>
