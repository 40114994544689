import Vue from "vue";
import * as components from "./index";

// generate name for component
// Example: // AtxInfoCard -> atx-info-card
function atxComponentName(componentName: string): string {
    return `${componentName.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase()}`;
}

// Auto Export all components
export function useDataDisplays(): void {
    Object.entries(components).forEach(([componentName, component]) => {
        Vue.component(atxComponentName(componentName), component);
    });
}
