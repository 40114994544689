import { IUser } from "@/models";
import { a2xApi } from "@/services/api.service";
import { AxiosResponse } from "axios";

export const userApi = {
    async getAsync(accountKey: string): Promise<IUser> {
        const url = `user?accountKey=${accountKey}`;
        try {
            const response: AxiosResponse = await a2xApi.get(url);
            return response.data;
        } catch (e) {
            return <IUser>{ error: `${e}` };
        }
    },
    async getByUserIdAsync(userId: string): Promise<any> {
        const url = `user?userId=${userId}`;
        try {
            const response: AxiosResponse = await a2xApi.get(url);
            return response.data;
        } catch (e) {
            return <any>{ error: `${e}` };
        }
    },
    async getProfileAsync(userId: string): Promise<IUser> {
        const url = `user-profile?userId=${userId}`;
        try {
            const response: AxiosResponse = await a2xApi.get(url);
            return response.data;
        } catch (e) {
            return <IUser>{};
        }
    },
    async postProfileAsync(obj: IUser): Promise<IUser> {
        const url = `user-profile`;
        try {
            const response: AxiosResponse = await a2xApi.post(url, obj);
            return response.data;
        } catch (e) {
            return <IUser>{ error: `${e}` };
        }
    },
};
