
<component
    :is="currentComponent"
    :class="{
        'atx-alerts': true,
        'my-6': container,
        'mb-6': !container,
    }"
    v-if="alertsExist"
    ><slot name="custom-alerts" />
    <atx-alert
        :top-space="false"
        v-for="alert in sortedAlerts"
        :key="alert.message"
        :icon="alert.icon"
        :icon-variant="alert.iconVariant"
        :variant="alertVariant(alert.type)"
        :show="alert.show"
        :dismissible="alert.dismissible"
    >
        <span v-html="alert.message"></span>
    </atx-alert>
</component>
