import { AccountingSystem, FEATURE_FLAGS, SalesSource } from "@/models";
import axios from "axios";

// Used to check for errors in API responses
// Also used to work around specific TS check below:
// Do not access Object.prototype method 'hasOwnProperty' from target object.eslintno-prototype-builtins
export function isError(obj: unknown): boolean {
    return Object.prototype.hasOwnProperty.call(obj, "error") || obj === null || obj === undefined;
}

/**
 * Sets a cookie with the specified name, value, expiration time, and SameSite policy.
 *
 * @param {string} cname - The name of the cookie.
 * @param {string} cvalue - The value to store in the cookie.
 * @param {number} expirationInDays - The number of days until the cookie expires.
 * @param {SameSiteFlag} sameSite - The SameSite attribute value for the cookie (default: "Strict").
 *
 * @example
 * setCookie("username", "JohnDoe", 7, "Lax"); // Sets a cookie for 7 days with Lax SameSite policy
 */
export function setCookie(
    cname: string,
    cvalue: string,
    expirationInDays: number,
    sameSite: "Strict" | "Lax" | "None" = "Strict"
): void {
    if (isNaN(expirationInDays) || expirationInDays <= 0) {
        console.warn("Invalid expiration time. Cookie not set.");
        return;
    }

    const d = new Date();
    d.setTime(d.getTime() + expirationInDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;

    // Check if running on localhost
    const isLocalhost = window.location.hostname === "localhost";

    let secureFlag = "";
    if (!isLocalhost) {
        secureFlag = "Secure;";
    }

    // If SameSite=None, Secure must be set (unless on localhost)
    if (sameSite === "None" && !isLocalhost) {
        secureFlag = "Secure;";
    }

    // Encode cookie value to avoid parsing issues
    const encodedValue = encodeURIComponent(cvalue);

    // Set the cookie with the specified parameters
    document.cookie = `${cname}=${encodedValue}; ${expires}; path=/; ${secureFlag} SameSite=${sameSite}`;
}

/**
 * Retrieves the value of a specified cookie by name.
 *
 * @param {string} cname - The name of the cookie to retrieve.
 * @returns {string} The value of the cookie if found, otherwise an empty string.
 */
export function getCookie(cname: string): string {
    try {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    } catch (error) {
        console.error("Error retrieving cookie:", error);
        return "";
    }
}

/**
 * Checks object equality
 * @param o1
 * @param o2
 * @returns
 */
export function objectsEqual(
    o1: Record<string, unknown> | undefined,
    o2: Record<string, unknown> | undefined
): boolean {
    if (o1 === undefined || o2 === undefined) return false;
    return (
        Object.keys(o1).length === Object.keys(o2).length &&
        Object.keys(o1).every((p) => o1[p] === o2[p])
    );
}

/**
 * Capitalize the passed value and return it
 * @param str
 * @returns
 */
export function capitalize(str: string): string {
    if (!str) return "";
    const lower = str.toLowerCase();
    return str.charAt(0).toUpperCase() + lower.slice(1);
}

/**
 * Capitalize the passed AccountingSystem or SalesChannel
 * Special Cases:
 * ebay -> eBay
 * qbo -> QuickBooks
 * @param system
 * @returns
 */
export function getSourceDisplayName(source: AccountingSystem | SalesSource): string {
    const displayNameList: Record<string, string> = {
        [AccountingSystem.QBO]: "QuickBooks",
        [AccountingSystem.NETSUITE]: "NetSuite",
        [AccountingSystem.MANUAL]: "Manual",
        [SalesSource.EBAY]: "eBay",
        [SalesSource.BIGCOMMERCE]: "BigCommerce",
        [SalesSource.PAYPAL]: "PayPal",
    };

    if (source in displayNameList) {
        return displayNameList[source];
    }

    return capitalize(source);
}

/**
 * Pack function mostly needed for packaging array of objects and passing through components as prop
 * @param payload
 * @returns
 */
export function pack(payload: unknown): string {
    return JSON.stringify(payload);
}

/**
 * Make array unique by removing the duplicates
 * @param array
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function arrayUnique(array: Array<any>): Array<any> {
    const a = array.concat();
    for (let i = 0; i < a.length; ++i) {
        for (let j = i + 1; j < a.length; ++j) {
            if (a[i] === a[j]) a.splice(j--, 1);
        }
    }

    return a;
}

/**
 * Deep clone the passed data (presumably array) and return it
 * @param array
 * @returns
 */
export function deepCopy<T>(array: T): T {
    if (array === null || array === undefined) return array;
    const deepCloneDataString = JSON.stringify(array);
    const deepCloneData = JSON.parse(deepCloneDataString);
    return deepCloneData;
}

export function isNullish(response: unknown): boolean {
    if (response === undefined || response === null) {
        return true;
    }
    return false;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isHttpError(response: any): boolean {
    return response.status > 399 ? true : false;
}

/**
 * Checks if all needed params have values for subsequent requests
 */
export function isValidCallback(callbackParams: Record<string, unknown>): boolean {
    let valid = false;

    for (const [, value] of Object.entries(callbackParams)) {
        if (!value) {
            valid = false;
            break;
        }

        valid = true;
    }

    return valid;
}

export interface AtxError {
    error: string;
    path?: string;
    status?: number;
    timestamp?: number;
}

/**
 * For now we will set return type as "any" since its least effort for the time being until we get this errorHandler across all endpoints. Then we will
 * update with even better error handling on API wrapper level.
 * @param error
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function catchErrorHandler(error: unknown): any {
    let data;
    if (axios.isAxiosError(error)) {
        /**
         * Should give object like this in error.response.data:
         * {
         *      error: "Internal Server Error"
                path: "/api/public/mappings/getUserMappings"
                status: 500
                timestamp: 1671446739790
            }
         */

        data = error.response?.data ?? {
            error: "Something went wrong, please try later.",
        };

        /**
         * Unfortunately we can also get an object like this too, so we'll need to map to our AtxError interface:
         * {
         *      httpStatusCode: 422
         *      message: "Please verify your enabled features."
         *      timestamp: 123123123
         *      type: "unprocessable-request-exception"
         * }
         */
        if (data.message && data.httpStatusCode) {
            data.error = data.message;
            data.status = data.httpStatusCode;
        }
        if (data.message && data.code) {
            data.error = data.message;
            data.status = data.code;
        }

        // If bad gateway something went wrong with the requested object. Couldn't get served for some reason.
        if (error && error.response && error.response.status === 502) {
            data = { error: "Something went wrong, please try later." };
        }

        /**
         * If the 500 error is not handled properly on backend and we get the actual page from within response or empty data
         * we solve it by picking error.response
         */
        if (data === "" || (typeof data === "string" && data.includes("<!DOCTYPE html>"))) {
            data = {
                error: "Something went wrong, please try later.",
                status: error.response?.status,
            };
        }
    } else {
        data = { error: "Unexpected error. Please try later." };
    }
    return data;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function containsErrorResponse(response: any): boolean {
    return (
        "message" in response ||
        "error" in response ||
        "posterr" in response ||
        ("status" in response && response.status === 500)
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function getErrorResponse(response: any): string {
    const messageExist = response.message ?? response.message;
    const errorExist = response.error ?? response.error;
    const posterrExist = response.posterr ?? response.posterr;
    const messageResponse = messageExist || errorExist || posterrExist || "";

    return messageResponse;
}

export function lightenDarkenColor(color: string, percent: number): string {
    const num = parseInt(color, 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        B = ((num >> 8) & 0x00ff) + amt,
        G = (num & 0x0000ff) + amt;

    return (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
        (G < 255 ? (G < 1 ? 0 : G) : 255)
    )
        .toString(16)
        .slice(1);
}

export function isProductionEnv(): boolean {
    return import.meta.env.VITE_APP_NODE_ENV === "production";
}

export function getAcronym(text: string): string {
    const acronym = text.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), "");
    return acronym;
}

export function getMappingTerminology(salesChannel: string): string {
    if (salesChannel === null || salesChannel === "") return "Accounts and Taxes";
    return "Accounts and Taxes";
}

export function getContinueParam(): string {
    const currentPath = window.location.pathname;
    const currentQuery = window.location.search;
    return `continue=${encodeURIComponent(`${currentPath}${currentQuery}`)}`;
}

export function redirectToLogin(): void {
    const continuePath = getContinueParam();
    window.location.href = `/login?${continuePath}`;
}

export function getPageTitle(name: string): string {
    return `${name} | A2X Accounting`;
}

export function isAutoMappingVisible(
    salesSource: SalesSource,
    accountingSystem: AccountingSystem
): boolean {
    if (salesSource === SalesSource.PAYPAL && accountingSystem === AccountingSystem.NETSUITE) {
        return false;
    }
    return true;
}

export function isManualConnection(accountingSystem: AccountingSystem): boolean {
    return accountingSystem.toUpperCase() === AccountingSystem.MANUAL;
}

export function isQboConnection(accountingSystem: AccountingSystem): boolean {
    return accountingSystem.toUpperCase() === AccountingSystem.QBO;
}

export function isShopify(salesChannel: SalesSource): boolean {
    return salesChannel.toUpperCase() === SalesSource.SHOPIFY;
}

export function isPayPal(salesChannel: SalesSource): boolean {
    return salesChannel.toUpperCase() === SalesSource.PAYPAL;
}

export function isNetSuite(accountingSystem: AccountingSystem): boolean {
    return accountingSystem.toUpperCase() === AccountingSystem.NETSUITE;
}

export function isAmazon(salesChannel: SalesSource): boolean {
    return salesChannel.toUpperCase() === SalesSource.AMAZON;
}

export function isEbay(salesChannel: SalesSource): boolean {
    return salesChannel.toUpperCase() === SalesSource.EBAY;
}

export function hasAdvancedCogsFeatures(
    cogsEnabled: boolean,
    premium: boolean,
    featureFlags: FEATURE_FLAGS[] | null
): boolean {
    if (cogsEnabled && premium) {
        return featureFlags?.includes(FEATURE_FLAGS.SHOW_COGS_ONLY_REFRESH_BULK_ACTION)
            ? true
            : false;
    }
    return false;
}
