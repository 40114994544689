
<b-row class="h-100">
    <b-col md="4" class="d-flex align-items-end justify-content-around">
        <b-skeleton variant="atx-blue-1" width="48px" height="30%" />
        <b-skeleton variant="atx-blue-1" width="48px" height="60%" />
    </b-col>
    <b-col md="4" class="d-flex align-items-end justify-content-around">
        <b-skeleton variant="atx-blue-1" width="48px" height="20%" />
        <b-skeleton variant="atx-blue-1" width="48px" height="70%" />
    </b-col>
    <b-col md="4" class="d-flex align-items-end justify-content-around">
        <b-skeleton variant="atx-blue-1" width="48px" height="40%" />
        <b-skeleton variant="atx-blue-1" width="48px" height="90%" />
    </b-col>
</b-row>
