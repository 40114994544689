<template>
    <div class="d-inline-flex flex-row">
        <span :class="`order-${labelPosition === 'right' ? 1 : 0}`"
            >{{ label }} ({{ percentage.toFixed() }}% complete).</span
        >
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            :class="`${labelPosition === 'right' ? 'mr-2' : 'ml-2'}`"
        >
            <circle cx="12" cy="12" r="10" fill="none" stroke="#dde6f0" :stroke-width="svgStroke" />
            <circle
                class="percent"
                cx="12"
                cy="12"
                r="10"
                fill="none"
                stroke="#0672D3"
                :stroke-width="svgStroke"
                pathLength="100"
                :style="{ 'stroke-dashoffset': svgPercentage }"
            />
        </svg>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        percentage: {
            type: Number,
            default: 0,
            required: true,
        },
        label: {
            type: String,
            default: "",
        },
        labelPosition: {
            type: String,
            default: "left",
        },
    },
    data() {
        return {
            svgStroke: 4,
        };
    },
    computed: {
        svgPercentage(): number {
            return 100 - this.percentage;
        },
    },
});
</script>

<style scoped>
svg {
    transform: rotate(-90deg);
}
.percent {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    stroke-linecap: round;
}
</style>
