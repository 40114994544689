
<div class="text-center table-overlay py-5">
    <b-icon icon="house-door" class="h1 pt-3 text-atx-blue-3" />
    <h5 class="text-atx-blue-3 mb-0">{{ title }}</h5>
    <p class="text-atx-grey-4">
        <b-row>
            <b-col md="4" sm="2" />
            <b-col md="4" sm="8">
                <slot />
            </b-col>
            <b-col md="4" sm="2" />
        </b-row>
    </p>
    <atx-button variant="atx-blue-3" @click="finishSteps">Finish steps 1 - 3 </atx-button>
</div>
