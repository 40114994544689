import { onboardingQuestionnaireApi } from "@/api";
import { query } from "vue-gtag";
import { useAccountStore } from "@/stores";

export enum FEATURES {
    ONBOARDING = "onboarding",
    RECONCILIATION = "reconciliation",
    BILLING = "billing",
    MAPPINGS = "mappings",
    // related to sign in, sign up and other auth-related pages
    AUTH = "auth",
    SIDEBAR = "sidebar",
    SP_SETTLEMENTS = "sp_settlements",
    // Single Settlement Page
    SINGLE_SETTLEMENT = "single_settlement",
    // B2B Invoices page
    SINGLE_ORDER_SETTLEMENTS = "single_order_settlements",
    BETA_FEATURE = "beta_feature",
    SETTLEMENT = "settlement",
    ACCOUNT_SETTINGS = "account_settings",
    EXPORT = "export",
    NAVIGATION = "navigation",
}

type EventProperties = Record<string, unknown>;

/**
 * Sending Behavioural Analytics Event to GA4
 * with standard properties like sales_channel, accounting_system and version
 */
export function sendStandardEvent(
    eventName: string,
    eventProperties: Record<string, unknown>
): void {
    const accountStore = useAccountStore();
    const salesChannel = accountStore.salesChannel.toUpperCase();
    const accountingSystem = accountStore.accountingSystem.toUpperCase();
    query("event", eventName, {
        sales_channel: salesChannel,
        accounting_system: accountingSystem,
        ...eventProperties,
        version: "2",
    });
}

function determineCustomQuestionnaireCompleted(shouldSplitByCountry: boolean | undefined | null) {
    return shouldSplitByCountry === undefined ? false : true;
}
function determineAssistedQuestionnaireCompleted(quickSetup: boolean | undefined | null | string) {
    return quickSetup === "undefined" ? false : quickSetup;
}
function sendPurchaseEvent(
    planAmount: number,
    mappings_questionnaire_complete: boolean | string,
    mappings_questionnaire_assisted_complete: boolean | string
) {
    sendStandardEvent("purchase", {
        feature: FEATURES.MAPPINGS,
        event_name: "subscription-complete",
        event_action: "admin-success",
        event_value: planAmount,
        mappings_questionnaire_complete: mappings_questionnaire_complete,
        mappings_questionnaire_assisted_complete: mappings_questionnaire_assisted_complete,
    });
}

export async function trackPurchaseEventAsync(
    accountKey: string,
    planAmount: number
): Promise<void> {
    query("event", "conversion", {
        send_to: "AW-963627325/_uTyCKC46YwCEL2Sv8sD",
        value: planAmount,
        currency: "USD",
    });

    const response = await onboardingQuestionnaireApi.getAsync(accountKey);
    if (response && Object.keys(response).length > 0) {
        // Attach a2x2assistedSetup true/false
        let isAssistedQuestionnaireCompleted;
        const isCustomQuestionnaireCompleted = determineCustomQuestionnaireCompleted(
            response.shouldSplitByCountry
        );

        if (response.quickSetup === undefined || response.quickSetup === null) {
            isAssistedQuestionnaireCompleted = "undefined";
        } else {
            isAssistedQuestionnaireCompleted = response.quickSetup;
        }

        const mappingsQuestionnaireComplete =
            determineAssistedQuestionnaireCompleted(isAssistedQuestionnaireCompleted) ||
            isCustomQuestionnaireCompleted;

        sendPurchaseEvent(
            planAmount,
            mappingsQuestionnaireComplete,
            isAssistedQuestionnaireCompleted
        );
    } else {
        // This handles a 404 response, which means the onboarding Questionnaire has not been completed
        sendPurchaseEvent(planAmount, "error", "error");
    }
}

export enum ReconciliationDataFields {
    LineItems = "line_items",
    Orders = "orders",
    Settlements = "settlements",
    Transactions = "transactions",
    OrderCreatedDate = "created_date",
    SalesId = "sales_id",
}

/**
 * Sending "reconciliation_report_no_line_items"   event
 * Sending "reconciliation_report_no_orders"       event
 * Sending "reconciliation_report_no_settlements"  event
 * Sending "reconciliation_report_no_transactions" event
 * Sending "reconciliation_report_no_created_date" event
 * Sending "reconciliation_report_no_sales_id" event
 */
export function sendEventReconciliationReportMissingData(
    missingDataField: ReconciliationDataFields,
    orderId: string,
    accountKey: string
): void {
    sendStandardEvent(`reconciliation_report_no_${missingDataField}`, {
        feature: FEATURES.RECONCILIATION,
        order_id: orderId,
        account_key: accountKey,
    });
}

/**
 * Sending "async_router_push_to_app_admin_failed_from_simultaneous_access_detection" event.
 * Happens when a router pushes to app admin and fails on detection of a simultaneous access detection.
 */
export function sendEventForAsyncRouterPushFailureForSimultaneousAccessPage(e: Error): void {
    if (typeof window?.gtag === "function") {
        window?.gtag(
            "event",
            "async_router_push_to_app_admin_failed_from_simultaneous_access_detection",
            {
                feature: FEATURES.AUTH,
                exception: e,
            }
        );
    }
}

/**
 * Sending "async_router_push_to_app_admin_failed_from_simultaneous_access_detection" event.
 * Happens when a router pushes to app admin and fails on detection of a simultaneous access detection.
 */
export function sendEventForAsyncRouterPushInitiateForSimultaneousAccessPage(): void {
    if (typeof window?.gtag === "function") {
        window?.gtag("event", "async_router_push_initiate_to_app_admin", {
            feature: FEATURES.AUTH,
        });
    }
}

export const tracking = {
    accountSettings: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "account_settings"
         */
        sendAccountSettingsEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.ACCOUNT_SETTINGS,
                ...eventProperties,
            });
        },
        // we divide further into settings tabs for easier distribution and naming of the events. Not to be confused with salesSources
        //name of the tab
        paypal: {
            // acc_settings_paypal_change_categories
            sendChangeCategories: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_paypal_change_categories",
                    eventProperties
                );
            },
            // acc_settings_paypal_save
            sendSave: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_paypal_save",
                    eventProperties
                );
            },
            // acc_settings_paypal_change_country
            sendChangeCountry: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_paypal_change_country",
                    eventProperties
                );
            },
            // acc_settings_paypal_change_timezone
            sendChangeTimezone: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_paypal_change_timezone",
                    eventProperties
                );
            },
        },
        // Invoices tab
        invoices: {
            // acc_settings_invoices_change_grouping
            sendChangeGrouping: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_invoices_change_grouping",
                    eventProperties
                );
            },
            // acc_settings_invoices_save
            sendSave: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_invoices_save",
                    eventProperties
                );
            },
        },
        automation: {
            // acc_settings_automation_enable_auto_posting
            sendEnableAutoPosting: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_auto_enable_auto_posting",
                    eventProperties
                );
            },
            // acc_settings_automation_disable_auto_posting
            sendDisableAutoPosting: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_auto_disable_auto_posting",
                    eventProperties
                );
            },
            sendEnableAutoMapping: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_auto_enable_auto_mapping",
                    eventProperties
                );
            },
            sendDisableAutoMapping: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_auto_disable_auto_mapping",
                    eventProperties
                );
            },
            // acc_settings_automation_cancel_modal_settings_change
            sendCancelModalSettingsChange: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_auto_cancel_settings_change",
                    eventProperties
                );
            },
            // acc_settings_automation_save
            sendSave: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_automation_save",
                    eventProperties
                );
            },
        },
        history: {
            // acc_settings_history_request
            sendRequest: (eventProperties?: EventProperties): void => {
                tracking.accountSettings.sendAccountSettingsEvent(
                    "acc_settings_history_request",
                    eventProperties
                );
            },
        },
    },
    singleOrderSettlements: {
        sendSingleOrderSettlementsEvent: (
            eventName: string,
            eventProperties?: Record<string, unknown>
        ): void => {
            console.log("", eventName, eventProperties);
            sendStandardEvent(eventName, {
                feature: FEATURES.SINGLE_ORDER_SETTLEMENTS,
                ...eventProperties,
            });
        },
        // all single order settlements are prefixed with sos_
        sendSingleReview: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_single_review",
                eventProperties
            );
        },
        sendOpenSingleReview: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_open_single_review",
                eventProperties
            );
        },
        sendSingleDateReview: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_single_date_review",
                eventProperties
            );
        },
        // sos_filter_datepicker_open
        sendFilterDatepickerOpen: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_datepicker_open",
                eventProperties
            );
        },
        // sos_filter_month_select
        sendFilterMonthSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_month_select",
                eventProperties
            );
        },

        // sos_filter_date_select
        sendFilterDateSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_date_select",
                eventProperties
            );
        },
        // sos_filter_order_open
        sendFilterOrderOpen: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_order_open",
                eventProperties
            );
        },
        // sos_filter_order_select
        sendFilterOrderSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_order_select",
                eventProperties
            );
        },

        // sos_filter_order_clear
        sendFilterOrderClear: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_order_clear",
                eventProperties
            );
        },
        // sos_filter_customer_open
        sendFilterCustomerOpen: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_customer_open",
                eventProperties
            );
        },
        // sos_filter_customer_select
        sendFilterCustomerSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_customer_select",
                eventProperties
            );
        },
        sendFilterCustomerClear: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_customer_clear",
                eventProperties
            );
        },

        // sos_filter_clear_filters
        sendFilterClearFilters: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_clear_filters",
                eventProperties
            );
        },

        // sos_filter_apply_filters
        sendFilterApplyFilters: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_apply_filters",
                eventProperties
            );
        },

        // sos_filter_date_clear
        sendFilterDateClear: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_date_clear",
                eventProperties
            );
        },
        // sos_filter_payout_total_clear
        sendFilterTotalClear: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_payout_total_clear",
                eventProperties
            );
        },
        // sos_filter_posting_status_open
        sendFilterPostingStatusOpen: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_posting_status_open",
                eventProperties
            );
        },
        // sos_filter_posting_status_select
        sendFilterPostingStatusSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_posting_status_select",
                eventProperties
            );
        },
        // sos_filter_posting_status_clear
        sendFilterPostingStatusClear: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_posting_status_clear",
                eventProperties
            );
        },
        // sos_filter_payment_status_open
        sendFilterPaymentStatusOpen: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_payment_status_open",
                eventProperties
            );
        },
        // sos_filter_payment_status_select
        sendFilterPaymentStatusSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_payment_status_select",
                eventProperties
            );
        },
        // sos_filter_payment_status_clear
        sendFilterPaymentStatusClear: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_payment_status_clear",
                eventProperties
            );
        },
        // sos_filter_payout_total_open
        sendFilterTotalOpen: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_payout_total_open",
                eventProperties
            );
        },
        // sos_filter_payout_total_min_set
        sendFilterTotalMinSet: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_payout_total_min_set",
                eventProperties
            );
        },
        // sos_filter_payout_total_max_set
        sendFilterTotalMaxSet: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_payout_total_max_set",
                eventProperties
            );
        },
        // sos_filter_this_month_select
        sendFilterThisMonthSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_this_month_select",
                eventProperties
            );
        },
        // sos_filter_last_month_select
        sendFilterLastMonthSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_last_month_select",
                eventProperties
            );
        },

        // sos_filter_this_year_select
        sendFilterThisYearSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_this_year_select",
                eventProperties
            );
        },

        // sos_filter_last_year_select
        sendFilterLastYearSelect: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_last_year_select",
                eventProperties
            );
        },

        // sos_filter_date_sort
        sendFilterDateSort: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_date_sort",
                eventProperties
            );
        },
        // sos_filter_customer_sort
        sendFilterCustomerSort: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_filter_customer_sort",
                eventProperties
            );
        },
        // sos_pagination_per_page_change
        sendPaginationPerPageChange: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_pagination_per_page_change",
                eventProperties
            );
        },
        // sos_order_review_from_table
        sendOrderReviewFromTable: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_order_review_from_table",
                eventProperties
            );
        },
        // sos_single_repost
        sendSingleRepost: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_single_repost",
                eventProperties
            );
        },
        // sos_single_post
        sendSinglePost: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleOrderSettlements.sendSingleOrderSettlementsEvent(
                "sos_single_post",
                eventProperties
            );
        },
    },
    singleSettlement: {
        sendSingleSettlementEvent: (
            eventName: string,
            eventProperties?: Record<string, unknown>
        ): void => {
            console.log("", eventName, eventProperties);
            sendStandardEvent(eventName, {
                feature: FEATURES.SINGLE_SETTLEMENT,
                ...eventProperties,
            });
        },
        // all single order settlements are prefixed with "settlement_"
        // settlement_b2b_invoices_back
        sendB2BInvoicesBackSettlementPage: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleSettlement.sendSingleSettlementEvent(
                "settlement_b2b_invoices_back",
                eventProperties
            );
        },
        // settlement_download_raw_data
        sendDownloadRawData: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleSettlement.sendSingleSettlementEvent(
                "settlement_download_raw_data",
                eventProperties
            );
        },
        // settlement_b2b_invoices_tab_change
        sendB2BInvoicesTabChange: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleSettlement.sendSingleSettlementEvent(
                "settlement_b2b_invoices_tab_change",
                eventProperties
            );
        },
        // settlement_b2b_payouts_tab_change
        sendPayoutsTabChange: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleSettlement.sendSingleSettlementEvent(
                "settlement_payouts_tab_change",
                eventProperties
            );
        },
        // settlement_connect_accounting_system
        sendConnectAccountingSystem: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleSettlement.sendSingleSettlementEvent(
                "settlement_connect_accounting_system",
                eventProperties
            );
        },
        // settlement_send_to_accounting_system
        sendSendToAccountingSystem: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleSettlement.sendSingleSettlementEvent(
                "settlement_send_to_accounting_system",
                eventProperties
            );
        },
        // settlement_send_to_accounting_confirm
        sendSendToAccountingConfirm: (eventProperties?: Record<string, unknown>): void => {
            tracking.singleSettlement.sendSingleSettlementEvent(
                "settlement_send_to_accounting_confirm",
                eventProperties
            );
        },
    },
    spSettlements: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "sp_settlements"
         */
        sendSPSettlementsEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.SP_SETTLEMENTS,
                ...eventProperties,
            });
        },

        // sp_settlements_single_review
        sendSingleReview: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_review",
                eventProperties
            );
        },

        // sp_settlements_open_single_review
        sendOpenSingleReview: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_open_single_review",
                eventProperties
            );
        },

        // sp_settlements_single_export
        sendSingleExport: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_export",
                eventProperties
            );
        },

        // sp_settlements_single_refresh
        sendSingleRefresh: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_refresh",
                eventProperties
            );
        },

        // sp_settlements_single_export_cogs
        sendSingleExportCOGS: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_export_cogs",
                eventProperties
            );
        },

        // sp_settlements_single_delete
        sendSingleDelete: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_delete",
                eventProperties
            );
        },

        // sp_settlements_single_repost_cogs
        sendSingleRepostCOGS: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_repost_cogs",
                eventProperties
            );
        },

        // sp_settlements_single_post_cogs
        sendSinglePostCOGS: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_post_cogs",
                eventProperties
            );
        },

        // sp_settlements_single_repost
        sendSingleRepost: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_repost",
                eventProperties
            );
        },

        // sp_settlements_single_post
        sendSinglePost: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_post",
                eventProperties
            );
        },

        // sp_settlements_single_post_both
        sendSinglePostBoth: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_post_both",
                eventProperties
            );
        },

        // sp_settlements_single_date_review
        sendSingleDateReview: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_single_date_review",
                eventProperties
            );
        },

        // sp_settlements_filter_datepicker_open
        sendFilterDatepickerOpen: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_datepicker_open",
                eventProperties
            );
        },

        // sp_settlements_filter_month_select
        sendFilterMonthSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_month_select",
                eventProperties
            );
        },

        // sp_settlements_filter_date_select
        sendFilterDateSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_date_select",
                eventProperties
            );
        },

        // sp_settlements_filter_marketplace_open
        sendFilterMarketplaceOpen: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_marketplace_open",
                eventProperties
            );
        },

        // sp_settlements_filter_marketplace_open
        sendFilterCurrencyOpen: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_currency_open",
                eventProperties
            );
        },

        // sp_settlements_filter_gateway_open
        sendFilterGatewayOpen: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_gateway_open",
                eventProperties
            );
        },

        // sp_settlements_filter_total_open
        sendFilterTotalOpen: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_total_open",
                eventProperties
            );
        },

        // sp_settlements_filter_total_min_set
        sendFilterTotalMinSet: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_total_min_set",
                eventProperties
            );
        },

        // sp_settlements_filter_total_max_set
        sendFilterTotalMaxSet: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_total_max_set",
                eventProperties
            );
        },

        // sp_settlements_filter_cogs_total_min_set
        sendFilterCOGSTotalMinSet: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_cogs_total_min_set",
                eventProperties
            );
        },

        // sp_settlements_filter_cogs_total_max_set
        sendFilterCOGSTotalMaxSet: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_cogs_total_max_set",
                eventProperties
            );
        },

        // sp_settlements_filter_status_select
        sendFilterStatusSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_status_select",
                eventProperties
            );
        },

        // sp_settlements_filter_cogs_status_select
        sendFilterCOGSStatusSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_cogs_status_select",
                eventProperties
            );
        },

        // sp_settlements_filter_cogs_total_open
        sendFilterCOGSTotalOpen: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_cogs_total_open",
                eventProperties
            );
        },

        // sp_settlements_filter_cogs_status_open
        sendFilterCOGSStatusOpen: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_cogs_status_open",
                eventProperties
            );
        },

        // sp_settlements_filter_status_open
        sendFilterStatusOpen: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_status_open",
                eventProperties
            );
        },

        // sp_settlements_bulk_refresh
        sendBulkRefresh: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_bulk_refresh",
                eventProperties
            );
        },

        // sp_settlements_bulk_refresh_cogs
        sendBulkRefreshCOGS: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_bulk_refresh_cogs",
                eventProperties
            );
        },

        // sp_settlements_bulk_post
        sendBulkPost: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_bulk_post",
                eventProperties
            );
        },

        // sp_settlements_bulk_post_cogs
        sendBulkPostCOGS: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_bulk_post_cogs",
                eventProperties
            );
        },

        // sp_settlements_bulk_export
        sendBulkExport: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_bulk_export",
                eventProperties
            );
        },

        // sp_settlements_bulk_export
        sendBulkExportRawData: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_bulk_export_raw_data",
                eventProperties
            );
        },

        // sp_settlements_bulk_export_cogs
        sendBulkExportCOGS: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_bulk_export_cogs",
                eventProperties
            );
        },

        // sp_settlements_bulk_delete
        sendBulkDelete: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_bulk_delete",
                eventProperties
            );
        },

        // sp_settlements_link_how_to_post
        sendLinkHowToPost: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_link_how_to_post",
                eventProperties
            );
        },

        // sp_settlements_link_how_to_reconcile
        sendLinkHowToReconcile: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_link_how_to_reconcile",
                eventProperties
            );
        },

        // sp_settlements_overlay_finish_steps_click
        sendOverlayFinishStepsClick: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_overlay_finish_steps_click",
                eventProperties
            );
        },

        // sp_settlements_overlay_refresh_data_click
        sendOverlayRefreshDataClick: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_overlay_refresh_data_click",
                eventProperties
            );
        },

        // sp_settlements_pagination_per_page_change
        sendPaginationPerPageChange: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_pagination_per_page_change",
                eventProperties
            );
        },

        // sp_settlements_filter_clear_filters
        sendFilterClearFilters: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_clear_filters",
                eventProperties
            );
        },

        // sp_settlements_filter_apply_filters
        sendFilterApplyFilters: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_apply_filters",
                eventProperties
            );
        },

        // sp_settlements_filter_date_clear
        sendFilterDateClear: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_date_clear",
                eventProperties
            );
        },

        // sp_settlements_filter_marketplace_clear
        sendFilterMarketplaceClear: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_marketplace_clear",
                eventProperties
            );
        },

        // sp_settlements_filter_marketplace_select
        sendFilterMarketplaceSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_marketplace_select",
                eventProperties
            );
        },

        // sp_settlements_filter_marketplace_clear
        sendFilterCurrencyClear: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_currency_clear",
                eventProperties
            );
        },

        // sp_settlements_filter_marketplace_select
        sendFilterCurrencySelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_currency_select",
                eventProperties
            );
        },

        // sp_settlements_filter_gateway_select
        sendFilterGatewaySelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_gateway_select",
                eventProperties
            );
        },

        // sp_settlements_filter_gateway_clear
        sendFilterGatewayClear: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_gateway_clear",
                eventProperties
            );
        },

        // sp_settlements_filter_total_clear
        sendFilterTotalClear: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_total_clear",
                eventProperties
            );
        },

        // sp_settlements_filter_status_clear
        sendFilterStatusClear: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_status_clear",
                eventProperties
            );
        },

        // sp_settlements_filter_cogs_total_clear
        sendFilterCOGSTotalClear: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_cogs_total_clear",
                eventProperties
            );
        },

        // sp_settlements_filter_cogs_status_clear
        sendFilterCOGSStatusClear: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_cogs_status_clear",
                eventProperties
            );
        },

        // sp_settlements_filter_date_sort
        sendFilterDateSort: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_date_sort",
                eventProperties
            );
        },

        // sp_settlements_filter_total_sort
        sendFilterTotalSort: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_total_sort",
                eventProperties
            );
        },

        // sp_settlements_filter_cogs_total_sort
        sendFilterCOGSTotalSort: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_cogs_total_sort",
                eventProperties
            );
        },
        // sp_settlements_filter_this_month_select
        sendFilterThisMonthSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_this_month_select",
                eventProperties
            );
        },
        // sp_settlements_filter_last_month_select
        sendFilterLastMonthSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_last_month_select",
                eventProperties
            );
        },

        // sp_settlements_filter_this_year_select
        sendFilterThisYearSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_this_year_select",
                eventProperties
            );
        },

        // sp_settlements_filter_last_year_select
        sendFilterLastYearSelect: (eventProperties?: EventProperties): void => {
            tracking.spSettlements.sendSPSettlementsEvent(
                "sp_settlements_filter_last_year_select",
                eventProperties
            );
        },
    },
    mappings: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "mappings"
         */
        sendMappingsEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.MAPPINGS,
                ...eventProperties,
            });
        },
        // mappings_auto_mapping_modal_confirm
        sendAutoMappingModalConfirm: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent(
                "mappings_automapping_modal_confirm",
                eventProperties
            );
        },

        // mappings_refresh_cache_in_settings
        sendRefreshCacheInSettings: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent(
                "mappings_refresh_cache_in_settings",
                eventProperties
            );
        },

        // mappings_copy_start
        sendCopyStart: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent("mappings_copy_start", eventProperties);
        },

        // mappings_copy_complete
        sendCopyComplete: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent("mappings_copy_complete", eventProperties);
        },

        // mappings_save
        sendSave: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent("mappings_save", eventProperties);
        },

        // mappings_filter_clear
        sendFilterClear: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent("mappings_filter_clear", eventProperties);
        },

        // mappings_categories_filter
        sendCategoriesFilter: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent("mappings_categories_filter", eventProperties);
        },
        // mapping_rules_view_clicked
        sendMappingRulesViewClicked: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent("mapping_rules_view_clicked", eventProperties);
        },
        // mapping_rule_add_clicked
        sendMappingRuleAddClicked: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent("mapping_rule_add_clicked", eventProperties);
        },
        // no_new_mapping_rules_shown
        no_new_mapping_rules_shown: (eventProperties?: EventProperties): void => {
            tracking.mappings.sendMappingsEvent("no_new_mapping_rules_shown", eventProperties);
        },
    },
    sidebar: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "sidebar"
         */
        sendSidebarEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.SIDEBAR,
                ...eventProperties,
            });
        },

        // sidebar_switch_account
        sendSwitchAccount: (eventProperties?: EventProperties): void => {
            tracking.sidebar.sendSidebarEvent("sidebar_switch_account", eventProperties);
        },

        // sidebar_click_partner_overview
        sendClickPartnerOverview: (eventProperties?: EventProperties): void => {
            tracking.sidebar.sendSidebarEvent("sidebar_click_partner_overview", eventProperties);
        },

        // sidebar_click_pending_invites
        sendClickPendingInvites: (eventProperties?: EventProperties): void => {
            tracking.sidebar.sendSidebarEvent("sidebar_click_pending_invites", eventProperties);
        },

        // sidebar_click_teams
        sendClickTeams: (eventProperties?: EventProperties): void => {
            tracking.sidebar.sendSidebarEvent("sidebar_click_teams", eventProperties);
        },

        // sidebar_create_new_account
        sendCreateNewAccount: (eventProperties?: EventProperties): void => {
            tracking.sidebar.sendSidebarEvent("sidebar_create_new_account", eventProperties);
        },

        // sidebar_click_account_overview
        sendClickAccountOverview: (eventProperties?: EventProperties): void => {
            tracking.sidebar.sendSidebarEvent("sidebar_click_account_overview", eventProperties);
        },

        // sidebar_search_accounts
        sendSearchAccounts: (eventProperties?: EventProperties): void => {
            tracking.sidebar.sendSidebarEvent("sidebar_search_accounts", eventProperties);
        },
    },
    onboarding: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "onboarding"
         */

        sendOnboardingEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.ONBOARDING,
                ...eventProperties,
            });
        },

        // sales_channel_connect_manual
        sendSalesChannelConnectManual: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "sales_channel_connect_manual",
                eventProperties
            );
        },

        // sales_channel_connect_select_manual
        sendSalesChannelConnectSelectManual: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "sales_channel_connect_select_manual",
                eventProperties
            );
        },

        // sales_channel_connect_start
        sendSalesChannelConnectStart: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent("sales_channel_connect_start", eventProperties);
        },

        // sales_channel_connect_cancel
        sendSalesChannelConnectCancel: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "sales_channel_connect_cancel",
                eventProperties
            );
        },

        // sales_channel_connect_authenticate
        sendSalesChannelConnectAuthenticate: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "sales_channel_connect_authenticate",
                eventProperties
            );
        },

        // sales_channel_connect_select_authenticate
        sendSalesChannelConnectSelectAuthenticate: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "sales_channel_connect_select_authenticate",
                eventProperties
            );
        },

        // sales_channel_connect_select_request
        sendSalesChannelConnectSelectRequest: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "sales_channel_connect_select_request",
                eventProperties
            );
        },

        // sales_channel_connect_request
        sendSalesChannelConnectRequest: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "sales_channel_connect_request",
                eventProperties
            );
        },

        // accounting_system_connect_share_access
        sendAccountingSystemConnectShareAccess: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "accounting_system_connect_share_access",
                eventProperties
            );
        },

        // accounting_system_connect_cancel
        sendAccountingSystemConnectCancel: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "accounting_system_connect_cancel",
                eventProperties
            );
        },

        // accounting_system_connect_authenticate
        sendAccountingSystemConnectAuthenticate: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent(
                "accounting_system_connect_authenticate",
                eventProperties
            );
        },

        sendSaveAccountName: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent("save_account_name", eventProperties);
        },

        sendModalSaveAccountName: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent("modal_save_account_name", eventProperties);
        },

        sendModalSkipAccountName: (eventProperties?: EventProperties): void => {
            tracking.onboarding.sendOnboardingEvent("modal_skip_account_name", eventProperties);
        },
    },

    reconciliation: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "reconciliation"
         */
        sendReconciliationEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.RECONCILIATION,
                ...eventProperties,
            });
        },

        // reconciliation_report_delete
        sendReportDelete: (eventProperties?: EventProperties): void => {
            tracking.reconciliation.sendReconciliationEvent(
                "reconciliation_report_delete",
                eventProperties
            );
        },

        // reconciliation_report_reprocess
        sendReportReprocess: (eventProperties?: EventProperties): void => {
            tracking.reconciliation.sendReconciliationEvent(
                "reconciliation_report_reprocess",
                eventProperties
            );
        },

        // reconciliation_report_export_original
        sendReportExportOriginal: (eventProperties?: EventProperties): void => {
            tracking.reconciliation.sendReconciliationEvent(
                "reconciliation_report_export_original",
                eventProperties
            );
        },

        // reconciliation_report_export_differences
        sendReportExportDifferences: (eventProperties?: EventProperties): void => {
            tracking.reconciliation.sendReconciliationEvent(
                "reconciliation_report_export_differences",
                eventProperties
            );
        },

        // reconciliation_report_export_all
        sendReportExportAll: (eventProperties?: EventProperties): void => {
            tracking.reconciliation.sendReconciliationEvent(
                "reconciliation_report_export_all",
                eventProperties
            );
        },

        // reconciliation_report_sort
        sendReportSort: (eventProperties?: EventProperties): void => {
            tracking.reconciliation.sendReconciliationEvent(
                "reconciliation_report_sort",
                eventProperties
            );
        },

        // reconciliation_report_settlement_detail_fetch_failed
        sendReportSettlementDetailFetchFailed: (eventProperties?: EventProperties): void => {
            tracking.reconciliation.sendReconciliationEvent(
                "reconciliation_report_settlement_detail_fetch_failed",
                eventProperties
            );
        },

        // reconciliation_report_order_summary_fail
        sendReportOrderSummaryFail: (eventProperties?: EventProperties): void => {
            tracking.reconciliation.sendReconciliationEvent(
                "reconciliation_report_order_summary_fail",
                eventProperties
            );
        },
    },

    settlement: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "settlement"
         */
        sendSettlementEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.SETTLEMENT,
                ...eventProperties,
            });
        },

        // settlement_single_review
        sendSingleReview: (eventProperties?: EventProperties): void => {
            tracking.settlement.sendSettlementEvent("settlement_single_review", eventProperties);
        },

        // settlement_single_export_dropdown
        sendSingleExportDropdown: (eventProperties?: EventProperties): void => {
            tracking.settlement.sendSettlementEvent(
                "settlement_single_export_dropdown",
                eventProperties
            );
        },

        // settlement_single_send_both_dropdown
        sendSingleSendBothDropdown: (eventProperties?: EventProperties): void => {
            tracking.settlement.sendSettlementEvent(
                "settlement_single_send_both_dropdown",
                eventProperties
            );
        },

        // settlement_single_send_cogs_dropdown
        sendSingleSendCOGSDropdown: (eventProperties?: EventProperties): void => {
            tracking.settlement.sendSettlementEvent(
                "settlement_single_send_cogs_dropdown",
                eventProperties
            );
        },

        // settlement_single_delete_dropdown
        sendSingleDeleteDropdown: (eventProperties?: EventProperties): void => {
            tracking.settlement.sendSettlementEvent(
                "settlement_single_delete_dropdown",
                eventProperties
            );
        },

        // settlement_single_refresh_dropdown
        sendSingleRefreshDropdown: (eventProperties?: EventProperties): void => {
            tracking.settlement.sendSettlementEvent(
                "settlement_single_refresh_dropdown",
                eventProperties
            );
        },

        // settlement_pagination_next
        sendPaginationNext: (eventProperties?: EventProperties): void => {
            tracking.settlement.sendSettlementEvent("settlement_pagination_next", eventProperties);
        },

        // settlement_action_open
        sendActionOpen: (eventProperties?: EventProperties): void => {
            tracking.settlement.sendSettlementEvent("settlement_action_open", eventProperties);
        },
    },

    billing: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "billing"
         */
        sendBillingEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.BILLING,
                ...eventProperties,
            });
        },

        // subscription-start-banner-choose-plan
        sendSubscriptionStartBannerChoosePlan: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent(
                "subscription-start-banner-choose-plan",
                eventProperties
            );
        },

        // subscription-start-subscriptions-choose-plan
        sendSubscriptionStartSubscriptionsChoosePlan: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent(
                "subscription-start-subscriptions-choose-plan",
                eventProperties
            );
        },

        // subscription-start-overlay-upgrade-now
        sendSubscriptionStartOverlayUpgradeNow: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent(
                "subscription-start-overlay-upgrade-now",
                eventProperties
            );
        },

        // subscription-linking-link-accounts
        sendSubscriptionLinkingLinkAccounts: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent(
                "subscription-linking-link-accounts",
                eventProperties
            );
        },

        // subscription-unlinking-unlink-account
        sendSubscriptionUnlinkingUnlinkAccount: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent(
                "subscription-unlinking-unlink-account",
                eventProperties
            );
        },

        // subscription-unlinking-start
        sendSubscriptionUnlinkingStart: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent("subscription-unlinking-start", eventProperties);
        },

        // subscription-unlinking-complete
        sendSubscriptionUnlinkingComplete: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent("subscription-unlinking-complete", eventProperties);
        },

        // subscription-linking-save
        sendSubscriptionLinkingSave: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent("subscription-linking-save", eventProperties);
        },

        // subscription-linking-choose-child
        sendSubscriptionLinkingChooseChild: (eventProperties?: EventProperties): void => {
            tracking.billing.sendBillingEvent("subscription-linking-choose-child", eventProperties);
        },
    },

    export: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "export"
         */
        sendExportEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.EXPORT,
                ...eventProperties,
            });
        },

        // monthly_sku_qty
        sendMonthlySKUAndQty: (eventProperties?: EventProperties): void => {
            tracking.export.sendExportEvent("export_monthly_sku_qty", eventProperties);
        },

        // monthly_gl_type
        sendMonthlyGLAndType: (eventProperties?: EventProperties): void => {
            tracking.export.sendExportEvent("export_monthly_gl_type", eventProperties);
        },

        // monthly_gl_type_description
        sendMonthlyGLAndTypeAndDescription: (eventProperties?: EventProperties): void => {
            tracking.export.sendExportEvent("export_monthly_gl_type_description", eventProperties);
        },
    },

    navigation: {
        /**
         * @param eventName event name
         * @param eventProperties event properties
         * @returns void
         * @description Sends the event to GA4, with the feature property set to "navigation"
         */
        sendNavigationEvent: (eventName: string, eventProperties?: EventProperties): void => {
            sendStandardEvent(eventName, {
                feature: FEATURES.NAVIGATION,
                ...eventProperties,
            });
        },

        // whats_new_open_dropdown
        sendWhatsNewOpenDropdown: (eventProperties?: EventProperties): void => {
            tracking.navigation.sendNavigationEvent("whats_new_open_dropdown", eventProperties);
        },

        // whats_new_view_more
        sendWhatsNewViewMore: (eventProperties?: EventProperties): void => {
            tracking.navigation.sendNavigationEvent("whats_new_view_more", eventProperties);
        },

        // whats_new_post_clicked
        sendWhatsNewPostClicked: (eventProperties?: EventProperties): void => {
            tracking.navigation.sendNavigationEvent("whats_new_post_clicked", eventProperties);
        },
    },
};

export enum FACEBOOK_EVENTS {
    SALES_CONNECTION_SUCCEEDED = "SALES_CONNECTION_SUCCEEDED",
}

export const fbqTracking = {
    /**
     * @param eventName event name
     * @returns void
     * @description Sends the event to FBQ, using Facebook Pixel
     */
    trackCustom: (eventName: FACEBOOK_EVENTS): void => {
        window.fbq("trackCustom", eventName);
    },

    trackSalesConnectionSucceeded: (): void => {
        fbqTracking.trackCustom(FACEBOOK_EVENTS.SALES_CONNECTION_SUCCEEDED);
    },
};

export const linkedInTracking = {
    trackSalesConnectionSucceeded(): void {
        window.lintrk("track", { conversion_id: 18229924 });
    },
};
