<template>
    <b-badge :v-if="getText()" class="atx-badge" :class="getClass()" :variant="variant">
        <atx-icon
            width="12"
            height="12"
            v-if="getIcon()"
            :icon="getIcon()"
            :class="`${getIconClass()}`"
        />
        <span :class="getTextClass()">{{ getText() }}</span>
        <span v-if="extraText" class="bg-white ml-1 p-1" :class="`text-${extraTextVariant}`">
            {{ extraText }}
        </span>
    </b-badge>
</template>

<script lang="ts">
import Vue from "vue";
import AtxIcon from "@/components/media/AtxIcon/AtxIcon.vue";
import { AtxBadgePresets } from "./AtxBadge";

export default Vue.extend({
    components: { AtxIcon },
    data() {
        return {
            presets: {
                [AtxBadgePresets.BETA]: {
                    variant: "primary",
                    icon: "rocket-takeoff",
                    text: "Beta",
                    iconClass: "text-white",
                    class: "bg-gradient-blue",
                    textClass: "text-white ml-1",
                },
                [AtxBadgePresets.INTRO_OFFER]: {
                    variant: "warning",
                    icon: "unlock-fill",
                    text: "Intro offer",
                    iconClass: "text-dark",
                    class: "badge-intro-offer text-white",
                    textClass: "text-dark ml-1",
                },
            },
        };
    },
    methods: {
        getIcon: function () {
            if (this.preset) {
                return this.presets[this.preset].icon;
            }
            return this.icon;
        },
        getText: function () {
            if (this.preset) {
                return this.presets[this.preset].text;
            }
            return this.text;
        },
        getIconClass: function () {
            if (this.preset) {
                return this.presets[this.preset].iconClass;
            }
            return "";
        },
        getClass: function () {
            if (this.preset) {
                return this.presets[this.preset].class;
            }
            return "";
        },
        getTextClass: function () {
            if (this.preset) {
                return this.presets[this.preset].textClass;
            }
            return "";
        },
    },
    props: {
        // once you choose a preset, your text, icon, and variant will be ignored
        preset: {
            type: String as () => AtxBadgePresets,
            required: false,
            default: null,
        },
        text: {
            type: String,
            required: false,
            default: null,
        },
        extraText: {
            type: String as () => string | null,
            default: null,
        },
        icon: {
            // Icons can be found here https://bootstrap-vue.org/docs/icons#icons-1
            type: String as () => string | null,
            default: null,
        },
        variant: {
            type: String as () => string | null,
            default: "primary",
        },
        extraTextVariant: {
            type: String as () => string | null,
            default: "primary",
        },
    },
});
</script>

<style lang="scss">
.atx-badge {
    padding: 7px;
}
.badge-intro-offer {
    background: $atx-orange-2;
    display: inline-flex;
    align-items: center;
}
</style>
