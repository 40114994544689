import { Route, RouteConfig } from "vue-router";
import Vue from "vue";
import { REFERRER_MISSING_COOKIE, REFERRER_UNBLOCK_COOKIE, WHITELIST_PAGES } from "@/globals";

export function updateReferrer(
    referrer: string,
    initialReferrerCheckDone: boolean,
    fromPath: string
): string {
    if (initialReferrerCheckDone) {
        referrer = window.location.origin + fromPath;
    }
    return referrer;
}

export function refererMatches(from: Route, referrer: string): boolean {
    const host = window.location.host;

    if (!referrer) {
        const referrerForceUnblock = Vue.$cookies.get(REFERRER_UNBLOCK_COOKIE);
        if (referrerForceUnblock) {
            return true;
        } else {
            Vue.$cookies.set(REFERRER_MISSING_COOKIE, "1", 0, "/", undefined, true, "None");
            return false;
        }
    } else {
        /**
         * In case of our a2x.app in development with SSL we need to use https:// to match them up
         */
        const protocol = referrer.includes("a2x.app") ? "https://" : "http://";
        let expectedReferer = protocol + host;

        if (import.meta.env.VITE_APP_NODE_ENV != "development") {
            expectedReferer = "https://" + host;
        }

        if (
            referrer == expectedReferer || // exact match
            referrer.startsWith(expectedReferer + "/")
        ) {
            // prefix match
            return true;
        } else {
            Vue.$cookies.set(REFERRER_MISSING_COOKIE, "1", 0, "/", undefined, true, "None");
            return false;
        }
    }
}

/**
 * Quickly generate a map of route name and path in array of objects format for use as a list and throughout the project
 * @param route
 * @param generatedRouteMap
 */
export function generateMap(route: RouteConfig, generatedRouteMap: Record<string, any>): void {
    if (route.name) {
        generatedRouteMap[route.name] = {
            name: route.name,
            path: route.path,
        };
    }
    if (route.children) {
        route.children.map((child: RouteConfig) => {
            generateMap(child, generatedRouteMap);
        });
    }
}

/**
 * Example of how to use the generateMap function
 */
// const generatedRouteMap: any = {};
// const test = AdminPageRoutes.map((route) => {
//     generateMap(route, generatedRouteMap);
// });
// console.log(generatedRouteMap);

/**
 * Get routes from route node object from routeConstants
 * @param routeNode
 * @param prefixPath
 */
export function getRouteNameAndPath(
    routeNode: { name: string; path: string },
    prefixPath = false
): {
    name: string;
    path: string;
} {
    return {
        path: prefixPath ? `/${routeNode.path}` : routeNode.path,
        name: routeNode.name,
    };
}

export function requiresPermission(to: Route): boolean {
    let checkPermissions = true;
    // Most routes can be exactly matched with the hashmap
    if (WHITELIST_PAGES[to.path]) {
        checkPermissions = false;
    } else {
        // If the route is not in the hashmap, we need to check if it's a subpage of a whitelisted page
        Object.keys(WHITELIST_PAGES).forEach((page) => {
            if (to.path.includes(page)) {
                checkPermissions = false;
            }
        });
    }
    return checkPermissions;
}

export function redirectAfterDelay(url: string, delay: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            window.location.href = url;
            resolve();
        }, delay);
    });
}
