
<b-button-group class="position-static" :data-test-id="testId">
    <b-dropdown
        ref="atx-dropdown-select"
        variant="light"
        class="atx-dropdown mx-1"
        toggle-class="border-radius-4 px-2 py-1"
        menu-class="border-radius-12"
        :disabled="dropdownDisabled"
        boundary="window"
        no-caret
        @show="openSelect"
        @hide="closeSelect"
    >
        <template #button-content>
            <span class="dropdown-toggle-button-text">{{ getDropdownText() }}</span>
            <atx-icon icon="chevron-down" />
        </template>
        <atx-select
            class="px-2 atx-select"
            v-on="$listeners"
            v-bind="$attrs"
            transition=""
            @input="closeSelectOnInput"
            :dropdown-should-open="() => isSelectOpen"
            ><template v-slot:option="option">
                <template v-if="'label' in $attrs"> {{ option[$attrs.label] }}</template>
                <template v-else>
                    {{ option.label }}
                </template>
            </template>
        </atx-select>
    </b-dropdown>
</b-button-group>
