<template>
    <b-modal
        v-if="rulesMet"
        visible
        v-on="$listeners"
        v-bind="$attrs"
        :id="id"
        body-class="p-5"
        :size="size"
        :centered="centered"
        :save-cookie="saveCookie"
        :reset-cookie="resetCookie"
        hide-footer
        hide-header
        lazy
        static
        no-close-on-backdrop
        no-close-on-esc
    >
        <div class="atx-modal-content">
            <template v-if="icon !== '' || image !== '' || alertState !== ''">
                <template v-if="showBetaBadge && image">
                    <div class="mb-2 text-center">
                        <atx-badge :preset="AtxBadgePresets.BETA" margin-class="m-0" />
                    </div>
                    <atx-icon
                        icon="star-fill"
                        class="text-atx-orange-2 position-absolute"
                        rotate="-15"
                        scale="1.45"
                        style="top: 26%; left: 19%"
                    />
                    <atx-icon
                        icon="star-fill"
                        class="text-atx-orange-2 position-absolute"
                        rotate="15"
                        scale="2.25"
                        style="top: 8%; right: 19%"
                    />
                    <atx-icon
                        icon="star-fill"
                        class="text-atx-orange-2 position-absolute"
                        rotate="-15"
                        scale="1.25"
                        style="top: 20%; right: 20%"
                    />
                </template>

                <div class="image-wrap d-flex justify-content-center">
                    <!-- Use either icon or image, icon will take precedence over image -->
                    <atx-icon
                        v-if="icon !== '' || alertState !== ''"
                        :icon="alertState !== '' ? alertStateIcon : icon"
                        style="width: 38px; height: 38px"
                        :variant="alertState !== '' ? alertStateVariant : iconVariant"
                    />
                    <img
                        v-else-if="image !== ''"
                        :src="image"
                        alt="Announcement image"
                        width="284"
                        height="199"
                        class="img-fluid"
                    />
                </div>
            </template>
            <div class="content-wrap mt-4 mb-2 text-center">
                <template v-if="$scopedSlots.default">
                    <slot />
                </template>
                <template v-else>
                    <h3 class="dialog-title mb-2 font-weight-normal">{{ title }}</h3>
                    <p class="dialog-content">
                        {{ content }}
                    </p>
                </template>
                <p v-if="supportMessage" class="mt-3">
                    For more assistance please contact
                    <a href="mailto:support@a2xaccounting.com">support</a>.
                </p>
            </div>
        </div>
        <div v-if="!stacked" class="content-buttons d-flex justify-content-center">
            <atx-button v-if="!hideOkButton" :variant="buttonVariantOkClass" @click="ok">{{
                buttonOkTitle
            }}</atx-button>
            <atx-button
                v-if="showCancelButton"
                class="ml-2"
                :variant="buttonVariantCancelClass"
                @click="cancel"
                >{{ buttonCancelTitle }}</atx-button
            >
        </div>
        <div v-else class="content-buttons text-center">
            <div>
                <atx-button v-if="!hideOkButton" :variant="buttonVariantOkClass" @click="ok">
                    {{ buttonOkTitle }}
                </atx-button>
            </div>
            <div>
                <atx-button-link v-if="showCancelButton" class="mt-3" @click="cancel">
                    {{ buttonCancelTitle }}
                </atx-button-link>
            </div>
        </div>
    </b-modal>
</template>
<script lang="ts">
import Vue from "vue";
import { AtxBadgePresets } from "../AtxBadge/AtxBadge";

export default Vue.extend({
    data() {
        return {
            AtxBadgePresets,
        };
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        image: {
            type: String,
            default: "",
        },
        icon: {
            type: String,
            default: "",
        },
        iconVariant: {
            type: String,
            default: "",
        },
        title: {
            type: String,
            default: "Default modal dialog title",
        },
        content: {
            type: String,
            default:
                "You can write any content you want just below the title. Make sure that the message is clear and concise.",
        },
        buttonOkTitle: {
            type: String,
            default: "Okay",
        },
        hideOkButton: {
            type: Boolean,
            default: false,
        },
        showCancelButton: {
            type: Boolean,
            default: false,
        },
        buttonCancelTitle: {
            type: String,
            default: "Cancel",
        },
        size: {
            type: String,
            default: "md",
        },
        centered: {
            type: Boolean,
            default: true,
        },
        saveCookie: {
            type: Boolean,
            default: false,
        },
        cookieExpiration: {
            type: String,
            default: "1y",
        },
        resetCookie: {
            type: Boolean,
            default: false,
        },
        /**
         * AlertState takes precedence over icon and image
         * If alertState, icon and image are passed then icon/image will be ignored
         */
        alertState: {
            type: String as () => "success" | "warning" | "error",
            validator: (value: string) => {
                return ["success", "warning", "error", ""].includes(value);
            },
            default: "",
        },
        /**
         * Modal dialog is different from modal in terms that is being triggered by certain actions
         * It is not designed to be triggered by a button click, as for that one we use regular AtxModal.
         * This is triggered either by cookie or an alert (warning, error, success) passed from the application to this component
         * Unlike atxModal this one cannot be dismissed in any way other than clicking the OK button
         */
        triggerBy: {
            type: String as () => "cookie" | "alert",
            validator: (value: string) => {
                return ["cookie", "alert"].includes(value);
            },
            default: "cookie",
        },
        supportMessage: {
            type: Boolean,
            default: false,
        },
        buttonVariant: {
            type: String as () => "regular" | "announcement",
            validator: (value: string) => {
                return ["regular", "announcement", ""].includes(value);
            },
            default: "regular",
        },
        /**
         * If showBetaBadge is true then show the beta badge above the image and stars around it
         */
        showBetaBadge: {
            type: Boolean,
            default: false,
        },
        /**
         * If stacked is true then the buttons will be stacked on top of each other
         */
        stacked: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        resetCookie: {
            handler(newVal) {
                if (newVal) {
                    this.$cookies.remove(this.id);
                }
            },
            immediate: true,
        },
    },
    methods: {
        ok() {
            this.$emit("ok", true);
            this.$bvModal.hide(this.id);

            /**
             * If saveCookie is true then set a cookie for 1 year and name it after the passed ID of the modal-dialog so the customer won't see it again
             * We set a default cookie expiration to 1 year
             */
            if (this.saveCookie) {
                this.$cookies.set(this.id, "true", this.cookieExpiration);
            }
        },
        cancel() {
            this.$emit("cancel", true);
            this.$bvModal.hide(this.id);
        },
    },
    computed: {
        cookieExist(): boolean {
            return this.$cookies.get(this.id);
        },
        rulesMet(): boolean {
            switch (this.triggerBy) {
                case "cookie":
                    return !this.cookieExist;
                case "alert":
                    return true;
                default:
                    return false;
            }
        },
        alertStateVariant(): string {
            switch (this.alertState) {
                case "success":
                    return "atx-green-2";
                case "warning":
                    return "atx-orange-2";
                case "error":
                    return "atx-red-2";
                default:
                    return "";
            }
        },
        alertStateIcon(): string {
            switch (this.alertState) {
                case "success":
                    return "check-circle-fill";
                case "warning":
                    return "exclamation-circle-fill";
                case "error":
                    return "x-circle-fill";
                default:
                    return "";
            }
        },
        buttonVariantOkClass(): string {
            switch (this.buttonVariant) {
                case "regular":
                    return "atx-blue-3";
                case "announcement":
                    return "atx-orange-2 text-atx-blue-3";
                default:
                    return "";
            }
        },
        buttonVariantCancelClass(): string {
            return "outline-atx-blue-3";
        },
    },
});
</script>
