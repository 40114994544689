
<b-row
    class="atx-heading align-items-center"
    v-if="heading !== '' || Object.keys($scopedSlots).length > 0"
>
    <b-col>
        <h1
            v-if="loaded"
            :class="{
                h4: true,
                'mb-0': description === '',
                'mb-3': description !== '',
                headingClass,
                'd-flex align-items-center': centered,
            }"
        >
            <template v-if="$scopedSlots.default">
                <slot />
            </template>
            <template v-else>
                {{ pageHeading }}
                <atx-badge class="ml-2" :preset="AtxBadgePresets.BETA" v-if="isBetaFeature" />
            </template>
        </h1>
        <b-skeleton
            v-else
            width="250px"
            height="32px"
            :class="{
                'mb-0': description === '',
                'mb-3': description !== '',
            }"
        />
        <template v-if="description !== ''">
            <p v-if="loaded">{{ description }}</p>
            <b-skeleton v-else width="400px" height="16px" class="mb-2" />
        </template>
    </b-col>

    <template v-if="$scopedSlots['heading-right-col']">
        <b-col cols="auto"><slot name="heading-right-col"></slot></b-col
    ></template>
</b-row>
