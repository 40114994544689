
<b-container id="navigation" fluid="xl" class="py-1">
    <b-row>
        <b-col>
            <b-navbar class="pl-0 pr-0" toggleable="lg">
                <b-button
                    v-b-toggle.a2x-sidebar-nav
                    class="d-flex btn-sidebar-nav-toggle d-xxl-none mr-4 border-radius-4 p-0 bg-transparent border-0"
                    ><div class="position-relative">
                        <atx-icon
                            icon="layout-sidebar"
                            style="width: 24px; height: 24px"
                        /><atx-icon
                            icon="arrow-right-short"
                            class="position-absolute arrow-absolute"
                            style="width: 19px; height: 19px"
                        /></div
                ></b-button>
                <b-navbar-brand href="/">
                    <img
                        :src="getA2XLogo()"
                        class="d-inline-block"
                        alt="A2X Logo"
                        width="80"
                        height="22"
                    />
                </b-navbar-brand>

                <b-navbar-toggle target="nav-collapse" class="order-3"></b-navbar-toggle>

                <b-navbar
                    class="p-0 ml-auto justify-content-end align-self-end order-lg-1 nav-secondary"
                >
                    <b-navbar-nav align="right" class="align-items-center">
                        <atx-navbar-whats-new-dropdown
                            :data="whatsNewData"
                            @dropdown-opened="$emit('whats-new-dropdown-opened')"
                            @post-clicked="$emit('whats-new-post-clicked')"
                            @view-more-clicked="$emit('whats-new-view-more-clicked')"
                        />

                        <b-nav-item
                            :to="USER_SETTINGS_PAGE_ROUTES.NOTIFICATIONS.path"
                            class="d-none d-md-block"
                            :active="
                                setCurrentRouteAsActive(USER_SETTINGS_PAGE_ROUTES.NOTIFICATIONS)
                            "
                        >
                            <atx-icon icon="bell-fill" class="rounded" />
                        </b-nav-item>
                        <b-nav-item
                            :href="SUPPORT_LINKS.SUPPORT.path"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="d-none d-md-block"
                        >
                            <atx-icon
                                font-scale="1"
                                icon="question-circle-fill"
                                aria-label="Help"
                                class="mr-1"
                            />
                        </b-nav-item>

                        <atx-navbar-settings-dropdown
                            :settings-menu="settingsMenu"
                            :current-route="currentRoute.fullPath"
                        ></atx-navbar-settings-dropdown>

                        <b-nav-item
                            v-if="Object.keys(currentAccount).length > 0"
                            v-b-toggle.a2x-sidebar-nav
                            href="#"
                            class="text-white"
                            link-classes="text-white border-radius-12 align-items-center ellipsis-text-35"
                        >
                            <b-img
                                class="border-radius-4"
                                width="24px"
                                height="24px"
                                :src="
                                    getSalesChannelBadgeIcon(
                                        currentAccount.salesChannel,
                                        currentAccount.isCurrent
                                    )
                                "
                            />
                            <span class="mx-1 nav-item-account-name align-middle">{{
                                currentAccount.name
                            }}</span>
                            <span
                                v-if="!currentAccount.hasOwnProperty('planCode')"
                                :class="{
                                    'badge badge-atx-orange-2': true,
                                }"
                                >Trial</span
                            >
                        </b-nav-item>

                        <atx-navbar-user-dropdown
                            :user-email="userEmail"
                            :enabling-party-icon="enablingPartyIcon"
                            :stripe-enabled="stripeEnabled"
                        ></atx-navbar-user-dropdown>
                    </b-navbar-nav>
                </b-navbar>

                <b-collapse id="nav-collapse" class="order-lg-0 order-3" is-nav>
                    <b-navbar-nav>
                        <template v-for="menuItem in mainMenu">
                            <hr
                                :key="menuItem.name + 'hr'"
                                v-if="menuItem.topDivider"
                                class="nav-item-divider"
                            />
                            <b-nav-item
                                v-if="!menuItem.subMenu"
                                :href="menuItem.path"
                                :key="menuItem.name"
                                :data-testid="menuItem.name"
                                :class="menuItem.classes"
                                :active="setCurrentRouteAsActive(menuItem)"
                                active-class="active"
                            >
                                <template v-if="menuItem.type && menuItem.type == 'pill'">
                                    <span class="label-new bg-gradient-orange">
                                        <i
                                            v-if="menuItem.icon && menuItem.icon !== ''"
                                            :class="menuItem.icon"
                                            aria-hidden="true"
                                        ></i>
                                        {{ menuItem.name }}</span
                                    >
                                </template>
                                <template v-else>
                                    <b-icon
                                        v-if="menuItem.icon && menuItem.icon !== ''"
                                        :icon="menuItem.icon"
                                    ></b-icon>
                                    {{ menuItem.name }}
                                </template>
                            </b-nav-item>
                            <b-nav-item-dropdown
                                v-else
                                :text="menuItem.name"
                                :key="menuItem.name"
                                :toggle-class="{
                                    active: setCurrentRouteAsActive(menuItem),
                                }"
                            >
                                <b-dropdown-item
                                    :href="subMenuItem.path"
                                    v-for="subMenuItem in menuItem.subMenu"
                                    :key="subMenuItem.name"
                                >
                                    {{ subMenuItem.name }}
                                    <atx-badge
                                        :preset="AtxBadgePresets.BETA"
                                        v-if="subMenuItem.isBetaFeature"
                                    />
                                    <atx-badge
                                        :preset="AtxBadgePresets.INTRO_OFFER"
                                        v-if="subMenuItem.isIntroOffer"
                                    />
                                </b-dropdown-item>
                            </b-nav-item-dropdown>
                        </template>
                    </b-navbar-nav>
                </b-collapse>
            </b-navbar>
        </b-col>
    </b-row>
</b-container>
