
<footer class="bg-atx-blue-3 text-light border-top p-3 mt-auto">
    <b-container fluid="xl">
        <b-row>
            <b-col sm>
                <strong>A2X</strong> &copy; 2014 &ndash; {{ currentYear }} A2X Limited.
                <small>
                    {{ printAppVersionLabel }}
                </small>
            </b-col>
            <b-col sm class="text-right">
                <a
                    :href="SUPPORT_LINKS.SUPPORT.path"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="text-white"
                    >Need help?</a
                >
            </b-col>
        </b-row>
    </b-container>
</footer>
