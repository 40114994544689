
<div>
    <b-sidebar
        id="a2x-sidebar-nav"
        ref="a2x-sidebar-nav"
        title="Sidebar"
        backdrop-variant="atx-blue-3"
        bg-variant="atx-blue-4"
        text-variant="white"
        shadow="lg"
        v-model="sidebarVisible"
        :backdrop="isSmallDesktop || isTablet || isMobile"
        :sidebar-class="{
            'a2x-sidebar-nav': true,
            opened: sidebarVisible,
            closed: !sidebarVisible,
        }"
        no-close-on-route-change
        no-header
        @mouseover.native="maximizeSidebar"
        @mouseleave.native="minimizeSidebar"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <div class="py-4 px-3">
            <ul class="p-0 m-0 list-unstyled">
                <li>
                    <b-form-checkbox
                        class="float-right"
                        v-model="sidebarStaticCheckbox"
                        name="check-button"
                        switch
                        v-if="sidebarVisible"
                    >
                    </b-form-checkbox>
                </li>
            </ul>
            <slot name="sidebar-header"></slot>
            <slot name="sidebar-content-before"></slot>
            <slot name="sidebar-content"></slot>
        </div>
        <slot name="sidebar-footer"></slot>
    </b-sidebar>
</div>
