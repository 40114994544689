import { Alert } from "@/components/models/Alert";
import { AMAZON_PAGE_ROUTES, MAPPING_PAGE_ROUTES } from "@/router/routeConstants";
import { defineStore } from "pinia";

export const useAlertStore = defineStore({
    id: "alert",
    state: () => ({
        global: {},
        page: {
            [MAPPING_PAGE_ROUTES.APP_MAPPINGS.name]: {
                cleanupScheduled: {
                    type: "warning",
                    message: "",
                    show: false,
                },
            },
            [AMAZON_PAGE_ROUTES.DATE_RANGE_SUMMARY_REPORT.name]: {
                potentialMissingSettlements: {
                    type: "warning",
                    message: "",
                    show: false,
                    icon: "exclamation-circle-fill",
                    iconVariant: "atx-orange-3",
                    dismissible: true,
                },
            },
        } as Record<string, Record<string, Alert>>,
    }),
});
