<template>
    <b-container id="navigation" :fluid="headerContainerFluid" class="py-1">
        <b-row>
            <b-col>
                <b-navbar class="pl-0 pr-0" toggleable="lg">
                    <b-button
                        v-if="sidebarNavigationFeatureActive"
                        v-b-toggle.a2x-sidebar-nav
                        class="d-flex btn-sidebar-nav-toggle d-xxl-none mr-4 border-radius-4 p-0 bg-transparent border-0"
                        ><div class="position-relative">
                            <atx-icon
                                icon="layout-sidebar"
                                style="width: 24px; height: 24px"
                            /><atx-icon
                                icon="arrow-right-short"
                                class="position-absolute arrow-absolute"
                                style="width: 19px; height: 19px"
                            /></div
                    ></b-button>
                    <b-navbar-brand v-if="showLogo" href="/">
                        <img
                            :src="getA2XLogo()"
                            class="d-inline-block"
                            alt="A2X Logo"
                            width="80"
                            height="22"
                        />
                    </b-navbar-brand>

                    <b-navbar-toggle target="nav-collapse" class="order-3"></b-navbar-toggle>

                    <b-navbar
                        class="p-0 ml-auto justify-content-end align-self-end order-lg-1 nav-secondary"
                    >
                        <b-navbar-nav align="right" class="align-items-center">
                            <b-nav-item
                                :href="WEBSITE_LINKS.WHATS_NEW.path"
                                class="d-none d-md-block"
                                ><span class="label-new bg-gradient-orange"
                                    ><atx-icon icon="gift" /> NEW</span
                                ></b-nav-item
                            >
                            <b-nav-item
                                :to="USER_SETTINGS_PAGE_ROUTES.NOTIFICATIONS.path"
                                class="d-none d-md-block"
                            >
                                <b-icon icon="bell-fill" class="rounded"></b-icon>
                            </b-nav-item>
                            <b-nav-item
                                :href="SUPPORT_LINKS.SUPPORT.path"
                                target="_blank"
                                rel="noopener noreferrer"
                                class="d-none d-md-block"
                            >
                                <b-icon
                                    font-scale="1"
                                    icon="question-circle-fill"
                                    aria-label="Help"
                                    class="mr-1"
                                ></b-icon>
                            </b-nav-item>
                            <b-nav-item
                                :href="PARTNER_PAGE_ROUTES.PARTNER.path"
                                class="d-none d-md-block"
                            >
                                Partner overview
                            </b-nav-item>
                            <b-nav-item
                                :href="PARTNER_PAGE_ROUTES.TEAMS.path"
                                class="d-none d-md-block"
                                >Teams</b-nav-item
                            >

                            <atx-navbar-user-dropdown
                                :user-email="userEmail"
                                :enabling-party-icon="enablingPartyIcon"
                                :stripe-enabled="stripeEnabled"
                            ></atx-navbar-user-dropdown>
                        </b-navbar-nav>
                    </b-navbar>
                </b-navbar>
            </b-col>
        </b-row>
    </b-container>
</template>

<script lang="ts">
import Vue from "vue";
import AtxNavbarUserDropdown from "../../navigation/AtxNavbarMain/AtxNavbarUserDropdown.vue";
import { IMenuItem, IMenu, ISettingsMenuItem } from "@/components/models/IMenu";
import { getA2XLogo } from "@/helpers";
import { IAccountItem } from "@/models";
import { mapStores } from "pinia";
import { useAuthStore } from "@/stores";
import {
    WEBSITE_LINKS,
    USER_SETTINGS_PAGE_ROUTES,
    SUPPORT_LINKS,
    PARTNER_PAGE_ROUTES,
    AMAZON_PAGE_ROUTES,
    SETTLEMENT_PAGE_ROUTES,
} from "@/router/routeConstants";

export default Vue.extend({
    components: {
        AtxNavbarUserDropdown,
    },
    props: {
        showLogo: {
            type: Boolean,
            default: true,
        },
        headerContainerFluid: {
            type: String,
            default: "xl",
        },
        menu: {
            type: String,
            default: null,
        },
        userEmail: {
            type: String,
            default: null,
        },
        enablingPartyIcon: {
            type: String,
            default: null,
        },
        userId: {
            type: String,
            default: null,
        },
        showAdvancedFba: {
            type: Boolean,
            default: false,
        },
        showNetsuiteConfiguration: {
            type: Boolean,
            default: false,
        },
        stripeEnabled: {
            type: Boolean,
            default: false,
        },
        sidebarNavigationFeatureActive: {
            type: Boolean,
            default: false,
        },
        internalCompanyEmail: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menuObj: {} as IMenu,
            mainMenu: [] as Array<IMenuItem>,
            settingsMenu: [] as Array<ISettingsMenuItem>,
            WEBSITE_LINKS: WEBSITE_LINKS,
            USER_SETTINGS_PAGE_ROUTES: USER_SETTINGS_PAGE_ROUTES,
            SUPPORT_LINKS: SUPPORT_LINKS,
            PARTNER_PAGE_ROUTES: PARTNER_PAGE_ROUTES,
            AMAZON_PAGE_ROUTES: AMAZON_PAGE_ROUTES,
            SETTLEMENT_PAGE_ROUTES: SETTLEMENT_PAGE_ROUTES,
        };
    },
    methods: {
        getA2XLogo: getA2XLogo,
        maybeAppendLink(
            menu: Array<IMenuItem>,
            link: IMenuItem,
            enabled: boolean
        ): Array<IMenuItem> {
            if (enabled) {
                menu.push(link);
            }
            return menu;
        },
    },
    computed: {
        ...mapStores(useAuthStore),
        currentAccount(): IAccountItem {
            return (
                this.authStore.accountListData.accounts.find((el) => el.isCurrent) ??
                ({} as IAccountItem)
            );
        },
    },
    created() {
        this.menuObj = JSON.parse(this.menu);
        this.mainMenu = this.menuObj.mainMenu;
        this.settingsMenu = this.menuObj.settingsMenu;
    },
});
</script>

<style lang="scss">
@media screen and (max-width: 500px) {
    .nav-secondary {
        width: 100%;
        order: 3;
    }
}

.navbar-collapse li a.nav-link {
    padding: 10px;
}

.nav-item-divider {
    margin: 0;
    border-color: rgba(255, 255, 255, 0.2);
}
</style>
