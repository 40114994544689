import { Route } from "vue-router";

/**
 * Example of the implementation and how to use in the router
 * MetaTags can be used for creation of any kind of meta which will then be added to the DOM by the guard below
 * 
 * {
            path: "/",
            name: "Homepage",
            meta: {
                title: "App | A2X Accounting",
                metaTags: [
                    {
                        name: "description",
                        content: "The home page of our example app.",
                    },
                    {
                        property: "og:description",
                        content: "The home page of our example app.",
                    },
                ],
            },
            // beforeEnter: AdminGuard,
            component: () => import("@/pages/Homepage.vue")
 * 
 * 
 * @param to Route
 * @param from Route
 */

export const metaInfo = (to: Route, from: Route): void => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
    // `/nested`'s will be chosen.
    const nearestWithTitle = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.title);

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    const previousNearestWithMeta = from.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta.meta.title;
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) =>
        el.parentNode?.removeChild(el)
    );

    // If any meta tags prepare them here.
    if (nearestWithMeta) {
        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags
            .map((tagDef: Record<string, string>) => {
                const tag = document.createElement("meta");

                Object.keys(tagDef).forEach((key) => {
                    tag.setAttribute(key, tagDef[key]);
                });

                // We use this to track which meta tags we create so we don't interfere with other ones.
                tag.setAttribute("data-vue-router-controlled", "");

                return tag;
            })
            // Add the meta tags to the document head.
            .forEach((tag: HTMLMetaElement) => document.head.appendChild(tag));
    }
};
