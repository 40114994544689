
<b-form-group
    v-if="totalRows > 0"
    label="Per page"
    label-for="per-page-select"
    label-cols="auto"
    label-align-lg="right"
    label-size="sm"
>
    <b-form-select
        @change="changeHandler"
        id="per-page-select"
        v-model="localPerPage"
        :options="pageOptions"
        size="sm"
        class="size-60"
    ></b-form-select>
    &nbsp;<small>{{ paginationText }}</small>
</b-form-group>
