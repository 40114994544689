
<b-table :fields="fields" :items="tableItems">
    <template #head(data1)><b-skeleton variant="atx-grey-2" class="m-2" /></template>
    <template #head(data2)><b-skeleton variant="atx-grey-2" class="m-2" /></template>
    <template #head(data3)><b-skeleton variant="atx-grey-2" class="m-2" /></template>
    <template #head(data4)><b-skeleton variant="atx-grey-2" class="m-2" /></template>
    <template #head(data5)><b-skeleton variant="atx-grey-2" class="m-2" /></template>
    <template #head(data6)><b-skeleton variant="atx-grey-2" class="m-2" /></template>
    <template #head(data7)><b-skeleton variant="atx-grey-2" class="m-2" /></template>
    <template #head(data8)><b-skeleton variant="atx-grey-2" class="m-2" /></template>
    <template #head(data9)><b-skeleton variant="atx-grey-2" class="m-2" /></template>

    <template #cell(data1)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    <template #cell(data2)><b-skeleton variant="atx-orange-1" class="m-2" /></template>
    <template #cell(data3)><b-skeleton variant="atx-green-1" class="m-2" /></template>
    <template #cell(data4)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    <template #cell(data5)><b-skeleton variant="atx-orange-1" class="m-2" /></template>
    <template #cell(data6)><b-skeleton variant="atx-green-1" class="m-2" /></template>
    <template #cell(data7)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    <template #cell(data8)><b-skeleton variant="atx-orange-1" class="m-2" /></template>
    <template #cell(data9)><b-skeleton variant="atx-green-1" class="m-2" /></template>
</b-table>
