<template>
    <b-table
        :id="id"
        :small="small"
        :class="tableClass"
        :fields="fields"
        :items="settlementsEmptyTableData.data"
    >
        <template #head(date)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
        <template #cell(date)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
        <template #head(total)><b-skeleton variant="atx-orange-1" class="m-2" /></template>
        <template #cell(total)><b-skeleton variant="atx-orange-1" class="m-2" /></template>
        <template #head(cogsInvoicesTotal)
            ><b-skeleton variant="atx-green-1" class="m-2"
        /></template>
        <template #cell(cogsInvoicesTotal)
            ><b-skeleton variant="atx-green-1" class="m-2"
        /></template>
        <template #head(channel)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
        <template #cell(channel)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
        <template #head(gateway)><b-skeleton variant="atx-yellow-1" class="m-2" /></template>
        <template #cell(gateway)><b-skeleton variant="atx-yellow-1" class="m-2" /></template>
        <template #head(status)><b-skeleton variant="atx-green-1" class="m-2" /></template>
        <template #cell(status)><b-skeleton variant="atx-green-1" class="m-2" /></template>
        <template #head(actions)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
        <template #cell(actions)><b-skeleton variant="atx-blue-1" class="m-2" /></template>
    </b-table>
</template>
<script lang="ts">
import Vue from "vue";
import { settlementsEmptyTableData } from "@/data/mock/";
export default Vue.extend({
    props: {
        id: {
            type: String,
            default: "settlements-table-empty",
        },
        small: {
            type: Boolean,
            default: false,
        },
        tableClass: {
            type: String,
            default: "",
        },
        fields: {
            type: [],
            default: () => [
                {
                    key: "date",
                    label: "Date",
                    tdClass: "align-middle",
                    thClass: "size-150",
                    sortable: false,
                    visible: true,
                },
                {
                    key: "total",
                    label: "Settlements total",
                    tdClass: "align-middle text-right column-settlements",
                    thClass: "size-140",
                    sortable: false,
                    visible: true,
                },
                {
                    key: "channel",
                    label: "Marketplace",
                    tdClass: "align-middle",
                    thClass: "size-140",
                    sortable: false,
                    visible: true,
                },
                {
                    key: "status",
                    label: "Status",
                    tdClass: "align-middle",
                    sortable: false,
                    visible: true,
                },
                {
                    key: "actions",
                    label: "Actions",
                    tdClass: "align-middle td-actions",
                    thClass: "size-250",
                    visible: true,
                },
            ],
        },
    },
    data() {
        return {
            settlementsEmptyTableData: settlementsEmptyTableData,
        };
    },
});
</script>
<style scoped>
th {
    width: auto !important;
}
</style>
