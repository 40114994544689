import Vue from "vue";
import { VueConstructor } from "vue/types/umd";
import AdminSkeleton from "@/skeletons/pages/AdminSkeleton.vue";
import TabbedSkeleton from "@/skeletons/pages/TabbedSkeleton.vue";
import FullWidthSkeleton from "@/skeletons/pages/FullWidthSkeleton.vue";
import FullWidthSidebarLeftSkeleton from "@/skeletons/pages/FullWidthSidebarLeftSkeleton.vue";
import FullWidthSidebarRightSkeleton from "@/skeletons/pages/FullWidthSidebarRightSkeleton.vue";
import { PAGE_LAYOUTS, PAGE_SKELETON_LIST } from "@/globals";
import { PageListItem } from "@/models";

/**
 * ## Example:
 *
 * ### /admin
 *
 * This is one of the regular routes but it affects everything under /admin/*:
 * - User visits /admin, we get the current path and start the search through the PAGE_SKELETON_LIST.
 * - We find /admin reference and pick the layout from there. We continue the search
 * - Eventually we find /admin/sales-channel and we pick that layout which will override the previous one.
 * - We don't have any matches, we stop here and show the layout.
 *
 * ### /admin/sales-channel/{sales-channel-name}/connect
 *
 * This is one of the REGEX_ROUTES and it is there to target specific URLs so we can quickly apply the same layout to all of them
 * Once again search goes like this:
 * - User visits /admin/sales-channel/{sales-channel-name}/connect, we get the current path and start the search
 * - We find /admin reference and pick the layout from there. We continue the search
 * - We find /admin/sales-channel reference and pick the layout from there. We continue the search
 * - We find the actual REGEX_ROUTE and it is exact match which precedes the previous ones. We pick the layout and immediately stop there.
 * @returns
 */

export function setupPageSkeleton(): Vue {
    let pageObject:
        | PageListItem
        | {
              layout: string;
              layoutOptions: { backButton: boolean; pageHeader: boolean; mediumBlock: boolean };
          }
        | {
              layout: string;
              layoutOptions: {
                  pageHeader: boolean;
                  backButton?: undefined;
                  mediumBlock?: undefined;
              };
          };
    let layout: VueConstructor<Vue>;
    let currentPath = window.location.pathname;
    let pagePath = "";
    let pageFound = false;
    currentPath = currentPath.replace("/app", "");

    const pages = Object.keys(PAGE_SKELETON_LIST);
    for (const page of pages) {
        const regex = new RegExp(page);
        const match = currentPath.match(regex);
        if (match) {
            pageFound = true;
            pagePath = page;
            //if exact match take that one and stop the cycle
            if (match[0] === currentPath) {
                break;
            }
        }
    }

    if (pageFound) {
        const pageIndex = pages.indexOf(pagePath);
        pageObject = PAGE_SKELETON_LIST[pages[pageIndex]];
        const pageLayout = pageObject.layout;
        switch (pageLayout) {
            case PAGE_LAYOUTS.ADMIN:
                layout = AdminSkeleton;
                break;
            case PAGE_LAYOUTS.FULL_WIDTH:
                layout = FullWidthSkeleton;
                break;
            case PAGE_LAYOUTS.TABBED:
                layout = TabbedSkeleton;
                break;
            case PAGE_LAYOUTS.FULL_WIDTH_SIDEBAR_LEFT:
                layout = FullWidthSidebarLeftSkeleton;
                break;
            case PAGE_LAYOUTS.FULL_WIDTH_SIDEBAR_RIGHT:
                layout = FullWidthSidebarRightSkeleton;
                break;
            default:
                // Make default full width simple layout in case we are missing a page layout
                layout = FullWidthSkeleton;
                break;
        }
    } else {
        layout = FullWidthSkeleton;
    }

    return new Vue({
        render: (h) => h(layout, { props: pageObject?.layoutOptions }),
    });
}
