<template>
    <b-link
        :class="{
            'link-btn': true,
            'disabled text-atx-grey-3': disabled ? true : false,
            ...textColorClass,
        }"
        v-bind="$attrs"
        v-on="$listeners"
        :variant="variant === '' ? 'atx-blue-2' : variant"
        ><template v-if="isLoading">
            <b-spinner
                small
                :variant="variant === '' ? 'atx-blue-2' : variant"
                label="Loading..."
            ></b-spinner>
        </template>
        <template v-else>
            <template v-if="$scopedSlots.default">
                <span v-if="icon !== ''" class="d-inline-block align-middle"
                    ><atx-icon v-if="icon !== ''" :icon="icon" space-right /></span
                ><span :class="`d-inline-block ${textClasses}`"><slot /></span>
            </template>
            <template v-else> Default Text </template>
        </template>
    </b-link>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        isLoading: {
            type: Boolean,
            default: false,
            required: false,
        },
        variant: {
            type: String,
            default: "",
            required: false,
        },
        icon: {
            type: String,
            default: "",
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        textClasses: {
            type: String,
            default: "",
            required: false,
        },
    },
    computed: {
        textColorClass(): { [key: string]: boolean } {
            if (this.variant === "") return {};

            return { [`text-${this.variant}`]: true };
        },
    },
});
</script>

<style scoped></style>
