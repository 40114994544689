<template>
    <b-form-group
        :id="`${id}-form-group`"
        class="mb-0"
        :label="label"
        label-class="font-weight-bold"
        :label-for="`${id}-from`"
    >
        <b-input-group>
            <b-form-text class="align-self-center pr-2">From</b-form-text>
            <b-form-input
                :id="`${id}-from`"
                :aria-label="`From ${fromValue ? fromValue : ''}`"
                :value="fromValueComputed"
                type="number"
                @input="fromChanged($event)"
            ></b-form-input>
            <b-form-text class="p-2 align-self-center">up to</b-form-text>
            <b-form-input
                :id="`${id}-to`"
                :aria-label="`To ${toValue ? toValue : ''}`"
                :value="toValueComputed"
                type="number"
                @input="toChanged($event)"
            ></b-form-input>
        </b-input-group>
    </b-form-group>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: "",
            required: false,
        },
        fromValue: {
            type: Number,
            required: false,
        },
        toValue: {
            type: Number,
            required: false,
        },
        precision: {
            type: Number,
            default: 2,
            required: false,
        },
    },
    computed: {
        fromValueComputed() {
            return this.fromValue ? this.fromValue.toFixed(this.precision) : "";
        },
        toValueComputed() {
            return this.toValue ? this.toValue.toFixed(this.precision) : "";
        },
    },
    methods: {
        fromChanged(eventValue: string) {
            const value = eventValue ? Number(eventValue).toFixed(this.precision) : "";
            this.$emit("from-changed", value);
        },
        toChanged(eventValue: string) {
            const value = eventValue ? Number(eventValue).toFixed(this.precision) : "";
            this.$emit("to-changed", value);
        },
    },
});
</script>
