<template>
    <b-form-group
        :id="`${id}-form-group`"
        :label="label"
        label-class="font-weight-bold"
        :label-for="id"
    >
        <atx-select
            :id="id"
            class="bg-white"
            :value="value"
            :options="options"
            :searchable="searchable"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="changeHandler"
        ></atx-select>
    </b-form-group>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: String,
            required: false,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: "All",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    methods: {
        changeHandler(val: string) {
            this.$emit("filter-changed", val);
        },
    },
});
</script>
