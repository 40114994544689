export const getWhatsNewDataAsync = [
    {
        date: "2024-10-01T15:30:00Z",
        permalink: "https://www.a2xaccounting.com/blog/a2x-and-finale-integration-live-in-beta/",
        title: "A2X and Finale Integration Live in Beta",
    },
    {
        date: "2024-10-01T15:00:00Z",
        permalink: "https://www.a2xaccounting.com/blog/shopify-cost-sync-integration-in-beta/",
        title: "Shopify Cost Sync Integration – In Beta",
    },
    {
        date: "2024-10-01T14:45:00Z",
        permalink: "https://www.a2xaccounting.com/blog/monthly-exports-feature-in-beta/",
        title: "Monthly Exports Feature – In Beta",
    },
    {
        date: "2024-07-29T12:00:00Z",
        permalink: "https://www.a2xaccounting.com/blog/introducing-persistent-filters/",
        title: "Introducing Persistent Filters",
    },
    {
        date: "2024-07-15T00:00:00Z",
        permalink:
            "https://www.a2xaccounting.com/blog/the-new-and-improved-sales-grouping-tracking-settings-page/",
        title: "The New and Improved ‘Sales Grouping & Tracking’ Settings Page:",
    },
    {
        date: "2024-05-21T00:00:00Z",
        permalink:
            "https://www.a2xaccounting.com/blog/new-a2x-settlements-payouts-page-live-in-beta/",
        title: "New A2X Settlements/Payouts Page Live in Beta",
    },
    {
        date: "2024-05-01T09:00:00Z",
        permalink:
            "https://www.a2xaccounting.com/blog/refresh-cache-link-now-displayed-in-mappings-page/",
        title: "Refresh Cache Link Now Displayed in Mappings Page",
    },
    {
        date: "2024-04-10T09:10:00Z",
        permalink: "https://www.a2xaccounting.com/blog/shopify-gift-card-updates/",
        title: "Shopify Gift Card Updates",
    },
    {
        date: "2024-04-09T09:00:00Z",
        permalink: "https://www.a2xaccounting.com/blog/cost-history-increased/",
        title: "Cost History Increased",
    },
    {
        date: "2024-04-05T09:00:00Z",
        permalink:
            "https://www.a2xaccounting.com/blog/a2x-for-shopify-exclude-gateway-setting-now-works-with-currency-suffixes-5-april/",
        title: "A2X for Shopify: Exclude Gateway Setting Now Works with Currency Suffixes | 5 April",
    },
];
