import { PiniaCustomStateProperties } from "pinia";

const ACCESS_TOKEN = "access-token";
const ACCESS_TOKEN_EXPIRES_AT = "access-token-expires-at";
const REFRESH_TOKEN = "refresh-token";
const REFRESH_TOKEN_EXPIRES_AT = "refresh-token-expires-at";
const USER_ID = "userId";
const USER_EMAIL = "userEmail";
const ACCOUNT_KEY = "accountKey";
const REDIRECT_URL = "redirectUrl";
const WHATS_NEW_LATEST_DATE = "whatsNewLatestDate";
const LAST_LOGIN_PROVIDER = "lastLoginProvider";

export interface AuthTokens {
    accessToken: string;
    refreshToken: string;
    accessTokenExpiresAt: number;
    refreshTokenExpiresAt: number;
}

/**
 * Manage the how Access Tokens are being stored and retrieved from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/
const storageService = {
    getAccessToken(): string | null {
        return localStorage.getItem(ACCESS_TOKEN);
    },

    setAccessToken(accessToken: string): void {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
    },

    removeAccessToken(): void {
        localStorage.removeItem(ACCESS_TOKEN);
    },

    getAccessTokenExpiresAt(): string | null {
        return localStorage.getItem(ACCESS_TOKEN_EXPIRES_AT);
    },

    setAccessTokenExpiresAt(accessTokenExpiresAt: number): void {
        return localStorage.setItem(ACCESS_TOKEN_EXPIRES_AT, accessTokenExpiresAt.toString());
    },

    getRefreshTokenExpiresAt(): string | null {
        return localStorage.getItem(REFRESH_TOKEN_EXPIRES_AT);
    },

    setRefreshTokenExpiresAt(refreshTokenExpiresAt: number): void {
        return localStorage.setItem(REFRESH_TOKEN_EXPIRES_AT, refreshTokenExpiresAt.toString());
    },
    getRefreshToken(): string | null {
        return localStorage.getItem(REFRESH_TOKEN);
    },

    setRefreshToken(refreshToken: string): void {
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    },

    removeRefreshToken(): void {
        localStorage.removeItem(REFRESH_TOKEN);
    },

    getUserId(): string | null {
        return localStorage.getItem(USER_ID);
    },
    setUserId(userId: string): void {
        return localStorage.setItem(USER_ID, userId);
    },
    getUserEmail(): string | null {
        return localStorage.getItem(USER_EMAIL);
    },
    getAccountKey(): string | null {
        return localStorage.getItem(ACCOUNT_KEY);
    },
    setAccountKey(accountKey: string): void {
        return localStorage.setItem(ACCOUNT_KEY, accountKey);
    },
    setLastLoginProvider(provider: string): void {
        localStorage.setItem(LAST_LOGIN_PROVIDER, provider);
    },
    getLastLoginProvider(): string | null {
        return localStorage.getItem(LAST_LOGIN_PROVIDER);
    },
    setSettlementsFilterState(accountKey: string, filterState: PiniaCustomStateProperties): void {
        localStorage.setItem(`settlementsFilterState-${accountKey}`, JSON.stringify(filterState));
    },
    getSettlementsFilterState(accountKey: string): Record<string, unknown> | null {
        const filterState = localStorage.getItem(`settlementsFilterState-${accountKey}`);
        if (filterState) {
            const filterStateObject = JSON.parse(filterState);
            return filterStateObject;
        } else {
            return null;
        }
    },
    removeSettlementsFilterState(accountKey: string): void {
        localStorage.removeItem(`settlementsFilterState-${accountKey}`);
    },
    setSettlementsSingleOrderShopifyFilterState(
        accountKey: string,
        filterState: PiniaCustomStateProperties
    ): void {
        localStorage.setItem(
            `settlementsSingleOrderShopifyFilterState-${accountKey}`,
            JSON.stringify(filterState)
        );
    },
    getSettlementsSingleOrderShopifyFilterState(
        accountKey: string
    ): Record<string, unknown> | null {
        const filterState = localStorage.getItem(
            `settlementsSingleOrderShopifyFilterState-${accountKey}`
        );
        if (filterState) {
            const filterStateObject = JSON.parse(filterState);
            return filterStateObject;
        } else {
            return null;
        }
    },
    removeSettlementsSingleOrderShopifyFilterState(accountKey: string): void {
        localStorage.removeItem(`settlementsSingleOrderShopifyFilterState-${accountKey}`);
    },
    clear(): void {
        localStorage.clear();
    },
    getRedirectUrl(): string | null {
        return localStorage.getItem(REDIRECT_URL);
    },
    setRedirectUrl(redirectUrl: string): void {
        localStorage.setItem(REDIRECT_URL, redirectUrl);
    },

    setWhatsNewLatestDate(date: string): void {
        localStorage.setItem(WHATS_NEW_LATEST_DATE, date);
    },

    getWhatsNewLatestDate(): string | null {
        return localStorage.getItem(WHATS_NEW_LATEST_DATE);
    },

    isRefreshTokenExpired(): boolean {
        const refreshTokenExpiresAt = this.getRefreshTokenExpiresAt();
        if (refreshTokenExpiresAt) {
            return Date.now() > Number(refreshTokenExpiresAt);
        } else {
            return true;
        }
    },
    updateStorageWithAuthTokens(auth: AuthTokens): void {
        storageService.setAccessToken(auth.accessToken);
        storageService.setRefreshToken(auth.refreshToken);
        storageService.setAccessTokenExpiresAt(auth.accessTokenExpiresAt);
        storageService.setRefreshTokenExpiresAt(auth.refreshTokenExpiresAt);
    },
};

export { storageService };
