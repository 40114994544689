
<div class="bg-atx-blue-3">
    <atx-navbar-main
        :menu="menu"
        :current-account="currentAccount"
        :user-email="userEmail"
        :enabling-party-icon="enablingPartyIcon"
        :user-id="userId"
        :show-advanced-fba="showAdvancedFba"
        :show-gift-cards="showGiftCards"
        :stripe-enabled="stripeEnabled"
        :whats-new-data="whatsNewData"
        @whats-new-dropdown-opened="handleWhatsNewDropdownOpen"
        @whats-new-view-more-clicked="handleWhatsNewViewMoreClicked"
        @whats-new-post-clicked="handleWhatsNewPostClicked"
    >
    </atx-navbar-main>
</div>
