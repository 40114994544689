import Vue from "vue";
import VueRouter from "vue-router";
import { getPageTitle } from "@/helpers";

Vue.use(VueRouter);

const publicRouter = new VueRouter({
    mode: "history",
    base: import.meta.env.BASE_URL,
    routes: [
        {
            path: "/oauth/firebase",
            meta: {
                title: getPageTitle("OAuth Firebase"),
            },
            component: () =>
                import(/* webpackChunkName: "Firebase" */ "@/pages/public/Firebase.vue"),
        },
        {
            path: "/oauth/user-management",
            meta: {
                title: getPageTitle("OAuth"),
            },
            component: () =>
                import(/* webpackChunkName: "Oauth" */ "@/pages/public/UserManagement.vue"),
        },
        {
            path: "/oauth/:provider",
            meta: {
                title: getPageTitle("OAuth"),
            },
            component: () => import(/* webpackChunkName: "Oauth" */ "@/pages/public/Oauth.vue"),
        },
    ],
});

export default publicRouter;
