/* eslint-disable @typescript-eslint/no-explicit-any */
import { storageService } from "@/services/storage.service";
import { watch } from "@vue/composition-api";
import { defineStore } from "pinia";

export const useFilterStore = defineStore({
    id: "filter",
    state: () => ({
        filtersCount: {
            date: 0,
            currency: 0,
            marketplace: 0,
            payoutGateway: 0,
            payoutTotal: 0,
            cogsTotal: 0,
            cogsStatus: 0,
            settlementStatus: 0,
            total: 0,
        } as Record<string, number>,
        filters: {
            date: null,
            currency: null,
            marketplace: null,
            payoutGateway: null,
            payoutTotal: null,
            cogsTotal: null,
            cogsStatus: null,
            settlementStatus: null,
        } as Record<string, any>,
        filtered: 0,
    }),
    actions: {
        init() {
            watch(
                this.filters,
                (newVal) => {
                    // nullify values if empty arrays or empty params within objects
                    for (const key in newVal) {
                        if (Array.isArray(newVal[key]) && newVal[key]?.length === 0) {
                            newVal[key] = null;
                        }
                        if (
                            newVal[key] &&
                            Object.values(newVal[key]).every((item) => item === null || item === "")
                        ) {
                            newVal[key] = null;
                        }
                    }

                    this.updateFilters(newVal);
                },
                { deep: true }
            );
        },
        updateFilters(filters: Record<string, any>) {
            if (this.filtersEmpty(filters)) {
                this.clearFilters();
                return;
            }

            let total = 0;
            for (const key in filters) {
                if (filters[key] && typeof filters[key] === "object") {
                    // Don't count empty values
                    const valueCount = Object.values(filters[key]).filter(
                        (item) => item !== "" && item !== null
                    ).length;
                    if (key !== "date") {
                        this.filtersCount[key] = valueCount;
                    } else {
                        this.filtersCount[key] = valueCount > 1 ? 1 : 0;
                    }
                    total += this.filtersCount[key];
                } else if (Array.isArray(filters[key])) {
                    this.filtersCount[key] = filters[key]?.length;
                    total += this.filtersCount[key];
                } else {
                    // strings - we could simplify this more and have always array
                    this.filtersCount[key] = filters[key] ? 1 : 0;
                    total += this.filtersCount[key];
                }
            }
            this.filtersCount.total = total;
        },
        clearCurrentFiltersObject() {
            this.clearProperties(this.filters);
        },
        clearProperties(obj: Record<string, any>) {
            for (const key in obj) {
                obj[key] = null;
            }
        },
        clearFilters() {
            this.clearCurrentFiltersObject();
            Object.entries(this.filtersCount).forEach(([key]) => (this.filtersCount[key] = 0));
        },
        clearPersistedFilters(accountKey: string) {
            storageService.removeSettlementsFilterState(accountKey);
        },
        filtersEmpty(filters: Record<string, any>) {
            return Object.keys(filters).every((key) => filters[key] === null);
        },
        saveFilterState(accountKey: string) {
            storageService.setSettlementsFilterState(accountKey, this.$state);
        },
        getFilterState(accountKey: string) {
            const filterState = storageService.getSettlementsFilterState(accountKey);
            if (!filterState) {
                return null;
            }

            return filterState;
        },
    },
});
