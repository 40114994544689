<template>
    <b-modal
        v-on="$listeners"
        v-bind="$attrs"
        :id="id"
        class="atx-modal"
        :title="title"
        :size="size"
        :centered="centered"
        hide-footer
        lazy
        static
        :no-close-on-esc="noCloseOnEscComputed"
        :no-close-on-backdrop="noCloseOnBackdropComputed"
    >
        <template #modal-header>
            <h4
                :class="{
                    'my-0': true,
                    'align-items-center d-flex justify-content-between':
                        $scopedSlots['modal-header-title-right-side'],
                }"
            >
                {{ title }} <slot name="modal-header-title-right-side" />
            </h4>
            <button v-if="showCloseButton" size="sm" class="close" @click="cancel()">×</button>
        </template>
        <b-overlay :show="modalBusy" variant="white">
            <div class="d-block">
                <div class="atx-modal-content">
                    <slot />
                </div>
                <div class="d-flex mt-2" v-if="!hideActionButtons">
                    <atx-button
                        class="mt-2 mr-2"
                        @click="ok"
                        :variant="okVariant"
                        :disabled="okDisabled"
                        >{{ okTitle }}</atx-button
                    >
                    <atx-button
                        v-if="!okOnly"
                        @click="cancel"
                        class="mt-2"
                        variant="outline-primary"
                        :disabled="cancelDisabled"
                        >{{ cancelTitle }}</atx-button
                    >
                </div>
            </div>
        </b-overlay>
    </b-modal>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
    props: {
        id: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            default: "",
        },
        okTitle: {
            type: String,
            default: "Ok",
        },
        cancelTitle: {
            type: String,
            default: "Cancel",
        },
        size: {
            type: String,
            default: "md",
        },
        centered: {
            type: Boolean,
            default: false,
        },
        okOnly: {
            type: Boolean,
            default: false,
        },
        okVariant: {
            type: String,
            default: "primary",
        },
        okDisabled: {
            type: Boolean,
            default: false,
        },
        okNoHide: {
            type: Boolean,
            default: false,
        },
        cancelDisabled: {
            type: Boolean,
            default: false,
        },
        modalBusy: {
            type: Boolean,
            default: false,
        },
        noCloseOnEsc: {
            type: Boolean,
            default: false,
        },
        noCloseOnBackdrop: {
            type: Boolean,
            default: false,
        },
        hideHeaderCloseButton: {
            type: Boolean,
            default: false,
        },
        hideActionButtons: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        noCloseOnEscComputed(): boolean {
            return this.noCloseOnEsc !== undefined ? this.noCloseOnEsc : this.modalBusy;
        },
        noCloseOnBackdropComputed(): boolean {
            return this.noCloseOnBackdrop !== undefined ? this.noCloseOnBackdrop : this.modalBusy;
        },
        showCloseButton(): boolean {
            return !this.hideHeaderCloseButton;
        },
    },
    methods: {
        ok() {
            if (this.modalBusy) return;
            this.$emit("ok", true);
            if (!this.okNoHide) this.$bvModal.hide(this.id);
        },
        cancel() {
            this.$emit("cancel", false);
            this.$bvModal.hide(this.id);
        },
    },
});
</script>
<style lang="scss">
.atx-modal {
    .modal-header {
        padding: 1.5rem 1.5rem;
    }

    .modal-body {
        padding: 1rem 1.5rem;

        // Expand the overlay to better cover the modal
        .b-overlay .position-absolute {
            margin: -10px;
        }
    }

    .close {
        font-weight: 300;
        font-size: 1.5rem;
        padding: 1.2rem 1rem;

        &:hover,
        &:focus {
            background: transparent;
        }
    }
}
</style>
