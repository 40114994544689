<script lang="ts">
import Vue, { VNode } from "vue";

export default Vue.extend({
    functional: true,
    props: {
        icon: {
            type: String,
            required: true,
        },
        spaceRight: {
            type: Boolean,
            default: false,
        },
        spaceLeft: {
            type: Boolean,
            default: false,
        },
        width: {
            type: String,
            default: "16",
        },
        height: {
            type: String,
            default: "16",
        },
    },
    render(h, { props, data, children }) {
        const width = props.width;
        const height = props.height;
        const iconMap: Record<string, VNode> = {
            boxes: h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width,
                        height,
                        fill: "currentColor",
                        viewBox: "0 0 16 16",
                        class: "bi bi-boxes",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M7.752.066a.5.5 0 0 1 .496 0l3.75 2.143a.5.5 0 0 1 .252.434v3.995l3.498 2A.5.5 0 0 1 16 9.07v4.286a.5.5 0 0 1-.252.434l-3.75 2.143a.5.5 0 0 1-.496 0l-3.502-2-3.502 2.001a.5.5 0 0 1-.496 0l-3.75-2.143A.5.5 0 0 1 0 13.357V9.071a.5.5 0 0 1 .252-.434L3.75 6.638V2.643a.5.5 0 0 1 .252-.434L7.752.066ZM4.25 7.504 1.508 9.071l2.742 1.567 2.742-1.567L4.25 7.504ZM7.5 9.933l-2.75 1.571v3.134l2.75-1.571V9.933Zm1 3.134 2.75 1.571v-3.134L8.5 9.933v3.134Zm.508-3.996 2.742 1.567 2.742-1.567-2.742-1.567-2.742 1.567Zm2.242-2.433V3.504L8.5 5.076V8.21l2.75-1.572ZM7.5 8.21V5.076L4.75 3.504v3.134L7.5 8.21ZM5.258 2.643 8 4.21l2.742-1.567L8 1.076 5.258 2.643ZM15 9.933l-2.75 1.571v3.134L15 13.067V9.933ZM3.75 14.638v-3.134L1 9.933v3.134l2.75 1.571Z",
                        },
                    }),
                ]
            ),
            "rocket-takeoff": h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-rocket-takeoff",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M9.752 6.193c.599.6 1.73.437 2.528-.362.798-.799.96-1.932.362-2.531-.599-.6-1.73-.438-2.528.361-.798.8-.96 1.933-.362 2.532Z",
                        },
                    }),
                    h("path", {
                        attrs: {
                            d: "M15.811 3.312c-.363 1.534-1.334 3.626-3.64 6.218l-.24 2.408a2.56 2.56 0 0 1-.732 1.526L8.817 15.85a.51.51 0 0 1-.867-.434l.27-1.899c.04-.28-.013-.593-.131-.956a9.42 9.42 0 0 0-.249-.657l-.082-.202c-.815-.197-1.578-.662-2.191-1.277-.614-.615-1.079-1.379-1.275-2.195l-.203-.083a9.556 9.556 0 0 0-.655-.248c-.363-.119-.675-.172-.955-.132l-1.896.27A.51.51 0 0 1 .15 7.17l2.382-2.386c.41-.41.947-.67 1.524-.734h.006l2.4-.238C9.005 1.55 11.087.582 12.623.208c.89-.217 1.59-.232 2.08-.188.244.023.435.06.57.093.067.017.12.033.16.045.184.06.279.13.351.295l.029.073a3.475 3.475 0 0 1 .157.721c.055.485.051 1.178-.159 2.065Zm-4.828 7.475.04-.04-.107 1.081a1.536 1.536 0 0 1-.44.913l-1.298 1.3.054-.38c.072-.506-.034-.993-.172-1.418a8.548 8.548 0 0 0-.164-.45c.738-.065 1.462-.38 2.087-1.006ZM5.205 5c-.625.626-.94 1.351-1.004 2.09a8.497 8.497 0 0 0-.45-.164c-.424-.138-.91-.244-1.416-.172l-.38.054 1.3-1.3c.245-.246.566-.401.91-.44l1.08-.107-.04.039Zm9.406-3.961c-.38-.034-.967-.027-1.746.163-1.558.38-3.917 1.496-6.937 4.521-.62.62-.799 1.34-.687 2.051.107.676.483 1.362 1.048 1.928.564.565 1.25.941 1.924 1.049.71.112 1.429-.067 2.048-.688 3.079-3.083 4.192-5.444 4.556-6.987.183-.771.18-1.345.138-1.713a2.835 2.835 0 0 0-.045-.283 3.078 3.078 0 0 0-.3-.041Z",
                        },
                    }),
                    h("path", {
                        attrs: {
                            d: "M7.009 12.139a7.632 7.632 0 0 1-1.804-1.352A7.568 7.568 0 0 1 3.794 8.86c-1.102.992-1.965 5.054-1.839 5.18.125.126 3.936-.896 5.054-1.902Z",
                        },
                    }),
                ]
            ),
            "filetype-pdf": h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-filetype-pdf",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            "fill-rule": "evenodd",
                            d: "M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z",
                        },
                    }),
                ]
            ),
            "filetype-csv": h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-filetype-csv",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            "fill-rule": "evenodd",
                            d: "M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM3.517 14.841a1.13 1.13 0 0 0 .401.823q.195.162.478.252.284.091.665.091.507 0 .859-.158.354-.158.539-.44.187-.284.187-.656 0-.336-.134-.56a1 1 0 0 0-.375-.357 2 2 0 0 0-.566-.21l-.621-.144a1 1 0 0 1-.404-.176.37.37 0 0 1-.144-.299q0-.234.185-.384.188-.152.512-.152.214 0 .37.068a.6.6 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.1 1.1 0 0 0-.2-.566 1.2 1.2 0 0 0-.5-.41 1.8 1.8 0 0 0-.78-.152q-.439 0-.776.15-.337.149-.527.421-.19.273-.19.639 0 .302.122.524.124.223.352.367.228.143.539.213l.618.144q.31.073.463.193a.39.39 0 0 1 .152.326.5.5 0 0 1-.085.29.56.56 0 0 1-.255.193q-.167.07-.413.07-.175 0-.32-.04a.8.8 0 0 1-.248-.115.58.58 0 0 1-.255-.384zM.806 13.693q0-.373.102-.633a.87.87 0 0 1 .302-.399.8.8 0 0 1 .475-.137q.225 0 .398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.4 1.4 0 0 0-.489-.272 1.8 1.8 0 0 0-.606-.097q-.534 0-.911.223-.375.222-.572.632-.195.41-.196.979v.498q0 .568.193.976.197.407.572.626.375.217.914.217.439 0 .785-.164t.550-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.8.8 0 0 1-.118.363.7.7 0 0 1-.272.25.9.9 0 0 1-.401.087.85.85 0 0 1-.478-.132.83.83 0 0 1-.299-.392 1.7 1.7 0 0 1-.102-.627zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879z",
                        },
                    }),
                ]
            ),
            "currency-dollar": h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-currency-dollar",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z",
                        },
                    }),
                ]
            ),
            "globe-americas": h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-globe-americas",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484q-.121.12-.242.234c-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z",
                        },
                    }),
                ]
            ),
            netsuite: h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: "25",
                        height: "24",
                        fill: "none",
                        class: "bi bi-netsuite",
                        viewBox: "0 0 25 24",
                    },
                },
                [
                    h(
                        "g",
                        {
                            attrs: {
                                "clip-path": "url(#clip0_3080_19451)",
                            },
                        },
                        [
                            h("path", {
                                attrs: {
                                    d: "M1.26562 6.71475H5.98441V17.9378H8.33385V22.3234H1.26562V6.71475ZM23.1075 17.0408H18.3887V5.8177H16.0392V1.43213H23.1075V17.0408Z",
                                    fill: "#BACCDB",
                                },
                            }),
                            h("path", {
                                attrs: {
                                    d: "M0.170898 0.355652H15.0839V13.1735L9.34964 5.77782H0.170898V0.355652ZM24.183 23.4198H9.27V10.6019L15.0042 17.9976H24.183",
                                    fill: "#125580",
                                },
                            }),
                        ]
                    ),
                    h("defs", [
                        h(
                            "clipPath",
                            {
                                attrs: {
                                    id: "clip0_3080_19451",
                                },
                            },
                            [
                                h("rect", {
                                    attrs: {
                                        width: "24",
                                        height: "23.2131",
                                        fill: "white",
                                        transform: "translate(0 0.393433)",
                                    },
                                }),
                            ]
                        ),
                    ]),
                ]
            ),
            "amazon-pay": h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        version: "1.1",
                        width: "256",
                        height: "256",
                        class: "bi bi-amazon-pay",
                        viewBox: "0 0 256 256",
                    },
                },
                [
                    h("defs", null),
                    h(
                        "g",
                        {
                            style: {
                                stroke: "none",
                                strokeWidth: 0,
                                strokeDasharray: "none",
                                strokeLinecap: "butt",
                                strokeLinejoin: "miter",
                                strokeMiterlimit: 10,
                                fill: "none",
                                fillRule: "nonzero",
                                opacity: 1,
                            },
                            attrs: {
                                transform:
                                    "translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)",
                            },
                        },
                        [
                            h("path", {
                                style: {
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "rgb(51,62,71)",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                },
                                attrs: {
                                    d: "M 24.335 20.026 c -2.405 0 -4.929 1.116 -6.889 2.856 l -0.22 -1.493 c -0.056 -0.376 -0.357 -0.694 -0.797 -0.694 h -2.093 c -0.383 0 -0.695 0.313 -0.695 0.694 c 0 9.498 0 18.997 0 28.495 c 0 0.382 0.313 0.694 0.695 0.694 h 2.735 c 0.382 0 0.694 -0.313 0.694 -0.694 v -9.84 c 1.735 1.547 3.991 2.437 6.403 2.437 c 5.73 0 9.118 -4.961 9.118 -11.135 C 33.286 25.576 31.071 20.026 24.335 20.026 z M 27.072 37.69 c -1.066 1.051 -2.256 1.337 -4.048 1.337 c -1.595 0 -3.693 -0.832 -5.26 -1.99 V 25.374 c 1.632 -1.253 3.713 -1.931 5.448 -1.931 c 4.53 0 5.764 3.562 5.764 7.641 C 29.002 33.834 28.496 36.286 27.072 37.69 z",
                                    transform: "matrix(1 0 0 1 0 0)",
                                    strokeLinecap: "round",
                                },
                            }),
                            h("path", {
                                style: {
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "rgb(51,62,71)",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                },
                                attrs: {
                                    d: "M 53.316 41.267 c 0 0.382 -0.313 0.694 -0.697 0.694 h -2.042 c -0.441 0 -0.742 -0.319 -0.799 -0.694 l -0.205 -1.39 c -0.938 0.794 -2.09 1.492 -3.339 1.978 c -2.401 0.933 -5.17 1.087 -7.516 -0.354 c -1.697 -1.041 -2.597 -3.077 -2.597 -5.178 c 0 -1.625 0.501 -3.236 1.611 -4.405 c 1.481 -1.598 3.629 -2.383 6.221 -2.383 c 1.566 0 3.807 0.184 5.438 0.717 v -2.797 c 0 -2.844 -1.197 -4.074 -4.354 -4.074 c -2.413 0 -4.259 0.364 -6.827 1.163 c -0.411 0.014 -0.652 -0.299 -0.652 -0.681 v -1.595 c 0 -0.383 0.327 -0.753 0.681 -0.865 c 1.834 -0.8 4.433 -1.298 7.195 -1.39 c 3.601 0 7.881 0.812 7.881 6.351 V 41.267 z M 49.392 37.183 v -4.22 c -1.37 -0.375 -3.638 -0.53 -4.515 -0.53 c -1.386 0 -2.904 0.328 -3.697 1.181 c -0.592 0.626 -0.86 1.524 -0.86 2.392 c 0 1.123 0.388 2.249 1.296 2.806 c 1.054 0.716 2.689 0.629 4.225 0.192 C 47.317 38.586 48.704 37.844 49.392 37.183 z",
                                    transform: "matrix(1 0 0 1 0 0)",
                                    strokeLinecap: "round",
                                },
                            }),
                            h("path", {
                                style: {
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "rgb(51,62,71)",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                },
                                attrs: {
                                    d: "M 57.179 49.847 c 0 -0.518 0 -0.983 0 -1.501 c 0 -0.427 0.21 -0.722 0.655 -0.695 c 0.832 0.118 2.008 0.236 2.844 0.064 c 1.091 -0.227 1.873 -1 2.336 -2.06 c 0.652 -1.492 1.084 -2.695 1.357 -3.484 l -8.282 -20.518 c -0.14 -0.348 -0.181 -0.994 0.514 -0.994 h 2.895 c 0.552 0 0.777 0.351 0.901 0.694 l 6.005 16.666 l 5.732 -16.666 c 0.117 -0.341 0.352 -0.694 0.899 -0.694 h 2.73 c 0.691 0 0.652 0.645 0.514 0.994 l -8.216 21.158 c -1.063 2.816 -2.479 7.302 -5.669 8.081 c -1.599 0.418 -3.617 0.267 -4.802 -0.229 C 57.293 50.513 57.179 50.112 57.179 49.847 z",
                                    transform: "matrix(1 0 0 1 0 0)",
                                    strokeLinecap: "round",
                                },
                            }),
                            h("path", {
                                style: {
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "rgb(247,156,52)",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                },
                                attrs: {
                                    d: "M 84.123 48.992 c -2.8 0.039 -6.106 0.666 -8.614 2.426 c -0.774 0.537 -0.638 1.283 0.223 1.184 c 2.82 -0.339 9.097 -1.1 10.219 0.334 c 1.125 1.434 -1.244 7.35 -2.291 9.996 c -0.324 0.789 0.361 1.116 1.084 0.516 c 4.692 -3.93 5.911 -12.169 4.951 -13.354 C 89.214 49.501 86.923 48.953 84.123 48.992 z",
                                    transform: "matrix(1 0 0 1 0 0)",
                                    strokeLinecap: "round",
                                },
                            }),
                            h("path", {
                                style: {
                                    stroke: "none",
                                    strokeWidth: 1,
                                    strokeDasharray: "none",
                                    strokeLinecap: "butt",
                                    strokeLinejoin: "miter",
                                    strokeMiterlimit: 10,
                                    fill: "rgb(247,156,52)",
                                    fillRule: "nonzero",
                                    opacity: 1,
                                },
                                attrs: {
                                    d: "M 0.647 51.992 c -0.654 0.09 -0.936 0.919 -0.252 1.576 c 11.751 11.022 27.28 16.419 44.521 16.419 c 12.297 0 26.574 -4.012 36.429 -11.573 c 1.629 -1.257 0.241 -3.141 -1.43 -2.397 c -11.049 4.875 -23.051 7.225 -33.974 7.225 c -16.189 0 -31.86 -3.38 -44.539 -11.057 C 1.126 52.017 0.865 51.962 0.647 51.992 z",
                                    transform: "matrix(1 0 0 1 0 0)",
                                    strokeLinecap: "round",
                                },
                            }),
                        ]
                    ),
                ]
            ),
            speedometer2: h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-speedometer2",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z",
                        },
                    }),
                    h("path", {
                        attrs: {
                            "fill-rule": "evenodd",
                            d: "M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z",
                        },
                    }),
                ]
            ),
            "thumbs-up": h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-thumbs-up",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2 2 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a10 10 0 0 0-.443.05 9.4 9.4 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a9 9 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.2 2.2 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.9.9 0 0 1-.121.416c-.165.288-.503.56-1.066.56z",
                        },
                    }),
                ]
            ),
            "thumbs-down": h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-thumbs-down",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M8.864 15.674c-.956.24-1.843-.484-1.908-1.42-.072-1.05-.23-2.015-.428-2.59-.125-.36-.479-1.012-1.04-1.638-.557-.624-1.282-1.179-2.131-1.41C2.685 8.432 2 7.85 2 7V3c0-.845.682-1.464 1.448-1.546 1.07-.113 1.564-.415 2.068-.723l.048-.029c.272-.166.578-.349.97-.484C6.931.08 7.395 0 8 0h3.5c.937 0 1.599.478 1.934 1.064.164.287.254.607.254.913 0 .152-.023.312-.077.464.201.262.38.577.488.9.11.33.172.762.004 1.15.069.13.12.268.159.403.077.27.113.567.113.856s-.036.586-.113.856c-.035.12-.08.244-.138.363.394.571.418 1.2.234 1.733-.206.592-.682 1.1-1.2 1.272-.847.283-1.803.276-2.516.211a10 10 0 0 1-.443-.05 9.36 9.36 0 0 1-.062 4.51c-.138.508-.55.848-1.012.964zM11.5 1H8c-.51 0-.863.068-1.14.163-.281.097-.506.229-.776.393l-.04.025c-.555.338-1.198.73-2.49.868-.333.035-.554.29-.554.55V7c0 .255.226.543.62.65 1.095.3 1.977.997 2.614 1.709.635.71 1.064 1.475 1.238 1.977.243.7.407 1.768.482 2.85.025.362.36.595.667.518l.262-.065c.16-.04.258-.144.288-.255a8.34 8.34 0 0 0-.145-4.726.5.5 0 0 1 .595-.643h.003l.014.004.058.013a9 9 0 0 0 1.036.157c.663.06 1.457.054 2.11-.163.175-.059.45-.301.57-.651.107-.308.087-.67-.266-1.021L12.793 7l.353-.354c.043-.042.105-.14.154-.315.048-.167.075-.37.075-.581s-.027-.414-.075-.581c-.05-.174-.111-.273-.154-.315l-.353-.354.353-.354c.047-.047.109-.176.005-.488a2.2 2.2 0 0 0-.505-.804l-.353-.354.353-.354c.006-.005.041-.05.041-.17a.9.9 0 0 0-.121-.415C12.4 1.272 12.063 1 11.5 1",
                        },
                    }),
                ]
            ),
            comment: h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-comment",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z",
                        },
                    }),
                ]
            ),
            "a2x-logo": h(
                "svg",
                {
                    attrs: {
                        width: "17",
                        height: "20",
                        viewBox: "0 0 17 20",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M8.06938 10.8703C7.60599 11.2437 7.12108 11.6245 6.60842 12.0147C4.63754 13.5128 2.23484 14.3569 0.0834961 14.8322V20C2.94784 19.4473 6.35633 18.3509 9.21287 16.1793C10.1104 15.4973 10.9232 14.8446 11.6798 14.2153L8.06938 10.8703Z",
                            fill: "#F57F20",
                        },
                    }),
                    h("path", {
                        attrs: {
                            d: "M8.0696 9.12933L11.6795 5.7847C10.9226 5.15601 10.1092 4.50303 9.21221 3.82129C6.35633 1.65 2.94711 0.552683 0.0834961 7.62939e-06V5.16778C2.23418 5.64275 4.63686 6.48717 6.60784 7.98532C7.12048 8.37494 7.60584 8.75578 8.0696 9.12933Z",
                            fill: "#8DC741",
                        },
                    }),
                    h("path", {
                        attrs: {
                            d: "M12.5556 6.70776L9.02881 9.9999L12.5556 13.2922C13.8053 12.2224 15.9735 10.0899 16.0707 9.9999C15.9735 9.91062 13.8053 7.77781 12.5556 6.70776Z",
                            fill: "#2DABE2",
                        },
                    }),
                ]
            ),
            exclamation: h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-exclamation-circle-fill icon-color",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4m.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2",
                        },
                    }),
                ]
            ),
            send: h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-send",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z",
                        },
                    }),
                ]
            ),
            paypal: h(
                "svg",
                {
                    attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        width: width,
                        height: height,
                        fill: "currentColor",
                        class: "bi bi-paypal",
                        viewBox: "0 0 16 16",
                    },
                },
                [
                    h("path", {
                        attrs: {
                            d: "M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z",
                        },
                    }),
                ]
            ),
        };

        const currentComponent = iconMap[props.icon] || "b-icon";

        if (typeof currentComponent === "string") {
            if (props.spaceLeft) {
                data.staticClass = data.staticClass ? data.staticClass.concat(" ml-2") : "ml-2";
            }
            if (props.spaceRight) {
                data.staticClass = data.staticClass ? data.staticClass.concat(" mr-2") : "mr-2";
            }
            return h(
                currentComponent,
                {
                    ...data,
                    style: {
                        width,
                        height,
                        ...data.staticStyle,
                    },
                    props,
                },
                children
            );
        } else {
            // add classes based on props
            if (props.spaceLeft) {
                (currentComponent.data as any).attrs.class = (currentComponent.data as any).attrs
                    .class
                    ? (currentComponent.data as any).attrs.class.concat(" ml-2")
                    : "ml-2";
            }
            if (props.spaceRight) {
                (currentComponent.data as any).attrs.class = (currentComponent.data as any).attrs
                    .class
                    ? (currentComponent.data as any).attrs.class.concat(" mr-2")
                    : "mr-2";
            }

            // add native class to the component if it exists
            (currentComponent.data as any).attrs.class = (currentComponent.data as any).attrs.class
                ? (currentComponent.data as any).attrs.class.concat(" " + data.staticClass)
                : null;

            // add styles
            (currentComponent.data as any).style = {
                width: `${width}px`,
                height: `${height}px`,
                ...data.staticStyle,
            };
            return currentComponent;
        }
    },
});
</script>
