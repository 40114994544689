import { catchErrorHandler } from "@/helpers";
import { GetUserStatusAsyncResponse } from "@/models/user.models";
import { a2x2Api, a2xPublic } from "@/services/api.service";
import { AxiosResponse } from "axios";

export const oauthApi = {
    async getHasAdminPermissionsAsync(): Promise<boolean> {
        // TODO: check promise type. It also expects an object with "error" property
        const url = `public/oauth/has-admin-permissions`;
        try {
            const response: AxiosResponse = await a2x2Api.get(url);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
    async getUserStatusAsync(): Promise<GetUserStatusAsyncResponse> {
        // TODO: check promise type. It also expects an object with "error" property
        const url = `public/oauth/user-status`;
        try {
            const response: AxiosResponse = await a2x2Api.get(url);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },

    async connectAsync(accessToken: string): Promise<any> {
        const url = `/connect`;
        const obj = {
            accessToken,
        };
        try {
            const response: AxiosResponse = await a2xPublic.post(url, obj);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },

    async sendOauthVerificationEmailAsync(name: string | null, email: string | null): Promise<any> {
        const url = `/send-verification-email`;
        const obj = {
            name,
            email,
        };
        try {
            const response: AxiosResponse = await a2xPublic.post(url, obj);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
};
