export const currencyLocale = {
    AED: {
        name: "Arab Emirate Dirham",
        locale: "en-ae",
    },
    AUD: {
        name: "Australian Dollars",
        locale: "en-AU",
    },
    BHD: {
        name: "Bahraini Dinars",
        locale: "en-BH",
    },
    BBD: {
        name: "Barbadian Dollar",
        locale: "bb-BB",
    },
    BRL: {
        name: "Brazilian Real",
        locale: "es-US",
    },
    CAD: {
        name: "Canadian Dollars",
        locale: "en-CA",
    },
    CHF: {
        name: "Swiss Franc",
        locale: "en-CH",
    },
    CNY: {
        name: "China Yuan Renminbi",
        locale: "zh-CN",
    },
    DKK: {
        name: "Danish Krone",
        locale: "en-US",
    },
    EGP: {
        name: "Egyptian pound",
        locale: "en-EG",
    },
    EUR: {
        name: "Euros",
        locale: "fr-FR",
    },
    GBP: {
        name: "British Pounds",
        locale: "en-GB",
    },
    HKD: {
        name: "Hong Kong Dollars",
        locale: "es-US",
    },
    IDR: {
        name: "Indonesian Rupiah",
        locale: "en-ID",
    },
    INR: {
        name: "Rupees",
        locale: "in-IN",
    },
    JOD: {
        name: "Jordanian Dinar",
        locale: "en-JO",
    },
    JPY: {
        name: "Japanese Yen",
        locale: "jp-JP",
    },
    KES: {
        name: "Kenyan Shilling",
        locale: "en-US",
    },
    KRW: {
        name: "South Korean Won",
        locale: "es-US",
    },
    KWD: {
        name: "Kuwaiti Dinar",
        locale: "en-KW",
    },
    LKR: {
        name: "Sri Lankan Rupee",
        locale: "en-LK",
    },
    MXN: {
        name: "Mexican Pesos",
        locale: "en-AU",
    },
    MYR: {
        name: "Malaysian Ringgit",
        locale: "en-US",
    },
    NGN: {
        name: "Nigerian Naira",
        locale: "en-US",
    },
    NIO: {
        name: "Nicaraguan Cordoba",
        locale: "en-NI",
    },
    NOK: {
        name: "Norwegian Krona",
        locale: "en-NO",
    },
    NZD: {
        name: "New Zealand Dollars",
        locale: "en-NZ",
    },
    PLN: {
        name: "Polish Zloty",
        locale: "pl-PL",
    },
    SAR: {
        name: "Saudi Riyal",
        locale: "en-SA",
    },
    SEK: {
        name: "Swedish Krona",
        locale: "se-SE",
    },
    SGD: {
        name: "Singapore Dollars",
        locale: "en-SG",
    },
    THB: {
        name: "Thai Baht",
        locale: "en-TH",
    },
    TRY: {
        name: "Turkish Lira",
        locale: "en-TR",
    },
    USD: {
        name: "US Dollars",
        locale: "en-US",
    },
    ZAR: {
        name: "South African Rand",
        locale: "en-ZA",
    },
    AFN: {
        name: "Afghan Afghani",
        locale: "fa-AF",
    },
    ALL: {
        name: "Albanian Lek",
        locale: "sq-AL",
    },
    AMD: {
        name: "Armenian Dram",
        locale: "hy-AM",
    },
    ARS: {
        name: "Argentine Peso",
        locale: "es-AR",
    },
    AZN: {
        name: "Azerbaijani Manat",
        locale: "az-AZ",
    },
    BDT: {
        name: "Bangladeshi Taka",
        locale: "bn-BD",
    },
    BYN: {
        name: "Belarusian Ruble",
        locale: "be-BY",
    },
    BZD: {
        name: "Belize Dollar",
        locale: "en-BZ",
    },
    BMD: {
        name: "Bermudian Dollar",
        locale: "en-BM",
    },
    BTN: {
        name: "Bhutanese Ngultrum",
        locale: "dz-BT",
    },
    BOB: {
        name: "Bolivian Boliviano",
        locale: "es-BO",
    },
    BAM: {
        name: "Bosnia and Herzegovina Convertible Mark",
        locale: "bs-BA",
    },
    BGN: {
        name: "Bulgarian Lev",
        locale: "bg-BG",
    },
    KHR: {
        name: "Cambodian Riel",
        locale: "km-KH",
    },
    XAF: {
        name: "Central African CFA Franc",
        locale: "fr-CF",
    },
    CLP: {
        name: "Chilean Peso",
        locale: "es-CL",
    },
    COP: {
        name: "Colombian Peso",
        locale: "es-CO",
    },
    CRC: {
        name: "Costa Rican Colon",
        locale: "es-CR",
    },
    HRK: {
        name: "Croatian Kuna",
        locale: "hr-HR",
    },
    CUP: {
        name: "Cuban Peso",
        locale: "es-CU",
    },
    CZK: {
        name: "Czech Koruna",
        locale: "cs-CZ",
    },
    DJF: {
        name: "Djiboutian Franc",
        locale: "fr-DJ",
    },
    DOP: {
        name: "Dominican Peso",
        locale: "es-DO",
    },
    ERN: {
        name: "Eritrean Nakfa",
        locale: "ti-ER",
    },
    ETB: {
        name: "Ethiopian Birr",
        locale: "am-ET",
    },
    FJD: {
        name: "Fijian Dollar",
        locale: "en-FJ",
    },
    FKP: {
        name: "Falkland Islands Pound",
        locale: "en-FK",
    },
    FOK: {
        name: "Faroese Króna",
        locale: "fo-FO",
    },
    GEL: {
        name: "Georgian Lari",
        locale: "ka-GE",
    },
    GHS: {
        name: "Ghanaian Cedi",
        locale: "en-GH",
    },
    GIP: {
        name: "Gibraltar Pound",
        locale: "en-GI",
    },
    GMD: {
        name: "Gambian Dalasi",
        locale: "en-GM",
    },
    GNF: {
        name: "Guinean Franc",
        locale: "fr-GN",
    },
    GTQ: {
        name: "Guatemalan Quetzal",
        locale: "es-GT",
    },
    GYD: {
        name: "Guyanese Dollar",
        locale: "en-GY",
    },
    HNL: {
        name: "Honduran Lempira",
        locale: "es-HN",
    },
    HTG: {
        name: "Haitian Gourde",
        locale: "fr-HT",
    },
    HUF: {
        name: "Hungarian Forint",
        locale: "hu-HU",
    },
    ILS: {
        name: "Israeli New Shekel",
        locale: "he-IL",
    },
    IQD: {
        name: "Iraqi Dinar",
        locale: "ar-IQ",
    },
    IRR: {
        name: "Iranian Rial",
        locale: "fa-IR",
    },
    ISK: {
        name: "Icelandic Króna",
        locale: "is-IS",
    },
    JMD: {
        name: "Jamaican Dollar",
        locale: "en-JM",
    },
    KGS: {
        name: "Kyrgyzstani Som",
        locale: "ky-KG",
    },
    KMF: {
        name: "Comorian Franc",
        locale: "fr-KM",
    },
    KPW: {
        name: "North Korean Won",
        locale: "ko-KP",
    },
    KYD: {
        name: "Cayman Islands Dollar",
        locale: "en-KY",
    },
    KZT: {
        name: "Kazakhstani Tenge",
        locale: "kk-KZ",
    },
    LAK: {
        name: "Lao Kip",
        locale: "lo-LA",
    },
    LBP: {
        name: "Lebanese Pound",
        locale: "ar-LB",
    },
    LRD: {
        name: "Liberian Dollar",
        locale: "en-LR",
    },
    LSL: {
        name: "Lesotho Loti",
        locale: "st-LS",
    },
    LYD: {
        name: "Libyan Dinar",
        locale: "ar-LY",
    },
    MAD: {
        name: "Moroccan Dirham",
        locale: "ar-MA",
    },
    MDL: {
        name: "Moldovan Leu",
        locale: "ro-MD",
    },
    MGA: {
        name: "Malagasy Ariary",
        locale: "mg-MG",
    },
    MKD: {
        name: "Macedonian Denar",
        locale: "mk-MK",
    },
    MMK: {
        name: "Myanmar Kyat",
        locale: "my-MM",
    },
    MNT: {
        name: "Mongolian Tögrög",
        locale: "mn-MN",
    },
    MOP: {
        name: "Macanese Pataca",
        locale: "zh-MO",
    },
    MRU: {
        name: "Mauritanian Ouguiya",
        locale: "ar-MR",
    },
    MUR: {
        name: "Mauritian Rupee",
        locale: "en-MU",
    },
    MVR: {
        name: "Maldivian Rufiyaa",
        locale: "dv-MV",
    },
    MWK: {
        name: "Malawian Kwacha",
        locale: "en-MW",
    },
    MZN: {
        name: "Mozambican Metical",
        locale: "pt-MZ",
    },
    NAD: {
        name: "Namibian Dollar",
        locale: "en-NA",
    },
    NPR: {
        name: "Nepalese Rupee",
        locale: "ne-NP",
    },
    OMR: {
        name: "Omani Rial",
        locale: "ar-OM",
    },
    PAB: {
        name: "Panamanian Balboa",
        locale: "es-PA",
    },
    PEN: {
        name: "Peruvian Sol",
        locale: "es-PE",
    },
    PGK: {
        name: "Papua New Guinean Kina",
        locale: "en-PG",
    },
    PHP: {
        name: "Philippine Peso",
        locale: "en-PH",
    },
    PKR: {
        name: "Pakistani Rupee",
        locale: "ur-PK",
    },
    PYG: {
        name: "Paraguayan Guarani",
        locale: "es-PY",
    },
    QAR: {
        name: "Qatari Riyal",
        locale: "ar-QA",
    },
    RON: {
        name: "Romanian Leu",
        locale: "ro-RO",
    },
    RSD: {
        name: "Serbian Dinar",
        locale: "sr-RS",
    },
    RUB: {
        name: "Russian Ruble",
        locale: "ru-RU",
    },
    RWF: {
        name: "Rwandan Franc",
        locale: "rw-RW",
    },
    SBD: {
        name: "Solomon Islands Dollar",
        locale: "en-SB",
    },
    SCR: {
        name: "Seychellois Rupee",
        locale: "en-SC",
    },
    SDG: {
        name: "Sudanese Pound",
        locale: "ar-SD",
    },
    SLL: {
        name: "Sierra Leonean Leone",
        locale: "en-SL",
    },
    SOS: {
        name: "Somali Shilling",
        locale: "so-SO",
    },
    SRD: {
        name: "Surinamese Dollar",
        locale: "nl-SR",
    },
    SSP: {
        name: "South Sudanese Pound",
        locale: "en-SS",
    },
    STN: {
        name: "São Tomé and Príncipe Dobra",
        locale: "pt-ST",
    },
    SVC: {
        name: "Salvadoran Colón",
        locale: "es-SV",
    },
    SYP: {
        name: "Syrian Pound",
        locale: "ar-SY",
    },
    SZL: {
        name: "Swazi Lilangeni",
        locale: "en-SZ",
    },
    TJS: {
        name: "Tajikistani Somoni",
        locale: "tg-TJ",
    },
    TMT: {
        name: "Turkmenistani Manat",
        locale: "tk-TM",
    },
    TND: {
        name: "Tunisian Dinar",
        locale: "ar-TN",
    },
    TOP: {
        name: "Tongan Paʻanga",
        locale: "to-TO",
    },
    TTD: {
        name: "Trinidad and Tobago Dollar",
        locale: "en-TT",
    },
    TWD: {
        name: "New Taiwan Dollar",
        locale: "zh-TW",
    },
    TZS: {
        name: "Tanzanian Shilling",
        locale: "sw-TZ",
    },
    UAH: {
        name: "Ukrainian Hryvnia",
        locale: "uk-UA",
    },
    UGX: {
        name: "Ugandan Shilling",
        locale: "en-UG",
    },
    UYU: {
        name: "Uruguayan Peso",
        locale: "es-UY",
    },
    UZS: {
        name: "Uzbekistani Som",
        locale: "uz-UZ",
    },
    VES: {
        name: "Venezuelan Bolívar Soberano",
        locale: "es-VE",
    },
    VND: {
        name: "Vietnamese Đồng",
        locale: "vi-VN",
    },
    VUV: {
        name: "Vanuatu Vatu",
        locale: "bi-VU",
    },
    WST: {
        name: "Samoan Tala",
        locale: "sm-WS",
    },
    XCD: {
        name: "East Caribbean Dollar",
        locale: "en-XC",
    },
    YER: {
        name: "Yemeni Rial",
        locale: "ar-YE",
    },
    ZMW: {
        name: "Zambian Kwacha",
        locale: "en-ZM",
    },
    ZWL: {
        name: "Zimbabwean Dollar",
        locale: "en-ZW",
    },
};

export function formatCurrency(currency?: string, total?: number | null): string {
    if (!currency || (!total && total != 0)) {
        return "--";
    }

    let locale = "en-US";

    // check if we have the proper currency key and look up the locale
    if (currency as keyof typeof currencyLocale) {
        const index = currency.toUpperCase() as keyof typeof currencyLocale;
        locale = currencyLocale[index].locale;
    }

    return new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currency,
        currencySign: "accounting", // wrap negatives with brackets
    }).format(total);
}

export function formatCurrencyWithoutCurrencySign(currency?: string, amount?: number): string {
    if (!currency) {
        currency = "USD";
    }

    if (!amount && amount !== 0) {
        return "--";
    }

    let locale = "en-US";

    // check if we have the proper currency key and look up the locale
    if (currency as keyof typeof currencyLocale) {
        const index = currency.toUpperCase() as keyof typeof currencyLocale;
        locale = currencyLocale[index].locale;
    }

    return new Intl.NumberFormat(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(amount);
}
