import { getPageTitle } from "@/helpers";
import { BILLING_PAGE_ROUTES, USER_SETTINGS_PAGE_ROUTES } from "@/router/routeConstants";

export const UserSettingsRoutes = [
    {
        path: USER_SETTINGS_PAGE_ROUTES.USER_SETTINGS.path,
        component: (): Promise<typeof import("*.vue")> => import("./UserSettingsIndex.vue"),
        children: [
            {
                path: "/",
                component: (): Promise<typeof import("*.vue")> => import("./UserSettings.vue"),
                props: true,
                children: [
                    {
                        path: USER_SETTINGS_PAGE_ROUTES.BILLING.path,
                        name: USER_SETTINGS_PAGE_ROUTES.BILLING.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import("./components/BillingDetails.vue"),
                        props: true,
                        meta: {
                            title: getPageTitle("Billing Details"),
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: USER_SETTINGS_PAGE_ROUTES.FIRM_BILLING.path,
                        name: USER_SETTINGS_PAGE_ROUTES.FIRM_BILLING.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import("./components/BillingDetails.vue"),
                        props: {
                            partnerSubscriptionsRender: true,
                        },
                        meta: {
                            title: getPageTitle("Firm Billing Details"),
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: USER_SETTINGS_PAGE_ROUTES.SETTINGS_NOTIFICATIONS.path,
                        name: USER_SETTINGS_PAGE_ROUTES.SETTINGS_NOTIFICATIONS.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import("./components/BillingNotifications.vue"),
                        meta: {
                            title: getPageTitle("Notifications"),
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: USER_SETTINGS_PAGE_ROUTES.PROFILE.path,
                        name: USER_SETTINGS_PAGE_ROUTES.PROFILE.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import("./components/UserProfile.vue"),
                        meta: {
                            title: getPageTitle("User profile"),
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: USER_SETTINGS_PAGE_ROUTES.SUBSCRIPTIONS.path,
                        name: USER_SETTINGS_PAGE_ROUTES.SUBSCRIPTIONS.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import("./components/BillingSubscriptions.vue"),
                        meta: {
                            title: getPageTitle("Subscriptions"),
                            strictReferrerCheck: true,
                        },
                    },
                    {
                        path: USER_SETTINGS_PAGE_ROUTES.PARTNER_SUBSCRIPTIONS.path,
                        name: USER_SETTINGS_PAGE_ROUTES.PARTNER_SUBSCRIPTIONS.name,
                        component: (): Promise<typeof import("*.vue")> =>
                            import("./components/BillingSubscriptions.vue"),
                        props: {
                            partnerSubscriptionsRender: true,
                        },
                        meta: {
                            title: getPageTitle("Firm subscriptions"),
                            strictReferrerCheck: true,
                        },
                    },
                ],
            },
            {
                path: BILLING_PAGE_ROUTES.BILLING_MIGRATION.path,
                name: BILLING_PAGE_ROUTES.BILLING_MIGRATION.name,
                component: (): Promise<typeof import("*.vue")> =>
                    import("../cheddar-to-stripe/CheddarToStripe.vue"),
            },
            {
                path: BILLING_PAGE_ROUTES.CHECKOUT.path,
                name: BILLING_PAGE_ROUTES.CHECKOUT.name,
                component: (): Promise<typeof import("*.vue")> =>
                    import("./components/BillingCheckout.vue"),
                props: true,
                meta: {
                    title: getPageTitle("Checkout"),
                    strictReferrerCheck: true,
                },
            },
            {
                path: BILLING_PAGE_ROUTES.CHECKOUT_COMPLETE.path,
                name: BILLING_PAGE_ROUTES.CHECKOUT_COMPLETE.name,
                component: (): Promise<typeof import("*.vue")> =>
                    import("./components/BillingCheckoutComplete.vue"),
                props: true,
                meta: {
                    title: getPageTitle("Checkout Complete"),
                    strictReferrerCheck: true,
                },
            },
        ],
    },
];
