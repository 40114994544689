// SPA router
export const BackOfficeRoutes = [
    {
        path: "/backend",
        meta: {
            title: "Backend | A2X Accounting",
        },
        component: (): Promise<typeof import("*.vue")> =>
            import(/* webpackChunkName: "Backend" */ "./Backend.vue"),
        // beforeEnter: AdminGuard,
        children: [
            {
                path: "",
                component: (): Promise<typeof import("*.vue")> =>
                    import(/* webpackChunkName: "BackendLayout" */ "./BackendLayout.vue"),
            },
            {
                path: "accounts",
                name: "Account",
                component: (): Promise<typeof import("*.vue")> =>
                    import(/* webpackChunkName: "Accounts" */ "./accounts/Accounts.vue"),
            },
            {
                path: "account/:accountKey",
                name: "Account Details",
                component: (): Promise<typeof import("*.vue")> =>
                    import(/* webpackChunkName: "Account" */ "./account/Account.vue"),
                redirect: "account/:accountKey/billing",
                children: [
                    {
                        path: "transactions/raw",
                        name: "Account Details - Transactions Raw",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "TransactionsRaw" */ "./account/transactions/TransactionsRaw.vue"
                            ),
                    },
                    {
                        path: "settlements",
                        name: "Account Details - Settlements",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "Settlements" */ "./account/settlements/Settlements.vue"
                            ),
                    },
                    {
                        path: "transactions/:reportId",
                        name: "Account Details - Transactions",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "Transactions" */ "./account/transactions/Transactions.vue"
                            ),
                    },
                    {
                        path: "billing",
                        name: "Account Details - Billing",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "Billing" */ "./account/billing/Billing.vue"
                            ),
                    },
                    {
                        path: "utilities",
                        name: "Account Details - Utilities",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "Utilities" */ "./account/utilities/Utilities.vue"
                            ),
                    },
                    {
                        path: "feature-flag",
                        name: "Account Details - Feature Flag",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "FeatureFlag" */ "./account/feature-flag/FeatureFlag.vue"
                            ),
                    },
                    {
                        path: "mappings",
                        name: "Account Details - Mappings",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "Mappings" */ "./account/mappings/Mappings.vue"
                            ),
                    },
                    {
                        path: "questionnaire",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(
                                /* webpackChunkName: "Mappings" */ "./account/questionnaire/MappingsQuestionnaire.vue"
                            ),
                    },
                    {
                        path: "users",
                        name: "Account Details - Users",
                        component: (): Promise<typeof import("*.vue")> =>
                            import(/* webpackChunkName: "Users" */ "./account/users/Users.vue"),
                    },
                ],
            },
            {
                path: ":accountKey/amazon/finance-api-settlements",
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "FinanceApiSettlements" */ "./amazon/finance-api-settlements/FinanceApiSettlements.vue"
                    ),
            },
            {
                path: ":accountKey/amazon/financial-events/:groupId",
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "FinancialEvents" */ "./amazon/financial-events/FinancialEvents.vue"
                    ),
            },
            {
                path: ":accountKey/netsuite/suiteql",
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "SuiteQLRecordRequests" */ "./netsuite/SuiteQLRecordRequests.vue"
                    ),
            },
            {
                path: ":accountKey/netsuite/suiteql/:recordType",
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "SuiteQLRecordResponse" */ "./netsuite/SuiteQLRecordResponse.vue"
                    ),
            },
            {
                path: ":accountKey/shopify/fetch-history",
                name: "History Fetch",
                component: (): Promise<typeof import("*.vue")> =>
                    import(/* webpackChunkName: "FetchHistory" */ "./shopify/FetchHistory.vue"),
                children: [
                    {
                        path: "request/:requestId",
                        component: (): Promise<typeof import("*.vue")> =>
                            import("./shopify/FetchRequest.vue"),
                        name: "History Fetch Report",
                        props: true,
                    },
                ],
            },
            {
                path: ":accountKey/shopify/fetch-history/:requestId",
                name: "History Fetch Request",
                component: (): Promise<typeof import("*.vue")> =>
                    import(/* webpackChunkName: "FetchRequest" */ "./shopify/FetchRequest.vue"),
                props: true,
            },
            {
                path: ":accountKey/shopify/fetch-history-status",
                name: "History Fetch Status",
                component: (): Promise<typeof import("*.vue")> =>
                    import(
                        /* webpackChunkName: "FetchRequest" */ "./shopify/FetchHistoryStatusTable.vue"
                    ),
            },
        ],
    },
];
