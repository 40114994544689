import { catchErrorHandler } from "@/helpers";
import { IQuestionnaire } from "@/models/mappings.models";
import { a2x2Api } from "@/services/api.service";
import { AxiosResponse } from "axios";

const onboardingQuestionnaireDefaultRoute = "public/onboarding/questionnaire";

export const onboardingQuestionnaireApi = {
    async postAsync(obj: unknown): Promise<IQuestionnaire> {
        const url = onboardingQuestionnaireDefaultRoute;
        try {
            const response: AxiosResponse = await a2x2Api.post(url, obj);
            return response.data;
        } catch (e) {
            return <IQuestionnaire>{};
        }
    },
    async getAsync(accountKey: string): Promise<IQuestionnaire> {
        const url = `${onboardingQuestionnaireDefaultRoute}/${accountKey}`;
        try {
            const response: AxiosResponse = await a2x2Api.get(url);
            return response.data;
        } catch (e) {
            return <IQuestionnaire>{};
        }
    },
    async deleteAsync(id: number): Promise<IQuestionnaire> {
        const url = `${onboardingQuestionnaireDefaultRoute}/${id}`;
        try {
            const response: AxiosResponse = await a2x2Api.delete(url);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
    async putAsync(obj: unknown): Promise<IQuestionnaire> {
        const url = onboardingQuestionnaireDefaultRoute;
        try {
            const response: AxiosResponse = await a2x2Api.put(url, obj);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
    async postFinalizeAsync(id: number): Promise<any> {
        const url = `${onboardingQuestionnaireDefaultRoute}/finalize/${id}`;
        try {
            const response: AxiosResponse = await a2x2Api.post(url, null);
            return response.data;
        } catch (e) {
            return [];
        }
    },
    async getShouldShowAssistedSetupAsync(accountKey: string): Promise<boolean> {
        const url = `${onboardingQuestionnaireDefaultRoute}/shouldShowAssistedSetup/${accountKey}`;
        try {
            const response: AxiosResponse = await a2x2Api.get(url);
            return response.data;
        } catch (e) {
            return catchErrorHandler(e);
        }
    },
};
