<template>
    <div class="bg-atx-orange-2 d-flex flex-row justify-content-center align-items-center p-2">
        <strong class="p-2"> You’re in trial mode. </strong>
        <span class="p-2 d-md-block d-none">
            {{ trialBannerText }}
        </span>
        <atx-button
            variant="primary"
            size="sm"
            :href="subscribeUrl"
            class="mx-2"
            @click="choosePlanHandler"
            >Choose Plan</atx-button
        >
        <atx-button variant="outline-dark" size="sm" class="mx-2" v-b-modal.modal-trial-banner>
            Learn More
        </atx-button>
        <atx-modal
            id="modal-trial-banner"
            title="Trial Mode Limitations"
            size="lg"
            centered
            ok-title="Choose plan"
            cancel-title="Close"
            @ok="okHandler"
        >
            <p>
                {{ bannerTextFirstSentence }} You can connect your accounting system to preview the
                entire process with a trial account.
            </p>
            <p>{{ bannerTextSecondSentence }}</p>
        </atx-modal>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import { transactionContainer } from "@/helpers";

export default Vue.extend({
    props: {
        salesChannel: {
            type: String,
            default: "",
        },
        salesChannelDisplayName: {
            type: String,
            default: "",
        },
        subscribeUrl: {
            type: String,
            required: true,
            default: null,
        },
    },
    data() {
        return {};
    },
    computed: {
        trialBannerText(): string {
            if (this.salesChannel === null) return "";
            return `Upgrade to send all your ${this.salesChannelDisplayName} transactions.`;
        },
        bannerTextFirstSentence(): string {
            if (this.salesChannel === null) return "";
            return `Trial accounts can load up to three ${transactionContainer(
                this.salesChannel
            )} files from ${this.salesChannelDisplayName} to test the A2X service.`;
        },
        bannerTextSecondSentence(): string {
            if (this.salesChannel === null) return "";
            return `Choose a plan when you're ready to sync all transactions and load multiple ${transactionContainer(
                this.salesChannel
            )} from ${this.salesChannelDisplayName}.`;
        },
    },
    methods: {
        choosePlanHandler() {
            this.$emit("choose-plan");
        },
        okHandler() {
            this.choosePlanHandler();
            window.location.href = this.subscribeUrl;
        },
    },
});
</script>
