<template>
    <div class="layout-default d-flex flex-column vh-100">
        <atx-sidebar-navigation-skeleton />
        <atx-header-skeleton />
        <main style="flex: 1">
            <admin-view-skeleton />
        </main>
        <atx-footer version="2"></atx-footer>
    </div>
</template>

<script lang="ts">
import Vue from "vue";
import AtxHeaderSkeleton from "@/components/layout/AtxHeader/skeletons/AtxHeaderSkeleton.vue";
import AtxSidebarNavigationSkeleton from "../../layout/__components__/skeletons/AtxSidebarNavigationSkeleton.vue";
import AdminViewSkeleton from "../views/AdminViewSkeleton.vue";
export default Vue.extend({
    components: {
        AtxHeaderSkeleton,
        AtxSidebarNavigationSkeleton,
        AdminViewSkeleton,
    },
});
</script>

<style scoped></style>
