import { getPageTitle } from "@/helpers";
import { REPORTS_PAGE_ROUTES } from "@/router/routeConstants";

export const ReportsRoutes = [
    {
        path: REPORTS_PAGE_ROUTES.APP_EXPORT_DATA.path,
        name: REPORTS_PAGE_ROUTES.APP_EXPORT_DATA.name,
        meta: {
            title: getPageTitle("Export Data"), // Title to be displayed in Browser Tab
            pageHeading: "Export Data", // Title to be displayed in Page Header
            pageHeaderOverride: true,
            isBetaFeature: true,
        },
        component: (): Promise<typeof import("*.vue")> =>
            import(
                /* webpackChunkName: "AppExportData" */ "@/pages/reports/export-data/ExportData.vue"
            ),
    },
];
