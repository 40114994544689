import { useInputs } from "../components/input/useInputs";
import { useDataDisplays } from "../components/dataDisplay/useDataDisplays";
import { useMedia } from "../components/media/useMedia";
import { useFeedbacks } from "../components/feedback/useFeedbacks";
import { useLayouts } from "../components/layout/useLayouts";
import { useUtilities } from "../components/utilities/useUtilities";
import { useNavigation } from "../components/navigation/useNavigation";

export function setupAtxLibrary(): void {
    useInputs();
    useDataDisplays();
    useMedia();
    useFeedbacks();
    useLayouts();
    useUtilities();
    useNavigation();
}
