import { defineStore } from "pinia";

export const useReconciliationReportStore = defineStore({
    id: "reconciliationReport",
    state: () => ({
        reconciliationReportId: "",
        fromReconciliationReportPage: false,
    }),
    actions: {
        setReconciliationReportId(reconciliationReportId: string) {
            this.reconciliationReportId = reconciliationReportId;
        },
        isFromReconciliationReportPage() {
            this.fromReconciliationReportPage = true;
        },
        resetFromReconciliationReportPage() {
            this.fromReconciliationReportPage = false;
        },
    },
});
