
<b-badge
    :class="{
        'atx-billing-badge': true,
        'bg-transparent': true,
        'text-atx-grey-4': !this.disabled,
        'text-atx-grey-2': this.disabled,
        border: true,
        'border-atx-grey-2': true,
    }"
>
    <slot />
</b-badge>
