
<b-table
    hover
    :items="data"
    :fields="summaryFields"
    :sort-by="sortBy"
    :sort-desc="sortDesc"
    outlined
    :small="small"
    head-variant="light"
>
</b-table>
