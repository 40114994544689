export enum AccountingSystem {
    QBO = "QBO",
    XERO = "XERO",
    SAGE = "SAGE",
    NETSUITE = "NETSUITE",
    MANUAL = "MANUAL",
    NONE = "NONE",
}

export enum SalesSource {
    AMAZON = "AMAZON",
    SHOPIFY = "SHOPIFY",
    EBAY = "EBAY",
    ETSY = "ETSY",
    WALMART = "WALMART",
    BIGCOMMERCE = "BIGCOMMERCE",
    PAYPAL = "PAYPAL",
    AFTERPAY = "AFTERPAY",
}

export enum RefreshMode {
    REDOWNLOAD = "REDOWNLOAD",
    REPROCESS = "REPROCESS",
}

export interface Credentials {
    clientId: string;
    clientSecret: string;
}
export interface BigcommerceCredentials {
    storeHash: string;
    accessToken: string;
}

export interface PostBigcommerceCredentials {
    code: string;
    context: string;
    scope: string;
}

export type PostBigCommercePayloadAsync = {
    accountKey: string;
    salesSource: SalesSource;
    credentials: PostBigcommerceCredentials;
};

export type Account = {
    accountKey: string;
    salesSource: SalesSource;
};

export type ExistingOrganization = {
    accountKey: string; // account you would like to connect
    sharedOrganizationAccountKey: string; // the account that owns the connection
};

export type ConnectToExistingOrganizationError = {
    message: string;
};

export type ConnectToOrganizationResponse = {
    provider: string;
    connected: boolean;
    // In case of an error this property will be in the response
    message?: string;
};

export type ConnectToAccountingSystemSuccess = {
    provider: string;
    connected: boolean;
    message?: string;
};

type SalesChannelConnectPayLoad = {
    accountKey: string;
    salesSource: SalesSource;
    credentials: {
        code: string;
    };
};

export type PostEbayConnectAsyncPayload = SalesChannelConnectPayLoad;
export type PostEtsyConnectAsyncPayload = SalesChannelConnectPayLoad;

export type SalesChannelConnectResponse = {
    accountKey: string;
    salesSource: SalesSource;
    connected: boolean;
    message?: string;
    code?: string;
};

export type PaymentGatewayConnectResponse = {
    accountKey: string;
    paymentGateway: SalesSource;
    connected: boolean;
};

export type PaymentGatewayConnectRequest = {
    accountKey: string;
    paymentGateway: SalesSource;
    credentials: OauthCredentials;
};

export type SalesChannelConnectRequest = {
    accountKey: string;
    salesSource: SalesSource;
    credentials: OauthCredentials;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    configuration?: any;
};

export type OauthCredentials = {
    clientId: string;
    clientSecret: string;
};

export type PostWalmartAsyncResponse = SalesChannelConnectResponse;
export type PostEbayConnectAsyncResponse = SalesChannelConnectResponse;
export type PostEtsyConnectAsyncResponse = SalesChannelConnectResponse;

export type ExistingOrganizations = {
    accountKey: string;
    accountName: string;
    businessName: string | null;
};

export interface PreConnectionMetaDataResponse {
    provider: AccountingSystem;
    authUrl: string;
    existingOrganizations: ExistingOrganizations[];
}

export type PostDelegatedConnectionResponse = {
    accountKey: string;
    salesSource: SalesSource;
    data: {
        message: string;
    };
};

export type PostShopifyAsyncPayload = {
    accountKey: string;
    salesSource: SalesSource;
    credentials: PostShopifyCredentials;
};

export type PostShopifyCredentials = {
    code: string;
    hmac: string;
    host: string;
    shop: string;
    state: string;
    timestamp: number;
};

export type PostShopifyAsyncResponse = SalesChannelConnectResponse;

export type PostAmazonAsyncPayload = {
    accountKey: string;
    salesSource: string;
    configuration: {
        marketplace: string;
    };
    credentials: PostAmazonCredentials;
};

export type PostAmazonCredentials = {
    code: string;
    selling_partner_id: string;
    mws_auth_token: string;
};

export type PostAmazonAsyncResponse = SalesChannelConnectResponse;
